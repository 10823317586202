<template>
  <div class="card col-lg-12 p-0">
    <div class="card-body">
      <h3 class="font-600 mb-2">Subscription Page</h3>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <div class="row">
            <ValidationProvider
              name="Title"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Title</label>
                <input
                  type="text"
                  v-model="model.title"
                  class="form-control"
                  placeholder="Enter Title"
                />
                <small class="text-danger" v-if="errors[0]">
                  {{ errors[0] }}
                </small>
              </div>
            </ValidationProvider>
          </div>
          <div class="row">
            <div class="form-group col">
              <label>Subtitle</label>
              <input
                type="text"
                v-model="model.subtitle"
                class="form-control"
                placeholder="Enter Subtitle"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <label>Description</label>
              <quill-editor
                v-model="model.description"
                placeholder="Enter Description"
                :options="editorOption"
              >
              </quill-editor>
            </div>
          </div>
          <div class="row">
            <ValidationProvider
              name="Icon"
              v-slot="{ errors, validate }"
              :rules="{ 'max-dimensions': 400 }"
              class="col-4"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label>Icon</label>
                <div class="form-control" style="height: auto">
                  <span
                    class="btn btn-outline-primary btn-sm"
                    @click="$refs.icon.click()"
                  >
                    Select Image
                  </span>
                  <img
                    v-if="model.icon"
                    :src="model.icon"
                    width="200"
                    height="200"
                    class="mt-1 d-block"
                    style="object-fit: cover; object-position: center"
                  />
                  <small class="block mt-1" style="margin-top: 4px"
                    >Ratio: 1:1</small
                  >
                  <small class="block" style="margin-top: 4px"
                    >Recommended dimensions: 400 x 400 pixels</small
                  >
                  <small class="block" style="margin-top: 4px"
                    >Max width: 400 pixels</small
                  >
                </div>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  @change="
                    (e) => {
                      validate(e).then((res) => storeIcon(res, e));
                    }
                  "
                  class="hidden"
                  placeholder="Upload Image"
                  ref="icon"
                />
                <small class="text-danger" v-if="errors[0]">
                  {{ errors[0] }}
                </small>
              </div>
            </ValidationProvider>
          </div>
          <div class="row">
            <div class="form-group col">
              <label>Active Subscription Packages</label>
              <p class="form-control read-only">
                {{
                  model.subscriptionPackages.length > 0
                    ? model.subscriptionPackages
                        .map((subscriptionPackage) => subscriptionPackage.name)
                        .join(', ')
                    : '-'
                }}
              </p>
            </div>
          </div>
          <p class="font-500">Comparison Table</p>
          <div class="row">
            <div class="col">&nbsp;</div>
            <ValidationProvider
              name="Non Subscription Name"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Non Subscription Name</label>
                <input
                  type="text"
                  v-model="model.comparisonTable.nonSubscriptionName"
                  class="form-control"
                  placeholder="Enter Non Subscription Name"
                />
                <small class="text-danger" v-if="errors[0]">
                  {{ errors[0] }}
                </small>
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="Subscription Name"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Subscription Name</label>
                <input
                  type="text"
                  v-model="model.comparisonTable.subscriptionName"
                  class="form-control"
                  placeholder="Enter Subscription Name"
                />
                <small class="text-danger" v-if="errors[0]">
                  {{ errors[0] }}
                </small>
              </div>
            </ValidationProvider>
            <div class="col">&nbsp;</div>
          </div>
          <div v-for="(row, idx) in model.comparisonTable.rows" :key="idx">
            <div class="row">
              <ValidationProvider
                :name="`Parameter ${idx + 1}`"
                v-slot="{ errors }"
                rules="required"
                class="col"
              >
                <div
                  class="form-group"
                  :class="{ 'validation-warning': errors[0] }"
                >
                  <label v-if="idx === 0" class="required">Parameter</label>
                  <input
                    type="text"
                    v-model="row.parameter"
                    class="form-control"
                    placeholder="Enter Parameter"
                  />
                  <small class="text-danger" v-if="errors[0]">
                    {{ errors[0] }}
                  </small>
                </div>
              </ValidationProvider>
              <ValidationProvider
                :name="`Non Subscription ${idx + 1}`"
                v-slot="{ errors }"
                rules="required"
                class="col"
              >
                <div
                  class="form-group"
                  :class="{ 'validation-warning': errors[0] }"
                >
                  <label v-if="idx === 0" class="required"
                    >Non Subscription</label
                  >
                  <input
                    type="text"
                    v-model="row.nonSubscription"
                    class="form-control"
                    placeholder="Enter Non Subscription"
                  />
                  <small class="text-danger" v-if="errors[0]">
                    {{ errors[0] }}
                  </small>
                </div>
              </ValidationProvider>
              <ValidationProvider
                :name="`Subscription ${idx + 1}`"
                v-slot="{ errors }"
                rules="required"
                class="col"
              >
                <div
                  class="form-group"
                  :class="{ 'validation-warning': errors[0] }"
                >
                  <label v-if="idx === 0" class="required">Subscription</label>
                  <input
                    type="text"
                    v-model="row.subscription"
                    class="form-control"
                    placeholder="Enter Subscription"
                  />
                  <small class="text-danger" v-if="errors[0]">
                    {{ errors[0] }}
                  </small>
                </div>
              </ValidationProvider>
              <div class="col">
                <label v-if="idx === 0">Actions</label>
                <button
                  type="button"
                  class="btn btn-rounded btn-danger"
                  title="Delete"
                  @click="deleteRow(idx)"
                  :disabled="model.comparisonTable.rows.length === 1"
                >
                  <i class="fa fa-trash"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-rounded btn-primary"
                  title="Move up"
                  @click="moveRow('up', idx)"
                  :disabled="idx === 0"
                >
                  <i class="fa fa-arrow-up"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-rounded btn-primary"
                  title="Move down"
                  @click="moveRow('down', idx)"
                  :disabled="idx + 1 === model.comparisonTable.rows.length"
                >
                  <i class="fa fa-arrow-down"></i>
                </button>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-primary" @click="addRow()">
            <i class="fa fa-plus"></i> Add Content
          </button>

          <app-collapse accordion type="margin">
            <app-collapse-item title="SEO Section" :isVisible="false">
              <div class="row">
                <ValidationProvider
                  name="Meta Title"
                  v-slot="{ errors }"
                  class="col"
                  rules="required|min:30|max:200"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Meta Title</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      v-model="model.metaTitle"
                      placeholder="Min 30 chars, max 200 chars"
                    ></textarea>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="Meta Description"
                  v-slot="{ errors }"
                  class="col"
                  rules="required|min:100|max:200"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Meta Description</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      v-model="model.metaDescription"
                      placeholder="Min 100 chars, max 200 chars"
                    ></textarea>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
            </app-collapse-item>
          </app-collapse>
          <div class="text-left mt-4">
            <button
              type="submit"
              class="btn btn-primary float-left"
              :disabled="isLoading"
            >
              <div v-if="isLoading">
                Updating<i class="fa fa-spinner fa-spin"></i>
              </div>
              <div v-else>Update</div>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ script: 'sub' }, { script: 'super' }],
  [{ color: ['#111820', '#0072cd', '#ff7f32', false] }],
  ['link'],
];

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      model: {
        title: '',
        subtitle: '',
        description: '',
        subscriptionPackages: [],
        icon: '',
        comparisonTable: {
          nonSubscriptionName: '',
          subscriptionName: '',
          rows: [
            {
              parameter: '',
              nonSubscription: '',
              subscription: '',
            },
          ],
        },
        // SEO Section
        metaTitle: '',
        metaDescription: '',
      },
      iconFile: null,
      isLoading: false,
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
          },
        },
      },
    };
  },
  methods: {
    getData() {
      this.axios
        .get('/v2/cms/subscription-page')
        .then((resp) => {
          const { data } = resp.data;
          this.model.title = data.title;
          this.model.subtitle = data.subtitle;
          this.model.description = data.description;
          this.model.subscriptionPackages = data.subscriptionPackages;
          this.model.icon = data.icon;
          this.model.comparisonTable = data.comparisonTable;
          // SEO Section
          this.model.metaTitle = data.metaTitle;
          this.model.metaDescription = data.metaDescription;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        });
    },
    addRow() {
      this.model.comparisonTable.rows.push({
        parameter: '',
        nonSubscription: '',
        subscription: '',
      });
    },
    deleteRow(idx) {
      this.model.comparisonTable.rows.splice(idx, 1);
    },
    moveRow(direction, index) {
      const isUp = direction === 'up';
      const destinationIndex = isUp ? index - 1 : index + 1;
      // do swap
      [
        this.model.comparisonTable.rows[index],
        this.model.comparisonTable.rows[destinationIndex],
      ] = [
        this.model.comparisonTable.rows[destinationIndex],
        this.model.comparisonTable.rows[index],
      ];
      this.$forceUpdate();
    },
    storeIcon({ valid }, event) {
      if (valid) {
        this.iconFile = event.target.files[0];
        this.$set(
          this.model,
          'icon',
          window.URL.createObjectURL(event.target.files[0])
        );
      }
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          this.isLoading = true;

          // upload images first
          const headers = {
            'Content-Type': 'multipart/form-data',
          };

          const iconFormData = new FormData();
          iconFormData.append('folder', 'subscription');
          iconFormData.append('file', this.iconFile);

          const iconUploadRequest = this.iconFile
            ? this.axios.post('/misc/upload', iconFormData, {
                headers,
              })
            : Promise.resolve(null);

          const [iconUploadResponse] = await Promise.all([iconUploadRequest]);

          const payload = {
            title: this.model.title,
            subtitle: this.model.subtitle,
            description: this.model.description,
            icon:
              iconUploadResponse === null
                ? this.model.icon
                : iconUploadResponse.data.data.file,
            comparisonTable: this.model.comparisonTable,
            // SEO Section
            metaTitle: this.model.metaTitle,
            metaDescription: this.model.metaDescription,
          };

          this.axios
            .put('/v2/cms/subscription-page', payload)
            .then((resp) => {
              this.$notify({
                type: 'success',
                title: 'Success!',
                text: resp.data.message,
              });
              this.getData();
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: err.response.data.message,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } catch (err) {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Failed to upload images',
          });
        }
      }
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>
