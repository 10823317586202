<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Article Library</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false">
                <div class="text-danger" v-if="message_error=='File too large'">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="title" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Title <span class="alert-required">*</span></label>
                                <input type="text" v-model="model.title" class="form-control" placeholder="Enter Title">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="Author" v-slot="{ errors }" rules="required|max:60" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Author <span class="alert-required">*</span></label>
                                <input type="text" v-model="model.author" class="form-control" placeholder="Article Author">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Author Profile URL" v-slot="{ errors }" rules="url" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Author Profile URL</label>
                                <input type="text" v-model="model.authorProfileUrl" class="form-control" placeholder="Starts with https://">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider name="description" v-slot="{ errors }" rules="required" class="col">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Description <span class="alert-required">*</span></label>
                            <quill-editor v-model="model.description" :options="editorOption"></quill-editor>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <div class="col">
                            <ValidationProvider name="URL Slug" v-slot="{ errors }" rules="required|slug-max-words:5">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>URL Slug <span class="alert-required">*</span></label>
                                    <input type="text" v-model="model.slug" class="form-control" placeholder="Example: this-is-valid-slug">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="Publish Date" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Publish Date <span class="alert-required">*</span></label>
                                    <date-picker v-model="model.publishDate" type="date" value-type="YYYY-MM-DD" class="w-100"></date-picker>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <ValidationProvider name="Meta Description" v-slot="{ errors }" class="col" rules="required|min:100|max:170">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Meta Description <span class="alert-required">*</span></label>
                                <textarea rows="5" class="form-control" v-model="model.metaDescription" id="metaDescription" placeholder="Min 100 chars, max 170 chars"></textarea>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="categoryArticle" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Category Article <span class="alert-required">*</span></label>
                                <multiselect 
                                    v-model="model.categoryArticle"
                                    label="name"
                                    name="categoryArticle"
                                    track-by="id" 
                                    placeholder="Search Category Article"
                                    :options="categories"
                                    class="single-select"
                                    :max-height="600">
                                    <span slot="noResult">Oops! There is no category article.</span>
                                </multiselect>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="tagArticle" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="descriptionInput">Tag Article <span class="alert-required">*</span></label>
                                <multiselect 
                                    v-model="model.tagArticle"
                                    label="name"
                                    name="tags"
                                    track-by="id" 
                                    placeholder="Search Tag Article"
                                    :options="tags" 
                                    :multiple="true" 
                                    :searchable="true" 
                                    :clear-on-select="false" 
                                    :close-on-select="false" 
                                    :max-height="600">
                                    <span slot="noResult">Oops! There is no tag article.</span>
                                </multiselect>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="imageBy" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Image By</label>
                                <input type="text" v-model="model.imageBy" class="form-control" placeholder="Enter Title">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="imageThumbnailAlt" v-slot="{ errors }" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Alt Image</label>
                                <input type="text" v-model="model.imageThumbnailAlt" class="form-control" placeholder="Enter Alt Image">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider name="imageThumbnail" v-slot="{ errors }" class="col" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Image <span class="alert-required">*</span></label>
                            <input type="file" accept=".png, .jpg, .jpeg" @change="storeImage" label="Image" name="imageThumbnail" class="form-control">
                            <input type="hidden" v-model="model.imageThumbnail" class="form-control" placeholder="Enter Alt Image">
                            <small class="text-muted">Recommended 1350 by 650 pixels and max upload 2MB</small>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider class="col">
                            <div class="form-group" v-if="model.thumbnail">
                                <label>Preview Image</label>
                                <img :src="model.thumbnail" class="responsive img-thumbnail" />
                            </div>
                            <div class="form-group" v-else>
                                <label>Preview Image</label>
                                <img :src="model.imageThumbnail" class="responsive img-thumbnail" />
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider name="bannerImage" v-slot="{ errors }" class="col">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Banner</label>
                            <input type="file" accept=".png, .jpg, .jpeg" @change="storeBannerImage" label="Image" name="bannerImage" class="form-control">
                            <input type="hidden" v-model="model.bannerImage" class="form-control" placeholder="Enter Alt Image">
                            <small class="text-muted">Recommended 725 by 300 pixels and max upload 2MB</small>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider class="col">
                            <div class="form-group" v-if="model.bannerImageThumbnail">
                                <label>Preview Banner</label>
                                <img :src="model.bannerImageThumbnail" class="responsive img-thumbnail" />
                            </div>
                            <div class="form-group" v-else>
                                <label>Preview Banner</label>
                                <img :src="model.bannerImage" class="responsive img-thumbnail" />
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider name="bannerUrl" v-slot="{ errors }" class="col" rules="url">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>URL Banner</label>
                            <input type="text" v-model="model.bannerUrl" class="form-control" placeholder="Enter Url Banner" :disabled="model.bannerImage === ''">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="purchase order" v-slot="{ errors }">
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                            <input type="checkbox" name="" id="" class="mt-1" v-model="model.index">&nbsp;Index
                        </div>
                    </ValidationProvider>
                    <hr>
                    <div class="text-left mt-1">
                        <router-link to="/article-library" class="btn text-danger float-left">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large'">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { replaceAllEnterWithSpace } from '@/helper/string';

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link', 'image']
]
export default {
    data() {
        return {
            model: {
                title: '',
                description: '',
                categoryArticle: '',
                tagArticle: [],
                imageThumbnail: '',
                imageThumbnailAlt: '',
                imageBy: '',
                thumbnail: '',
                userId: '',
                slug: '',
                publishDate: this.$moment().format('YYYY-MM-DD'),
                metaDescription: '',
                author: '',
                authorProfileUrl: '',
                bannerImage: '',
                bannerImageThumbnail: '',
                bannerUrl: '',
                index: true,
            },
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
            categories: [],
            tags: [],
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    },
                    imageUploader: {
                        upload: (file) => new Promise((resolve, reject) => {
                            const formData = new FormData();
                            formData.append('folder', 'article');
                            formData.append('file', file);
                            this.axios.post('/misc/upload', formData)
                                .then(resp => {
                                    resolve(resp.data.data.file);
                                })
                                .catch(err => {
                                    reject(err);
                                });
                        }),
                    }
                }
            }
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        get() {
            let _ = this
            _.axios.get('/dc-articles/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                    _.model.categoryArticle = _.model.category
                    _.model.tagArticle = _.model.tags
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("title", _.model.title);
                formData.append("description", _.model.description);
                formData.append("categoryArticle", _.model.categoryArticle.id);
                formData.append("tagArticle", JSON.stringify(this.model.tagArticle.map(p => p.id)));
                formData.append("imageBy", _.model.imageBy);
                formData.append("imageThumbnailAlt", _.model.imageThumbnailAlt);
                formData.append("imageThumbnail", _.model.imageThumbnail);
                formData.append("slug", _.model.slug);
                formData.append("publishDate", _.model.publishDate);
                formData.append("metaDescription", replaceAllEnterWithSpace(_.model.metaDescription));
                formData.append("author", _.model.author);
                formData.append("authorProfileUrl", _.model.authorProfileUrl || '');
                if (_.model.bannerImage !== null) {
                    formData.append("bannerImage", _.model.bannerImage);
                }
                if (_.model.bannerUrl !== null) {
                    formData.append("bannerUrl", _.model.bannerUrl);
                }
                formData.append("index", _.model.index);
                if (!_.$route.params.id) {
                    _.axios.post('/dc-articles', formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.success = resp.data.success
                            _.$router.push('/article-library')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.message_error = err.response.data.message
                            _.success = err.response.data.success
                            _.isLoading = false
                        })     
                } 
                else {
                    _.axios.put('/dc-articles/' + _.$route.params.id, formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.success = resp.data.success
                            _.$router.push('/article-library')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.message_error = err.response.data.message
                            _.success = err.response.data.success
                            _.isLoading = false
                        })   
                }
            }
        },
        storeImage(event){
            this.model.imageThumbnail = event.target.files[0];
            this.$set(this.model, 'thumbnail', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeBannerImage(event){
            this.model.bannerImage = event.target.files[0];
            this.$set(this.model, 'bannerImageThumbnail', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        getCategory() {
            let _ = this
            _.axios.get('/dc-category-articles/list-all')
            .then(resp=>{
                _.categories = resp.data.data
            })
        },
        getTag() {
            let _ = this
            _.axios.get('/dc-tag-articles/list-all')
            .then(resp=>{
                _.tags = resp.data.data
            })
        }
        
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
        _.getCategory()
        _.getTag()
    }
}
</script>