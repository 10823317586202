<template>
  <div class="card">
    <div class="card-body">
      <h3 class="font-600 mb-2">{{ id ? 'Edit' : 'Add' }} Clinic Treatment</h3>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <div class="row">
            <ValidationProvider
              name="Treatment Name"
              v-slot="{ errors }"
              rules="required|max:180"
              class="col-6"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Treatment Name</label>
                <input
                  type="text"
                  v-model="model.name"
                  class="form-control"
                  placeholder="Enter name"
                />
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="Is Active"
              v-slot="{ errors }"
              class="col-2"
              rules="required"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Is Active?</label>
                <select class="form-control" v-model="model.isActive">
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
                <small class="val-error" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="Starting Price"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Starting Price</label>
                <input
                  type="text"
                  v-model="model.startingPrice"
                  class="form-control"
                  placeholder="Enter starting price"
                />
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="row">
            <ValidationProvider
              name="Description Summary"
              v-slot="{ errors }"
              rules="required|max:180"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Description Summary</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  v-model="model.descriptionSummary"
                  class="form-control"
                ></textarea>
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="Image"
              v-slot="{ errors, validate }"
              :rules="{ required: !model.image, 'max-dimensions': 960 }"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label for="image" class="required">Image</label>
                <div class="form-control" style="height: auto">
                  <span
                    class="btn btn-outline-primary btn-sm"
                    @click="$refs.image.click()"
                    >Select Image</span
                  >
                  <img
                    v-if="model.image"
                    :src="model.image"
                    width="460"
                    height="460"
                    class="mt-1"
                    style="object-fit: cover; object-position: center"
                  />
                  <small class="block mt-1">Aspect ratio: square</small>
                  <small class="block" style="margin-top: 4px"
                    >Recommended dimensions: 960 x 960 pixels</small
                  >
                  <small class="block" style="margin-top: 4px"
                    >Max width: 960 pixels</small
                  >
                </div>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  @change="
                    (e) => {
                      validate(e).then((res) => storeImage(res, e));
                    }
                  "
                  id="image"
                  class="hidden"
                  placeholder="Upload Image"
                  ref="image"
                />
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="row">
            <div class="form-group col">
              <label>CTA Button Text</label>
              <input
                type="text"
                v-model="model.ctaButtonText"
                class="form-control"
                placeholder="Enter CTA Button Text"
              />
            </div>
            <ValidationProvider
              name="CTA Button URL"
              v-slot="{ errors }"
              rules="url"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label>CTA Button URL</label>
                <input
                  type="text"
                  v-model="model.ctaButtonUrl"
                  class="form-control"
                  placeholder="Enter CTA Button URL"
                />
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
          </div>
          <app-collapse accordion type="margin">
            <app-collapse-item title="Content Section" :isVisible="false">
              <div class="row">
                <div class="form-group col-2">
                  <label>Is Active?</label>
                  <select class="form-control" v-model="model.contentIsActive">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label>Description</label>
                  <quill-editor
                    v-model="model.contentDescription"
                    placeholder="Enter description"
                    :options="editorOption"
                  >
                  </quill-editor>
                </div>
              </div>
            </app-collapse-item>
            <app-collapse-item
              title="Table of Treatment Section"
              :isVisible="false"
            >
              <div class="row">
                <div class="form-group col-2">
                  <label>Is Active?</label>
                  <select
                    class="form-control"
                    v-model="model.treatmentTableIsActive"
                  >
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <ValidationProvider
                  name="Table Title"
                  v-slot="{ errors }"
                  :rules="{ required: model.treatmentTableIsActive }"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label :class="{ required: model.treatmentTableIsActive }"
                      >Table Title</label
                    >
                    <input
                      type="text"
                      v-model="model.treatmentTableTitle"
                      class="form-control"
                      placeholder="Enter table title"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <hr />
              <div class="row" v-if="model.treatmentTableItems.length === 0">
                <div class="col">
                  <button
                    class="btn btn-primary"
                    @click="addTreatmentTableItem(0)"
                  >
                    <i class="fa fa-plus"></i> Add Content
                  </button>
                </div>
              </div>
              <div
                v-for="(item, idx) in model.treatmentTableItems"
                :key="idx"
                class="mb-1"
              >
                <div class="row">
                  <ValidationProvider
                    :name="`Treatment Table Title ${idx + 1}`"
                    v-slot="{ errors }"
                    rules="required"
                    class="col"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label class="required">Title</label>
                      <input
                        type="text"
                        v-model="item.title"
                        class="form-control"
                        placeholder="Enter title"
                      />
                      <small class="text-muted" v-if="errors[0]">{{
                        errors[0]
                      }}</small>
                    </div>
                  </ValidationProvider>
                  <div class="col form-group">
                    <label>Subtitle</label>
                    <input
                      type="text"
                      v-model="item.subTitle"
                      class="form-control"
                      placeholder="Enter subtitle"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label>Retail Price</label>
                    <input
                      type="text"
                      v-model="item.retailPrice"
                      class="form-control"
                      placeholder="Enter retail price"
                    />
                  </div>
                  <ValidationProvider
                    :name="`Treatment Table Selling Price ${idx + 1}`"
                    v-slot="{ errors }"
                    rules="required"
                    class="col"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label class="required">Selling Price</label>
                      <input
                        type="text"
                        v-model="item.sellingPrice"
                        class="form-control"
                        placeholder="Enter selling price"
                      />
                      <small class="text-muted" v-if="errors[0]">{{
                        errors[0]
                      }}</small>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="row">
                  <div class="form-group col">
                    <label>Description</label>
                    <quill-editor
                      v-model="item.description"
                      placeholder="Enter description"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </div>
                </div>
                <div class="row">
                  <div class="col d-flex" style="gap: 1rem">
                    <button
                      class="btn btn-primary"
                      @click="addTreatmentTableItem(idx + 1)"
                    >
                      <i class="fa fa-plus"></i> Add Content
                    </button>
                    <button
                      class="btn btn-danger"
                      @click="deleteTreatmentTableItem(idx)"
                    >
                      <i class="fa fa-trash"></i> Delete Content
                    </button>
                  </div>
                </div>
                <hr />
              </div>
            </app-collapse-item>
            <app-collapse-item title="SEO Section" :isVisible="false">
              <div class="row">
                <ValidationProvider
                  name="Meta Title"
                  v-slot="{ errors }"
                  class="col"
                  rules="required|min:30|max:200"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Meta Title</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      v-model="model.metaTitle"
                      placeholder="Min 30 chars, max 200 chars"
                    ></textarea>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="Meta Description"
                  v-slot="{ errors }"
                  class="col"
                  rules="required|min:100|max:200"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Meta Description</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      v-model="model.metaDescription"
                      placeholder="Min 100 chars, max 200 chars"
                    ></textarea>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <ValidationProvider
                  name="URL Slug"
                  v-slot="{ errors }"
                  class="col-6"
                  rules="required|slug-max-words:5"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">URL Slug</label>
                    <input
                      type="text"
                      v-model="model.slug"
                      class="form-control"
                      placeholder="Enter URL slug"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
            </app-collapse-item>
          </app-collapse>
          <div class="row">
            <div class="col">
              <hr />
              <router-link
                to="/clinic-treatment-library"
                class="btn text-danger"
                >Cancel</router-link
              >
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="isLoading"
              >
                <div v-if="isLoading">
                  {{ id ? 'Updating' : 'Creating'
                  }}<i class="fa fa-spinner fa-spin"></i>
                </div>
                <div v-else>{{ id ? 'Update' : 'Create' }}</div>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ script: 'sub' }, { script: 'super' }],
  [{ color: ['#111820', '#0072cd', '#ff7f32', false] }],
  ['link'],
];

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      model: {
        name: '',
        isActive: true,
        descriptionSummary: '',
        image: '',
        ctaButtonText: '',
        ctaButtonUrl: '',
        startingPrice: '',
        // Content Section
        contentIsActive: true,
        contentDescription: '',
        // Table of Treatment Section
        treatmentTableIsActive: true,
        treatmentTableTitle: '',
        treatmentTableItems: [],
        // SEO Section
        metaTitle: '',
        metaDescription: '',
        slug: '',
      },
      imageFile: null,
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
          },
        },
      },
    };
  },
  async mounted() {
    if (this.id) {
      this.axios
        .get(`/v2/cms/clinic-treatment-library/${this.id}`)
        .then((res) => {
          const { data } = res.data;
          this.model = {
            name: data.name,
            isActive: data.isActive,
            descriptionSummary: data.descriptionSummary,
            image: data.image,
            ctaButtonText: data.ctaButtonText,
            ctaButtonUrl: data.ctaButtonUrl,
            startingPrice: data.startingPrice,
            // Content Section
            contentIsActive: data.contentIsActive,
            contentDescription: data.contentDescription,
            // Table of Treatment Section
            treatmentTableIsActive: data.treatmentTableIsActive,
            treatmentTableTitle: data.treatmentTableTitle,
            treatmentTableItems: data.treatmentTableItems || [],
            // SEO Section
            metaTitle: data.metaTitle,
            metaDescription: data.metaDescription,
            slug: data.slug,
          };
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
          this.$router.push('/clinic-treatment-library');
        });
    }
  },
  methods: {
    addTreatmentTableItem(idx) {
      const items = [
        ...this.model.treatmentTableItems.slice(0, idx),
        {
          title: '',
          subTitle: '',
          retailPrice: '',
          sellingPrice: '',
          description: '',
        },
        ...this.model.treatmentTableItems.slice(idx),
      ];
      this.$set(this.model, 'treatmentTableItems', items);
    },
    deleteTreatmentTableItem(idx) {
      const items = [
        ...this.model.treatmentTableItems.slice(0, idx),
        ...this.model.treatmentTableItems.slice(idx + 1),
      ];
      this.$set(this.model, 'treatmentTableItems', items);
    },
    storeImage({ valid }, event) {
      if (valid) {
        this.imageFile = event.target.files[0];
        this.$set(
          this.model,
          'image',
          window.URL.createObjectURL(event.target.files[0])
        );
      }
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          this.isLoading = true;

          // upload images first
          const formDataImage = new FormData();
          formDataImage.append('folder', 'clinic');
          formDataImage.append('file', this.imageFile);
          const responseImage = this.imageFile
            ? await this.axios.post('/misc/upload', formDataImage, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
            : null;
          const imageUrl = responseImage
            ? responseImage.data.data.file
            : this.model.image;

          const data = {
            name: this.model.name,
            isActive: this.model.isActive,
            descriptionSummary: this.model.descriptionSummary,
            image: imageUrl,
            ctaButtonText: this.model.ctaButtonText,
            ctaButtonUrl: this.model.ctaButtonUrl,
            startingPrice: this.model.startingPrice,
            // Content Section
            contentIsActive: this.model.contentIsActive,
            contentDescription: this.model.contentDescription,
            // Table of Treatment Section
            treatmentTableIsActive: this.model.treatmentTableIsActive,
            treatmentTableTitle: this.model.treatmentTableTitle,
            treatmentTableItems: this.model.treatmentTableItems,
            // SEO Section
            metaTitle: this.model.metaTitle,
            metaDescription: this.model.metaDescription,
            slug: this.model.slug,
          };

          if (this.id) {
            this.axios
              .put(`/v2/cms/clinic-treatment-library/${this.id}`, data)
              .then((resp) => {
                this.$notify({
                  type: 'success',
                  title: 'Success!',
                  text: resp.data.message,
                });
                this.$router.push('/clinic-treatment-library');
              })
              .catch((err) => {
                this.isLoading = false;
                this.$notify({
                  type: 'error',
                  title: 'Error',
                  text: err.response.data.message,
                });
              });
          } else {
            this.axios
              .post('/v2/cms/clinic-treatment-library', data)
              .then((resp) => {
                this.$notify({
                  type: 'success',
                  title: 'Success!',
                  text: resp.data.message,
                });
                this.$router.push('/clinic-treatment-library');
              })
              .catch((err) => {
                this.isLoading = false;
                this.$notify({
                  type: 'error',
                  title: 'Error',
                  text: err.response.data.message,
                });
              });
          }
        } catch (err) {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Failed to upload images',
          });
        }
      }
    },
  },
};
</script>
