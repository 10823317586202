<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">Testimony Page</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <div class="text-danger">
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(save)">
                    <app-collapse accordion type="margin">
                        <app-collapse-item title="Banner Section">
                            <div class="row">
                                <ValidationProvider name="Banner Title" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="name">Title <span class="alert-required">*</span></label>
                                        <input type="text" id="name" v-model="model.bannerTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="Banner Description" v-slot="{ errors }" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="description">Description</label>
                                        <textarea
                                            v-model="model.bannerDescription"
                                            id="description"
                                            cols="30"
                                            rows="5"
                                            class="form-control"
                                            placeholder="Enter Description"
                                            style="resize: none;"
                                        >
                                        </textarea>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider
                                    name="Banner Image"
                                    v-slot="{ errors, validate }"
                                    :rules="`${isBannerImageRequired ? 'required|max-dimensions:860' : 'max-dimensions:860'}`"
                                    class="col"
                                >
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="bannerImage">Banner Image <span class="alert-required">*</span></label>
                                        <div class="form-control d-flex flex-column align-items-start" style="height: auto;">
                                            <span class="btn btn-outline-primary btn-sm" @click="$refs.bannerImage.click()">Select Photo</span>
                                            <img
                                                v-if="model.bannerImageUrl"
                                                :src="model.bannerImageUrl"
                                                width="320"
                                                height="320"
                                                class="mt-1"
                                                style="object-fit: contain; object-position: center;"
                                            />
                                            <small class="block mt-1">Aspect ratio: square</small>
                                            <small class="block" style="margin-top: 4px;">Recommended dimensions: 860 x 860 pixels</small>
                                            <small class="block" style="margin-top: 4px;">Max width: 860 pixels</small>
                                        </div>
                                        <input
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            @change="(e) => {
                                                isBannerImageRequired = true;
                                                validate(e).then((res) => storeBannerImage(res, e));
                                            }"
                                            id="bannerImage"
                                            class="hidden"
                                            placeholder="Upload Banner Image"
                                            ref="bannerImage"
                                        >
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Banner Background Color" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label for="bannerBackgroundColor">Background Header Color <span class="alert-required">*</span></label>
                                        <sketch-picker v-model="model.bannerBackgroundColor" @input="updateColor" id="bannerBackgroundColor"></sketch-picker>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="Primary Button Text" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="bannerPrimaryButton">Primary Button Text <span class="alert-required">*</span></label>
                                        <input type="text" id="bannerPrimaryButton" v-model="model.bannerPrimaryButton" class="form-control" placeholder="Enter Primary Button Text">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Secondary Button Text" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="bannerSecondaryButton">Secondary Button Text <span class="alert-required">*</span></label>
                                        <input type="text" id="bannerSecondaryButton" v-model="model.bannerSecondaryButton" class="form-control" placeholder="Enter Secondary Button Text">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Testimony Section">
                            <div class="row">
                                <ValidationProvider name="Testimony Title" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="name">Title <span class="alert-required">*</span></label>
                                        <input type="text" id="name" v-model="model.tesimonyTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="Testimony Description" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="description">Description <span class="alert-required">*</span></label>
                                        <textarea
                                            v-model="model.tesimonyDescription"
                                            id="description"
                                            cols="30"
                                            rows="5"
                                            class="form-control"
                                            placeholder="Enter Description"
                                            style="resize: none;"
                                        >
                                        </textarea>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Video Section">
                            <div class="row">
                                <ValidationProvider name="Video Title" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="name">Title <span class="alert-required">*</span></label>
                                        <input type="text" id="name" v-model="model.tesimonyVideoTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider name="Video Description" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="description">Description <span class="alert-required">*</span></label>
                                        <textarea
                                            v-model="model.tesimonyVideoDescription"
                                            id="description"
                                            cols="30"
                                            rows="5"
                                            class="form-control"
                                            placeholder="Enter Description"
                                            style="resize: none;"
                                        >
                                        </textarea>
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item>
                    </app-collapse>
                    <div class="text-left mt-4">
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Save</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import AppCollapse from '@/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue';
import { replaceAllEnterWithSpace } from '@/helper/string';

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
    },
    data() {
        return {
            model: {
                bannerTitle: '',
                bannerDescription: '',
                bannerImage: null,
                bannerImageUrl: '',
                bannerBackgroundColor: '#FFFFFF',
                bannerPrimaryButton: '',
                bannerSecondaryButton: '',
                tesimonyTitle: '',
                tesimonyDescription: '',
                tesimonyVideoTitle: '',
                tesimonyVideoDescription: '',
            },
            isLoading: false,
            errors: null,
            messageError: null,
            isBannerImageRequired: true,
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getData() {
            this.isLoading = true;
            this.axios.get('/testimony-page')
                .then(resp => {
                    const { data } = resp.data;
                    this.model = {
                        bannerTitle: data.bannerTitle,
                        bannerDescription: data.bannerDescription,
                        bannerImageUrl: data.bannerImage,
                        bannerBackgroundColor: data.bannerBackgroundColor,
                        bannerPrimaryButton: data.bannerPrimaryButton,
                        bannerSecondaryButton: data.bannerSecondaryButton,
                        tesimonyTitle: data.tesimonyTitle,
                        tesimonyDescription: data.tesimonyDescription,
                        tesimonyVideoTitle: data.tesimonyVideoTitle,
                        tesimonyVideoDescription: data.tesimonyVideoDescription,
                    };
                    this.isBannerImageRequired = data.bannerImage === '';
                })
                .catch(err => {
                    this.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        storeBannerImage({ valid }, event){
            if (valid) {
                this.$set(this.model, 'bannerImage', event.target.files[0]);
                this.$set(this.model, 'bannerImageUrl', window.URL.createObjectURL(event.target.files[0]));
            }
        },
        updateColor(event) {
            this.model.bannerBackgroundColor = event.hex;
        },
        async save() {
            const formDataBannerImage = new FormData();
            formDataBannerImage.append('folder', 'contents');
            formDataBannerImage.append('file', this.model.bannerImage);

            try {
                this.isLoading = true;
                const responseBannerImage = this.model.bannerImage
                    ? await this.axios.post('/misc/upload', formDataBannerImage, { headers: { 'Content-Type': 'multipart/form-data' }})
                    : null;

                const payload = {
                    bannerTitle: this.model.bannerTitle,
                    bannerDescription: replaceAllEnterWithSpace(this.model.bannerDescription),
                    bannerImage: responseBannerImage ? responseBannerImage.data.data.file : this.model.bannerImageUrl,
                    bannerBackgroundColor: this.model.bannerBackgroundColor,
                    bannerPrimaryButton: this.model.bannerPrimaryButton,
                    bannerSecondaryButton: this.model.bannerSecondaryButton,
                    tesimonyTitle: this.model.tesimonyTitle,
                    tesimonyDescription: replaceAllEnterWithSpace(this.model.tesimonyDescription),
                    tesimonyVideoTitle: this.model.tesimonyVideoTitle,
                    tesimonyVideoDescription: replaceAllEnterWithSpace(this.model.tesimonyVideoDescription),
                };

                this.axios.put(`/testimony-page`, payload)
                    .then(resp => {
                        this.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        });
                        this.errors = null;
                        this.messageError = null;
                    })
                    .catch(err => {
                        this.errors = err.response.data.message;
                        this.messageError = err.response.data.message;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            } catch (err) {
                this.isLoading = false;
                this.errors = true;
                this.messageError = 'Failed to upload photo';
            }
        }
        
    },
    async mounted() {
        this.getData();
    }
}
</script>
