<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Questionaire</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <ul class="mb-0">
                    <div v-for="(v, k) in errors" :key="k">
                        <li class="text-danger">{{v.message}}</li>
                    </div>
                </ul>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <ValidationProvider name="title" v-slot="{ errors }" rules="required">
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                            <label class="required">Title</label>
                            <input type="text" v-model="model.title" class="form-control" placeholder="Enter title">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="section" v-slot="{ errors }" rules="required">
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                            <label class="required">Section</label>
                            <input type="text" v-model="model.section" class="form-control" placeholder="Enter Section">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <h3 class="font-600 mb-2">Contents</h3>
                    <section v-for="(page, idx) in model.json.pages" :key="idx">
                        <div class="form-group">
                            <label for="">Rules </label>
                            <!-- <select name="" id="" class="form-control" v-model="selectedRules[idx]">
                                <option value="">Pilih</option>
                                <option :value="xx.id" v-for="(xx, i) in getRules(idx-1)" :key="i">
                                    {{xx.value}}
                                </option>
                            </select> -->

                            <multiselect 
                                v-model="selectedRules[idx]"
                                label="label"
                                track-by="id" 
                                placeholder="Choose Choice"
                                :options="getRules()"
                                :multiple="true" 
                                :clear-on-select="false" 
                                :close-on-select="false" 
                                @input="model.json.pages[idx].visibleIf = selectedRules[idx]">
                                <span slot="noResult">Oops! Data not found.</span>
                            </multiselect>
                        </div>
                        <ValidationProvider :name="'content ' + (idx+1)" v-slot="{ errors }" rules="required">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="required">Content</label>
                                <multiselect 
                                    v-model="selectedContents[idx]"
                                    label="title"
                                    track-by="id" 
                                    placeholder="Choose Content"
                                    :options="optionContents" 
                                    :allow-empty="false"
                                    @input="setModel(idx)">
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div v-if="selectedContents[idx] != null && selectedContents[idx].pageTypeId == 3">
                            <div class="row" v-for="(choice, index) in selectedContents[idx].pageOption.choices" :key="index">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="">Choice {{index + 1}}</label>
                                        <input type="text" disabled class="form-control" :value="choice.label">
                                    </div>
                                </div>
                                <!-- <div class="col-6">
                                    <ValidationProvider :name="'redirect ' + (index+1)" v-slot="{ errors }" rules="required">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="required">Redirect To</label>
                                        <multiselect 
                                            :disabled="model.json.pages[idx].answer[index].redirectTo != null"
                                            v-model="selectedRedirectTo[idx].answer[index].redirectTo"
                                            label="title"
                                            track-by="slug" 
                                            placeholder="Choose Content"
                                            :options="optionContents" 
                                            :allow-empty="false"
                                            @input="
                                                model.json.pages[idx].answer[index].redirectTo = selectedRedirectTo[idx].answer[index].redirectTo.slug,
                                                autofilledPage(
                                                    model.json.pages[idx].answer[index].id, 
                                                    selectedRedirectTo[idx].answer[index].redirectTo
                                                )
                                            ">
                                            <span slot="noResult">Oops! Data not found.</span>
                                        </multiselect>
                                        <small class="text-primary pointer" 
                                            v-if="selectedRedirectTo[idx].answer[index].redirectTo"
                                            @click="removeAutoFilledPage(idx, index)">
                                            <u>Change</u>
                                        </small>
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                    </ValidationProvider>
                                </div> -->
                            </div>
                        </div>

                        <ValidationProvider name="is auth" v-slot="{ errors }" rules="required">
                            <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                <label>Need Auth?</label>
                                <input type="checkbox" v-model="model.json.pages[idx].isAuth">
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>

                        <button type="button" class="btn btn-danger" @click="deleteContent(idx)" v-if="model.json.pages.length > 1"><i class="fa fa-trash"></i> Delete Content</button>&nbsp;
                        <button type="button" class="btn btn-primary" @click="addContent()" v-if="idx == model.json.pages.length-1"><i class="fa fa-plus"></i> Add Content</button>
                        <hr>
                    </section>
                    <div class=" mt-1">
                        <router-link to="/questionnaire" class="btn text-danger">Cancel</router-link>
                        <button type="submit" class="btn btn-primary " :disabled="isLoading">
                            <div v-if="isLoading">{{$route.params.id ? 'Updating' : 'Creating'}}<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>{{$route.params.id ? 'Update' : 'Create'}}</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
            <!-- <json-viewer :value="model" :expand-depth="10"></json-viewer> -->
            <!-- <json-viewer :value="hiddenContents"></json-viewer> -->
            <!-- <json-viewer :value="selectedRedirectTo" :expand-depth="4"></json-viewer> -->
            <!-- <json-viewer :value="selectedContents" :expand-depth="2"></json-viewer> -->
            <!-- <json-viewer :value="selectedRules" :expand-depth="2"></json-viewer> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                title: '',
                section: '',
                json: {
                    pages: [
                        {
                            id: this.randomString(),
                            contentId: "",
                            slug: null,
                            redirectTo: null,
                            isAuth: false,
                            answer: [],
                            visibleIf: []
                        }
                    ],
                }
            },
            isLoading: false,
            errors: null,
            optionContents: [],
            selectedContents: [],
            selectedRedirectTo: [],
            selectedRules: []
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/questionnaires/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data 
                    for (let index = 0; index < _.model.json.pages.length; index++) {
                        this.selectedContents.push(this.optionContents.filter(x => x.id == _.model.json.pages[index].contentId)[0])
                        
                        let answer = []
                        for (let i = 0; i < _.model.json.pages[index].answer.length; i++) {
                            answer.push({
                                redirectTo: this.optionContents.filter(x => x.slug == _.model.json.pages[index].answer[i].redirectTo)[0]
                            })
                        }

                        _.selectedRedirectTo.push({
                            answer: answer
                        })
                        _.model.json.pages[index].id = this.randomString()

                        _.selectedRules.push(_.model.json.pages[index].visibleIf)
                    }
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getContents() {
            let _ = this
            _.axios.get('/contents')
                .then(resp => {
                    _.optionContents = resp.data.data.rows
                    if (_.$route.params.id) {
                        _.get()
                    }
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        randomString() {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 8; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            return result;
        },
        addContent() {
            let _ = this
            _.model.json.pages.push({
                id: _.randomString(),
                contentId: "",
                slug: null,
                redirectTo: null,
                isAuth: false,
                answer: [],
                visibleIf: []
            })

            _.selectedRedirectTo.push({ answer: [] })
            // _.selectedRules.push({ rules: [] })
        },
        deleteContent(x) {
            let _ = this
            
            if ( _.model.json.pages[x].visibleIf == true ) {
                let pageId = _.model.json.pages[x].id
                for (let z = 0; z < _.selectedRedirectTo.length; z++) {
                    for ( let i = 0; i < _.selectedRedirectTo[z].answer.length; i++ ) {
                        if ( _.selectedRedirectTo[z].answer[i].id == pageId ) {
                            if ( _.selectedRedirectTo[z].answer[i] ) {
                                _.selectedRedirectTo[z].answer[i].redirectTo = null
                            }

                            if ( _.model.json.pages[z].answer[i] ) {
                                _.model.json.pages[z].answer[i].redirectTo = null
                            }
                        }                    
                    }
                }
            }

            _.model.json.pages.splice(x, 1);
            _.selectedContents.splice(x, 1);
            _.selectedRedirectTo.splice(x, 1);
        },
        autofilledPage(rand, x){
            let _ = this

            _.model.json.pages.push({
                id: rand,
                contentId: x.id,
                slug: x.slug,
                redirectTo: null,
                isAuth: false,
                answer: [],
                visibleIf: true
            })

            _.selectedContents.push(x)
            _.setModel(_.model.json.pages.length-1)
        },
        removeAutoFilledPage(idx, index) {
            this.model.json.pages[idx].answer[index].redirectTo = null
            this.selectedRedirectTo[idx].answer[index].redirectTo = null

            for (let i = 0; i < this.model.json.pages.length; i++) {
                if (this.model.json.pages[idx].answer[index].id && this.model.json.pages[i].id) {
                    if (this.model.json.pages[idx].answer[index].id == this.model.json.pages[i].id) {
                        this.model.json.pages.splice(i, 1)
                        this.selectedContents.splice(i, 1)
                        this.selectedRedirectTo.splice(i, 1)
                    }
                }                    
            }
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                if (!_.$route.params.id) {
                    _.axios.post('/questionnaires', _.model)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.$router.push('/questionnaire')
                        })
                        .catch(err => {
                            _.errors = err.response.data.data
                            _.isLoading = false
                        })     
                } 
                else {
                    _.axios.put('/questionnaires/' + _.$route.params.id, _.model)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.$router.push('/questionnaire')
                        })
                        .catch(err => {
                            _.errors = err.response.data.data
                            _.isLoading = false
                        })   
                }  
            }
        },
        setModel(idx){
            let _ = this
            _.model.json.pages[idx].contentId = _.selectedContents[idx].id
            _.model.json.pages[idx].slug = _.selectedContents[idx].slug
            _.model.json.pages[idx].answer = []
            _.selectedRedirectTo.push({answer:[]})
            
            if (_.selectedContents[idx].pageTypeId == 3){
                for (let index = 0; index < _.selectedContents[idx].pageOption.choices.length; index++) {
                    let randId = this.randomString()
                    _.selectedRedirectTo[idx].answer.push({
                        id: randId,
                        label: _.selectedContents[idx].title+ ': ' + _.selectedContents[idx].pageOption.choices[index].label,
                        value: index + 1,
                        redirectTo: null
                    })

                    _.model.json.pages[idx].answer.push({
                        id: randId,
                        label: _.selectedContents[idx].title+ ': ' + _.selectedContents[idx].pageOption.choices[index].label,
                        value: index + 1,
                        redirectTo: null
                    })
                }
            }

            _.$forceUpdate()
        },
        getRules() {
            let _ = this

            let hehe = _.model.json.pages.map(( page ) => {
                return page.answer.map((jwb) => {
                    return jwb
                })
            })

            return hehe.flat(1)
        }
    },
    mounted() {
        let _ = this
        _.getContents()
    }
}
</script>