<template>
    <div class="card col-lg-12 col-xs-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Admin</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <ul class="mb-0">
                    <div v-for="(v, k) in errors" :key="k">
                        <li class="text-danger">{{v.message}}</li>
                    </div>
                </ul>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <ValidationProvider name="first name" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label class="required">First Name</label>
                            <input type="text" v-model="model.firstName" class="form-control" placeholder="Enter first name">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="last name" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label class="required">Last Name</label>
                            <input type="text" v-model="model.lastName" class="form-control" placeholder="Enter last name">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="email" v-slot="{ errors }" rules="required|email">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label class="required">Email</label>
                            <input type="text" v-model="model.email" class="form-control" placeholder="Enter email" :disabled="$route.params.id">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="phone number" v-slot="{ errors }" rules="">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label class="">Phone number</label>
                            <input type="number" v-model="model.mobileNumber" class="form-control" placeholder="Enter phone number">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="role" v-slot="{ errors }" rules="required">
                        <div class="form-group mt-1" :class="{ 'validation-warning': errors[0] }">
                            <label class="required">Role</label>
                            <multiselect 
                                v-model="selectedRole"
                                label="name"
                                track-by="id" 
                                placeholder="Search role"
                                :options="options.roles" 
                                :multiple="false" 
                                :searchable="true" 
                                :loading="isLoading" 
                                :internal-search="false" 
                                :clear-on-select="false" 
                                :close-on-select="true" 
                                :max-height="300" 
                                :show-no-results="false" 
                                :hide-selected="false" 
                                @search-change="get_roles">
                                <span slot="noResult">Oops! There is no role.</span>
                            </multiselect>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div v-if="!$route.params.id">
                    <hr>
                    <ValidationProvider name="new password" ref="password" :rules="'min:8' + (!$route.params.id ? '|required' : '')" v-slot="{ errors }">
                        <label for="" class="required">Password</label>
                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                            <input :type="show_new_password ? 'text' : 'password'" v-model="model.password" class="form-control border-right-0 bg-white" placeholder="Masukkan password baru" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');">
                            <span class="input-group-append">
                                <div class="input-group-text">
                                    <a href="javascript:;" @click="show_new_password = !show_new_password" class="text-blue">
                                        <i :class="show_new_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                    </a>
                                </div>
                            </span> 
                        </div>
                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                    <ValidationProvider name="confirm new password" :rules="'confirmed:new password|min:8' + (!$route.params.id ? '|required' : '')" v-slot="{ errors }">
                        <label for="" class="mt-1 required">Password Confirmation</label>
                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                            <input :type="show_conf_new_password ? 'text' : 'password'" v-model="conf_password" class="form-control border-right-0 bg-white" placeholder="Konfirmasi password baru" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');">
                            <span class="input-group-append">
                                <div class="input-group-text">
                                    <a href="javascript:;" @click="show_conf_new_password = !show_conf_new_password" class="text-blue">
                                        <i :class="show_conf_new_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                    </a>
                                </div>
                            </span> 
                        </div>
                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                    <p class="mt-1">
                        <small>
                            <b>Hint</b>: 
                            Password must be at least 8 characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $% ^ )
                        </small>
                    </p>
                    </div>
                    <hr>
                    <div class=" mt-1">
                        <router-link to="/admins" class="btn text-danger">Cancel</router-link>
                        <button type="submit" class="btn btn-primary " :disabled="isLoading">
                            <div v-if="isLoading">{{$route.params.id ? 'Updating' : 'Creating'}}<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>{{$route.params.id ? 'Update' : 'Create'}}</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                firstName: '',
                lastName: '',
                email: '',
                isSuperadmin: false,
                mobileNumber: null
            },
            isLoading: false,
            errors: null,
            show_new_password: false,
            show_conf_new_password: false,
            options: {
                roles: []
            },
            selectedRole: "",
            conf_password: ''
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/admins/' + _.$route.params.id)
                .then(resp => {
                    let data = resp.data.data
                    _.model.firstName = data.firstName
                    _.model.lastName = data.lastName
                    _.model.email = data.account.email
                    _.model.mobileNumber = data.mobileNumber
                    _.selectedRole = data.role
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                if ( _.$route.params.id ) {
                    _.axios.put('/admins' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.model)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.$router.push('/admins')
                        })
                        .catch(err => {
                            _.$notify({
                                type: 'error',
                                title: 'Error!',
                                text: err.response.data.message
                            })
                            _.isLoading = false
                        })     
                } else {
                    _.axios.post('/admins' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.model)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.$router.push('/admins')
                        })
                        .catch(err => {
                            _.$notify({
                                type: 'error',
                                title: 'Error!',
                                text: err.response.data.message
                            })
                            _.isLoading = false

                            err.response.data.data.forEach(elm => {
                                this.$refs[elm.context.key].applyResult({
                                    errors: [elm.message],
                                    valid: false,
                                    failedRules: {}
                                });
                            });
                        })     
                }       
            }
        },
        get_roles(key) {
            let _ = this
            _.axios.get('/roles?search='+ (key ? key : ''))
                .then(resp => {
                    _.options.roles = resp.data.data.rows
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        increaseNumber() {
            this.model.mobileNumber = parseInt(this.model.mobileNumber) + parseInt(1)
        },
        decreaseNumber() {
            this.model.mobileNumber = parseInt(this.model.mobileNumber) - parseInt(1)
        }
    },
    mounted() {
        let _ = this
        _.get_roles()
        if (_.$route.params.id){
            _.get()
        }
    },
    watch: {
        selectedRole: function(){
            let _ = this
            _.model.roleId = _.selectedRole.id
        },
    }
}
</script>