<template>
  <div class="card col-lg-12 p-0">
    <div class="card-body">
      <h3 class="font-600 mb-2">Add-ons</h3>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <div class="row">
            <div class="form-group col-2">
              <label>Is Active?</label>
              <select class="form-control" v-model="model.isActive">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
          <div v-for="(item, idx) in model.items" :key="idx">
            <div class="row">
              <ValidationProvider
                :name="`Polyclinic ${idx + 1}`"
                v-slot="{ errors }"
                rules="required"
                class="col-6"
              >
                <div
                  class="form-group"
                  :class="{ 'validation-warning': errors[0] }"
                >
                  <label class="required">Polyclinic</label>
                  <multiselect
                    v-model="item.polyClinic"
                    label="namePage"
                    track-by="id"
                    placeholder="Search Polyclinic"
                    :options="getPolyclinicOptions(idx)"
                    :multiple="false"
                    :searchable="true"
                    :max-height="600"
                  >
                    <span slot="noResult">Oops! There is no polyclinic.</span>
                  </multiselect>
                  <small class="text-danger" v-if="errors[0]">{{
                    errors[0]
                  }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div class="row">
              <ValidationProvider
                :name="`Products ${idx + 1}`"
                v-slot="{ errors }"
                rules="required"
                class="col"
              >
                <div
                  class="form-group"
                  :class="{ 'validation-warning': errors[0] }"
                >
                  <label class="required">Products</label>
                  <multiselect
                    v-model="item.products"
                    label="title"
                    track-by="id"
                    placeholder="Search Products"
                    :multiple="true"
                    :max="10"
                    :options="products"
                    :searchable="true"
                    :max-height="600"
                    :close-on-select="false"
                  >
                    <span slot="noResult">Oops! There is no products.</span>
                  </multiselect>
                  <small class="text-danger" v-if="errors[0]">{{
                    errors[0]
                  }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div class="row">
              <div class="col-2">
                <label>Actions</label>
                <button
                  type="button"
                  class="btn btn-rounded btn-danger"
                  title="Delete"
                  @click="deleteItem(idx)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
            <hr />
          </div>
          <button type="button" class="btn btn-primary" @click="addItem()">
            <i class="fa fa-plus"></i> Add Content
          </button>
          <div class="text-left mt-4">
            <button
              type="submit"
              class="btn btn-primary float-left"
              :disabled="isLoading"
            >
              <div v-if="isLoading">
                Updating<i class="fa fa-spinner fa-spin"></i>
              </div>
              <div v-else>Update</div>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: {
        isActive: true,
        items: [],
      },
      isLoading: false,
      polyClinics: [],
      products: [],
    };
  },
  methods: {
    getPolyclinicOptions(idx) {
      const selectedPolyClinicIds = [
        ...this.model.items.slice(0, idx),
        ...this.model.items.slice(idx + 1),
      ].map((item) => item.polyClinic?.id ?? null);
      return this.polyClinics.filter(
        (polyClinic) => !selectedPolyClinicIds.includes(polyClinic.id)
      );
    },
    getPolyclinics() {
      this.axios
        .get('/misc/poly-clinic')
        .then((resp) => {
          this.polyClinics = resp.data.data;
        })
        .catch((err) => {
          console.error(err.response);
        });
    },
    getProducts() {
      this.axios
        .get('/v2/misc/products', {
          params: { page: 1, type: 'in:Rx Blue,OTC', isActive: true },
        })
        .then((resp) => {
          this.products = resp.data.data.rows;
        })
        .catch((err) => {
          console.error(err.response);
        });
    },
    getData() {
      this.axios
        .get('/v2/cms/add-ons')
        .then((resp) => {
          const { data } = resp.data;
          this.model.isActive = data.isActive;
          this.model.items = data.items.map((item) => ({
            ...item,
            polyClinic: {
              id: item.polyClinic.id,
              namePage: item.polyClinic.name,
            },
          }));
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        });
    },
    addItem() {
      this.model.items.push({
        polyClinic: null,
        products: [],
      });
    },
    deleteItem(idx) {
      this.model.items.splice(idx, 1);
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.isLoading = true;

        const payload = {
          isActive: this.model.isActive,
          items: this.model.items.map((item) => ({
            polyClinicId: item.polyClinic.id,
            productIds: item.products.map((product) => product.id),
          })),
        };

        this.axios
          .put('/v2/cms/add-ons', payload)
          .then((resp) => {
            this.$notify({
              type: 'success',
              title: 'Success!',
              text: resp.data.message,
            });
            this.getData();
          })
          .catch((err) => {
            this.$notify({
              type: 'error',
              title: 'Error',
              text: err.response.data.message,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
  mounted() {
    this.getPolyclinics();
    this.getProducts();
    this.getData();
  },
};
</script>
