<template>
  <div class="card">
    <div class="card-body">
      <Table
        :dataTable="table.dataTable"
        :title="table.title"
        :withPagination="false"
      >
        <template v-slot:thead>
          <thead>
            <tr>
              <th v-for="(data, index) in table.dataTable.header" :key="index">
                {{ data }}
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:action="{ data }">
          <div class="btn-action-block">
            <router-link :to="`/care-center/edit/${data.polyClinicId}`">
              <button
                type="button"
                class="btn btn-rounded btn-warning waves-effect waves-light"
                title="Edit"
              >
                <i class="fa fa-edit"></i>
              </button>
            </router-link>
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue';

export default {
  components: {
    Table,
  },
  data() {
    return {
      table: {
        title: 'Care Center Content Management',
        dataTable: {
          header: ['#', 'Polyclinic Name', 'Last Edited', 'Action'],
          columns: ['counter', 'polyClinicName', 'lastEditedBy'],
          lists: {
            count: 0,
            rows: [],
          },
        },
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.axios
        .get('/cms/care-center')
        .then((res) => {
          const { data } = res.data;
          this.table.dataTable.lists = {
            count: data.length,
            rows: data,
          };
        })
        .catch((err) => {
          console.error(err.response);
        });
    },
  },
};
</script>
