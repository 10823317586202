<template>
  <div class="card col-lg-12 p-0">
    <div class="card-body">
      <h3 class="font-600 mb-2">Update User Address</h3>
      <form @submit.prevent="submit">
        <ValidationObserver class="card-content" ref="userObserver">
          <div class="row">
            <ValidationProvider
              name="User Email"
              v-slot="{ errors }"
              rules="email|required"
              class="col-6"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">User Email</label>
                <input
                  type="text"
                  v-model="email"
                  class="form-control"
                  placeholder="Enter User Email"
                />
                <small class="text-danger" v-if="errors[0]">
                  {{ errors[0] }}
                </small>
              </div>
            </ValidationProvider>
          </div>
        </ValidationObserver>
        <div class="row">
          <div class="text-left col">
            <button
              class="btn btn-primary float-left"
              :disabled="isSearchLoading"
              @click="handleSearchUser"
            >
              <div v-if="isSearchLoading">
                Loading<i class="fa fa-spinner fa-spin"></i>
              </div>
              <div v-else>Search</div>
            </button>
          </div>
        </div>
        <hr v-if="user" />
        <ValidationObserver
          class="card-content"
          ref="addressObserver"
          v-if="user"
        >
          <div class="row">
            <div class="form-group col">
              <label>Address Latitude</label>
              <input
                type="number"
                v-model.number="user.latitude"
                step="any"
                class="form-control"
                placeholder="Enter latitude"
              />
            </div>
            <div class="form-group col">
              <label>Address Longitude</label>
              <input
                type="number"
                v-model.number="user.longitude"
                step="any"
                class="form-control"
                placeholder="Enter longitude"
              />
            </div>
          </div>
        </ValidationObserver>
        <div v-if="user" class="text-left mt-4">
          <button
            type="submit"
            class="btn btn-primary float-left"
            :disabled="isSubmitLoading"
          >
            <div v-if="isSubmitLoading">
              Loading<i class="fa fa-spinner fa-spin"></i>
            </div>
            <div v-else>Submit</div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      user: null,
      isSearchLoading: false,
      isSubmitLoading: false,
    };
  },
  methods: {
    async resetData() {
      this.email = '';
      this.user = null;
      await this.$refs.userObserver.reset();
    },
    getUserDetail(userId) {
      this.axios
        .get(`/users/${userId}`)
        .then((resp) => {
          const { data } = resp.data;
          this.user = {
            ...data,
            latitude: data.latitude === null ? null : Number(data.latitude),
            longitude: data.longitude === null ? null : Number(data.longitude),
          };
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    },
    async handleSearchUser() {
      const isValid = await this.$refs.userObserver.validate();
      if (isValid) {
        this.isSearchLoading = true;

        this.axios
          .get('/v2/users', {
            params: {
              limit: 1,
              email: this.email,
            },
          })
          .then((resp) => {
            if (resp.data.data.rows && resp.data.data.rows.length > 0) {
              this.getUserDetail(resp.data.data.rows[0].id);
            } else {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: 'User not found',
              });
              this.isSearchLoading = false;
            }
          })
          .catch((err) => {
            this.$notify({
              type: 'error',
              title: 'Error',
              text: err.response.data.message,
            });
            this.isSearchLoading = false;
          });
      }
    },
    async submit() {
      const isValid = await this.$refs.addressObserver.validate();
      if (isValid) {
        this.isSubmitLoading = true;

        const payload = {
          lat: this.user.latitude || null,
          long: this.user.longitude || null,
        };

        this.axios
          .post(`/devs/users/${this.user.id}/address`, payload)
          .then((resp) => {
            this.$notify({
              type: 'success',
              title: 'Success!',
              text: resp.data.message,
            });
            this.resetData();
          })
          .catch((err) => {
            this.$notify({
              type: 'error',
              title: 'Error',
              text: err.response.data.message,
            });
          })
          .finally(() => {
            this.isSubmitLoading = false;
          });
      }
    },
  },
};
</script>
