<template>
  <div class="card">
    <div class="card-body">
      <h3 class="font-600 mb-2">{{ polyClinicName }}</h3>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <app-collapse accordion type="margin">
            <!-- Promotion Section -->
            <app-collapse-item title="Promotion">
              <div class="row">
                <ValidationProvider
                  name="Promotion Is Active"
                  v-slot="{ errors }"
                  class="col-2"
                  rules="required"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Is Active ?</label>
                    <select
                      class="form-control"
                      v-model="model.promotion.isActive"
                    >
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                    <small class="val-error" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="model.promotion.items"
                      id="promotionSameAsPromotionHompage"
                      :value="null"
                    />
                    <label
                      class="form-check-label"
                      for="promotionSameAsPromotionHompage"
                      >Same as promotion homepage</label
                    >
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="model.promotion.items"
                      id="promotionPolyClinicSpecific"
                      value="SELECT"
                    />
                    <label
                      class="form-check-label"
                      for="promotionPolyClinicSpecific"
                      >Polyclinic specific</label
                    >
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 5px">
                <ValidationProvider
                  name="Promotion Detail Page"
                  :rules="{ required: model.promotion.items === 'SELECT' }"
                  v-slot="{ errors }"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <multiselect
                      v-model="selectedPromotionDetailPages"
                      label="title"
                      track-by="id"
                      placeholder="Select Promotion Detail Page"
                      :multiple="true"
                      :options="promotionDetailPages"
                      :clear-on-select="true"
                      :close-on-select="false"
                      :disabled="model.promotion.items !== 'SELECT'"
                    >
                      <span slot="noResult">Oops! Data not found.</span>
                    </multiselect>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
            </app-collapse-item>
            <!-- Testimonial Section -->
            <app-collapse-item title="Testimonial">
              <div class="row">
                <ValidationProvider
                  name="Testimonial Is Active"
                  v-slot="{ errors }"
                  class="col-2"
                  rules="required"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Is Active ?</label>
                    <select
                      class="form-control"
                      v-model="model.testimony.isActive"
                    >
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <div class="form-group col">
                  <label for="testimonyConsultStatus">Enabled Status</label>
                  <multiselect
                    v-model="model.testimony.consultStatus"
                    label="label"
                    track-by="enum"
                    placeholder="Select Consult Status"
                    :multiple="true"
                    :options="consultStatusOptions"
                    :clear-on-select="true"
                    :close-on-select="false"
                    id="testimonyConsultStatus"
                  >
                    <span slot="noResult">Oops! Data not found.</span>
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label for="testimonyTitle">Title</label>
                  <input
                    type="text"
                    id="testimonyTitle"
                    v-model="model.testimony.title"
                    class="form-control"
                    placeholder="Enter Title"
                  />
                </div>
                <div class="form-group col">
                  <label for="testimonySubtitle">Subtitle</label>
                  <input
                    type="text"
                    id="testimonySubtitle"
                    v-model="model.testimony.subTitle"
                    class="form-control"
                    placeholder="Enter Subtitle"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label for="testimonyCTACopy">CTA Copy</label>
                  <input
                    type="text"
                    id="testimonyCTACopy"
                    v-model="model.testimony.ctaCopy"
                    class="form-control"
                    placeholder="Enter CTA Copy"
                  />
                </div>
                <ValidationProvider
                  name="Testimony URL"
                  v-slot="{ errors }"
                  rules="url"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label for="testimonyUrl">URL</label>
                    <input
                      type="text"
                      id="testimonyUrl"
                      v-model="model.testimony.ctaURL"
                      class="form-control"
                      placeholder="Enter URL"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <ValidationProvider
                  name="Testimony Deeplink URL"
                  v-slot="{ errors }"
                  rules="url-deeplink"
                  class="col-6"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label for="testimonyDeeplinkUrl">Deeplink URL</label>
                    <input
                      type="text"
                      id="testimonyDeeplinkUrl"
                      v-model="model.testimony.ctaDeeplink"
                      class="form-control"
                      placeholder="Enter Deeplink URL"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
            </app-collapse-item>
            <!-- Education Section -->
            <app-collapse-item title="Education">
              <div class="row">
                <ValidationProvider
                  name="Education Is Active"
                  v-slot="{ errors }"
                  class="col-2"
                  rules="required"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Is Active ?</label>
                    <select
                      class="form-control"
                      v-model="model.education.isActive"
                    >
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <div class="form-group col">
                  <label for="educationConsultStatus">Enabled Status</label>
                  <multiselect
                    v-model="model.education.consultStatus"
                    label="label"
                    track-by="enum"
                    placeholder="Select Consult Status"
                    :multiple="true"
                    :options="consultStatusOptions"
                    :clear-on-select="true"
                    :close-on-select="false"
                    id="educationConsultStatus"
                  >
                    <span slot="noResult">Oops! Data not found.</span>
                  </multiselect>
                </div>
              </div>
              <div class="row">
                <ValidationProvider
                  name="Education Title"
                  v-slot="{ errors }"
                  class="col"
                  rules="required"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label for="educationTitle" class="required">Title</label>
                    <input
                      type="text"
                      id="educationTitle"
                      v-model="model.education.title"
                      class="form-control"
                      placeholder="Enter Title"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="Education Subtitle"
                  v-slot="{ errors }"
                  class="col"
                  rules="required"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label for="educationSubtitle" class="required"
                      >Subtitle</label
                    >
                    <input
                      type="text"
                      id="educationSubtitle"
                      v-model="model.education.subTitle"
                      class="form-control"
                      placeholder="Enter Subtitle"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row mb-1">
                <div class="col">
                  <span class="f-16 font-weight-bold">Contents</span>
                </div>
              </div>
              <div
                class="row"
                v-for="(item, idx) in model.education.items"
                :key="idx"
              >
                <div class="col">
                  <div v-if="item.flow === null" class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      value="Default"
                      disabled
                    />
                  </div>
                  <ValidationProvider
                    v-else
                    :name="`Flow Name ${idx + 1}`"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <multiselect
                        v-model="model.education.items[idx].flow"
                        label="title"
                        track-by="id"
                        placeholder="Select Flow"
                        :options="getEducationFlows(idx)"
                        :allow-empty="false"
                        deselect-label=""
                      >
                        <span slot="noResult">Oops! Data not found.</span>
                      </multiselect>
                      <small class="text-muted" v-if="errors[0]">{{
                        errors[0]
                      }}</small>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col form-group">
                  <multiselect
                    v-model="model.education.items[idx].schoolDirectory"
                    label="title"
                    track-by="id"
                    placeholder="Select School Directory"
                    :options="schoolDirectories"
                  >
                    <span slot="noResult">Oops! Data not found.</span>
                  </multiselect>
                </div>
                <div
                  class="col-2 d-flex align-items-start"
                  style="gap: 0.25rem"
                >
                  <button
                    class="btn btn-info"
                    @click="addEducationContent(idx + 1)"
                    type="button"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                  <button
                    v-if="item.flow !== null"
                    class="btn btn-danger"
                    @click="removeEducationContent(idx)"
                    type="button"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </app-collapse-item>
            <!-- Product Section -->
            <app-collapse-item title="Product">
              <div class="row">
                <ValidationProvider
                  name="Product Is Active"
                  v-slot="{ errors }"
                  class="col-2"
                  rules="required"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Is Active ?</label>
                    <select
                      class="form-control"
                      v-model="model.product.isActive"
                    >
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <div class="form-group col">
                  <label for="productConsultStatus">Enabled Status</label>
                  <multiselect
                    v-model="model.product.consultStatus"
                    label="label"
                    track-by="enum"
                    placeholder="Select Consult Status"
                    :multiple="true"
                    :options="consultStatusOptions"
                    :clear-on-select="true"
                    :close-on-select="false"
                    id="productConsultStatus"
                  >
                    <span slot="noResult">Oops! Data not found.</span>
                  </multiselect>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col">
                  <span class="f-16 font-weight-bold">Products</span>
                </div>
              </div>
              <div
                v-for="(item, idx) in model.product.items"
                :key="idx"
                class="mb-2"
              >
                <div class="row">
                  <div class="col-6">
                    <div v-if="item.flow === null" class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        value="Default"
                        disabled
                      />
                    </div>
                    <ValidationProvider
                      v-else
                      :name="`Flow Name ${idx + 1}`"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div
                        class="form-group"
                        :class="{ 'validation-warning': errors[0] }"
                      >
                        <multiselect
                          v-model="model.product.items[idx].flow"
                          label="title"
                          track-by="id"
                          placeholder="Select Flow"
                          :options="getProductFlows(idx)"
                          :allow-empty="false"
                          deselect-label=""
                        >
                          <span slot="noResult">Oops! Data not found.</span>
                        </multiselect>
                        <small class="text-muted" v-if="errors[0]">{{
                          errors[0]
                        }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col">
                    <label :for="`productTitle${idx + 1}`">Title</label>
                    <input
                      type="text"
                      :id="`productTitle${idx + 1}`"
                      v-model="model.product.items[idx].title"
                      class="form-control"
                      placeholder="Enter Title"
                    />
                  </div>
                  <div class="form-group col">
                    <label :for="`productSubtitle${idx + 1}`">Subtitle</label>
                    <input
                      type="text"
                      :id="`productSubtitle${idx + 1}`"
                      v-model="model.product.items[idx].subTitle"
                      class="form-control"
                      placeholder="Enter Subtitle"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <multiselect
                      v-model="model.product.items[idx].products"
                      label="title"
                      track-by="id"
                      placeholder="Select Product"
                      :options="products"
                      :multiple="true"
                      :clear-on-select="true"
                      :close-on-select="false"
                      :max="10"
                    >
                      <span slot="noResult">Oops! Data not found.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-2 d-flex align-items-start"
                    style="gap: 0.25rem"
                  >
                    <button
                      class="btn btn-info"
                      @click="addProductItem(idx + 1)"
                      type="button"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                    <button
                      v-if="item.flow !== null"
                      class="btn btn-danger"
                      @click="removeProductItem(idx)"
                      type="button"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
                <hr />
              </div>
            </app-collapse-item>
          </app-collapse>
          <div class="row">
            <div class="col">
              <router-link to="/care-center" class="btn text-danger"
                >Cancel</router-link
              >
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="isLoading"
              >
                <div v-if="isLoading">
                  Updating <i class="fa fa-spinner fa-spin"></i>
                </div>
                <div v-else>Update</div>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      polyClinicName: '',
      isLoading: false,
      model: {
        promotion: {
          isActive: true,
          items: null, // null or 'SELECT'
        },
        testimony: {
          isActive: true,
          consultStatus: [],
          title: '',
          subTitle: '',
          ctaCopy: '',
          ctaURL: '',
          ctaDeeplink: '',
        },
        education: {
          isActive: true,
          consultStatus: [],
          title: '',
          subTitle: '',
          items: [
            {
              flow: null, // Default
              schoolDirectory: null,
            },
          ],
        },
        product: {
          isActive: true,
          consultStatus: [],
          items: [
            {
              flow: null, // Default
              title: '',
              subTitle: '',
              products: [],
            },
          ],
        },
      },
      selectedPromotionDetailPages: [],
      promotionDetailPages: [],
      consultStatusOptions: [],
      schoolDirectories: [],
      flows: [],
      products: [],
    };
  },
  beforeMount() {
    this.axios
      .get(`/cms/care-center/${this.$route.params.id}`)
      .then((res) => {
        const { polyClinicName, promotion, testimony, education, product } =
          res.data.data;

        this.polyClinicName = polyClinicName;

        // Promotion
        this.$set(this.model, 'promotion', {
          ...promotion,
          items: promotion.items === null ? null : 'SELECT',
        });
        this.selectedPromotionDetailPages = promotion.items || [];

        // Testimony
        this.$set(this.model, 'testimony', testimony);

        // Education
        this.$set(this.model, 'education', education);

        // Product
        this.$set(this.model, 'product', product);
      })
      .catch((err) => {
        console.error(err);
      });
  },
  mounted() {
    this.getPromotionDetailPages();
    this.getConsultStatus();
    this.getFlows();
    this.getSchoolDirectories();
    this.getProducts();
  },
  methods: {
    getEducationFlows(idx) {
      const selectedFlowIds = [
        ...this.model.education.items.slice(0, idx),
        ...this.model.education.items.slice(idx + 1),
      ].map((item) => item?.flow?.id ?? null);
      return this.flows.filter((flow) => !selectedFlowIds.includes(flow.id));
    },
    getProductFlows(idx) {
      const selectedFlowIds = [
        ...this.model.product.items.slice(0, idx),
        ...this.model.product.items.slice(idx + 1),
      ].map((item) => item?.flow?.id ?? null);
      return this.flows.filter((flow) => !selectedFlowIds.includes(flow.id));
    },
    getPromotionDetailPages() {
      this.axios
        .get('/promotion-banners')
        .then((res) => {
          this.promotionDetailPages = res.data.data.rows.map((item) => ({
            id: item.id,
            title: item.title,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getConsultStatus() {
      this.axios
        .get('/misc/consult-status')
        .then((res) => {
          this.consultStatusOptions = res.data.data.map((item) => ({
            enum: item.enum,
            label: item.label,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFlows() {
      this.axios
        .get('/misc/flows', { params: { polyClinicId: this.$route.params.id } })
        .then((res) => {
          this.flows = res.data.data.map((item) => ({
            id: item.id,
            title: item.title,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getSchoolDirectories() {
      this.axios
        .get('/otc/school-directory')
        .then((res) => {
          this.schoolDirectories = res.data.data.rows.map((item) => ({
            id: item.id,
            title: item.title,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getProducts() {
      this.axios
        .get('/products', { params: { isActive: true, isDiscoverable: true } })
        .then((res) => {
          this.products = res.data.data.rows.map((item) => ({
            id: item.id,
            title: item.title,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addEducationContent(index) {
      this.model.education.items.splice(index, 0, {
        flow: undefined,
        schoolDirectory: null,
      });
    },
    removeEducationContent(index) {
      this.model.education.items = [
        ...this.model.education.items.slice(0, index),
        ...this.model.education.items.slice(index + 1),
      ];
    },
    addProductItem(index) {
      this.model.product.items.splice(index, 0, {
        flow: undefined,
        title: '',
        subTitle: '',
        products: [],
      });
    },
    removeProductItem(index) {
      this.model.product.items = [
        ...this.model.product.items.slice(0, index),
        ...this.model.product.items.slice(index + 1),
      ];
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        const data = {
          ...this.model,
          promotion: {
            ...this.model.promotion,
            items: undefined,
            itemIds:
              this.model.promotion.items === 'SELECT'
                ? this.selectedPromotionDetailPages.map((item) => item.id)
                : null,
          },
          testimony: {
            ...this.model.testimony,
            consultStatus: undefined,
            consultStatusEnums: this.model.testimony.consultStatus.map(
              (item) => item.enum
            ),
          },
          education: {
            ...this.model.education,
            consultStatus: undefined,
            consultStatusEnums: this.model.education.consultStatus.map(
              (item) => item.enum
            ),
            items: this.model.education.items.map((item) => ({
              flowId: item?.flow?.id ?? null,
              schoolDirectoryId: item?.schoolDirectory?.id ?? null,
            })),
          },
          product: {
            ...this.model.product,
            consultStatus: undefined,
            consultStatusEnums: this.model.product.consultStatus.map(
              (item) => item.enum
            ),
            items: this.model.product.items.map((item) => ({
              ...item,
              flow: undefined,
              flowId: item?.flow?.id ?? null,
              products: undefined,
              productIds: item.products.map((product) => product.id),
            })),
          },
        };
        this.isLoading = true;
        this.axios
          .put(`/cms/care-center/${this.$route.params.id}`, data)
          .then((res) => {
            this.$notify({
              type: 'success',
              title: 'Success!',
              text: res.data.message,
            });
            this.$router.push('/care-center');
          })
          .catch((err) => {
            this.isLoading = false;
            this.$notify({
              type: 'error',
              title: 'Error',
              text: err.response.data.message,
            });
          });
      }
    },
  },
};
</script>
