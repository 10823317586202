<template>
	<div class="card">
		<div class="card-body">
			<h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Regimen</h3>
			<ValidationObserver class="card-content" ref="observer">
				<form @submit.prevent="submit">
					<div class="row">
						<ValidationProvider name="IG story banner" v-slot="{ errors, validate }" class="col" :rules="typeof models.igStoryImageUrl === 'string' ? 'image|size:2000|max-width-dimensions:1080' : 'required|image|size:2000|max-width-dimensions:1080'">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="igStoryBanner" class="required">IG Story Banner</label>
								<small class="block">Aspect ratio: 9:16</small>
								<small class="block">Recommended dimensions: 1080 x 1920 pixels</small>
								<small class="block">Max width: 1080 pixels</small>
								<div class="row mt-1">
									<div class="col">
										<div class="position-relative">
												<img v-if="models.igStoryImageUrl" @click="$refs.regimenStory.click()" :src="getUrlOfFile(models.igStoryImageUrl)" class="responsive m-0 cursor-pointer img-fluid img-story-banner" />
												<skeleton-img v-else @click="$refs.regimenStory.click()" class="img-story-banner"/>
												<button v-if="models.igStoryImageUrl" @click="removeImage('igStoryImageUrl')" class="btn btn-rounded btn-danger position-absolute" 
														style="top: -10px; right: -5px"
														title="Delete Image"
														type="button">
														<i class="fa fa-times"></i>
												</button>
										</div>
										<input 
												id="igStoryBanner"
												ref="regimenStory" 
												type="file" 
												accept=".png, .jpg, .jpeg" 
												@change="(value) => {
														validate(value)
														.then((resp) => validateImage(resp, value, 'igStoryImageUrl'));
												}"
												name="faqIcon" 
												class="form-control d-none">
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</div>
							</div>
						</ValidationProvider>
						<ValidationProvider name="Regimen thumbnail" v-slot="{ errors, validate }" class="col" :rules="typeof models.thumbnailUrl === 'string' ? 'image|size:2000|max-width-dimensions:480' : 'required|image|size:2000|max-width-dimensions:480'">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="regimen_name" class="required">Regimen Thumbnail</label>
								<small class="block">Aspect ratio: 2:3</small>
								<small class="block">Recommended dimensions: 480 x 720 pixels</small>
								<small class="block">Max width: 480 pixels</small>
									<div class="row mt-1">
										<div class="col-12">
											<div class="position-relative">
													<img v-if="models.thumbnailUrl" @click="$refs.regimenThumbnail.click()" :src="getUrlOfFile(models.thumbnailUrl)" class="responsive m-0 cursor-pointer img-fluid img-regimen-thumbnail" />
													<skeleton-img v-else @click="$refs.regimenThumbnail.click()" class="img-regimen-thumbnail"/>

													<button v-if="models.thumbnailUrl" @click="removeImage('thumbnailUrl')" class="btn btn-rounded btn-danger position-absolute" 
															style="top: -10px; right: -5px"
															title="Delete Image"
															type="button">
															<i class="fa fa-times"></i>
													</button>
											</div>
											<input 
													ref="regimenThumbnail" 
													type="file" 
													accept=".png, .jpg, .jpeg" 
													@change="(value) => {
															validate(value)
															.then((resp) => validateImage(resp, value, 'thumbnailUrl'));
													}"
													name="faqIcon" 
													class="form-control d-none">
											<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
										</div>
								</div>
							</div>
						</ValidationProvider>
						<ValidationProvider name="Regimen icon" v-slot="{ errors, validate }" class="col" rules="image|size:2000|max-width-dimensions:180">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="regimen_name">Regimen Icon</label>
								<small class="block">Aspect ratio: 1:1</small>
								<small class="block">Recommended dimensions: 180 x 180 pixels</small>
								<small class="block">Max width: 180 pixels</small>
									<div class="row mt-1">
										<div class="col-12">
											<div class="position-relative">
													<img v-if="models.iconUrl" @click="$refs.regimenIcon.click()" :src="getUrlOfFile(models.iconUrl)" class="responsive m-0 cursor-pointer img-fluid img-icon-thumbnail aspect-ratio-1/1" />
													<skeleton-img v-else @click="$refs.regimenIcon.click()" class="img-icon-thumbnail aspect-ratio-1/1"/>

													<button v-if="models.iconUrl" @click="removeImage('iconUrl')" class="btn btn-rounded btn-danger position-absolute" 
															style="top: -10px; right: -5px"
															title="Delete Image"
															type="button">
															<i class="fa fa-times"></i>
													</button>
											</div>
											<input 
													ref="regimenIcon" 
													type="file" 
													accept=".png, .jpg, .jpeg" 
													@change="(value) => {
															validate(value)
															.then((resp) => validateImage(resp, value, 'iconUrl'));
													}"
													name="faqIcon" 
													class="form-control d-none">
											<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
										</div>
								</div>
							</div>
						</ValidationProvider>
					</div>
					<div class="row">
						<ValidationProvider name="Regimen Name" v-slot="{ errors }" class="col" rules="required|maxWords:225">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="regimen_name" class="required">Regimen Name</label>
								<input v-model="models.name" type="text" class="form-control" id="regimen_name" placeholder="Enter regimen name">
								<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<ValidationProvider vid="slug" name="Slug" v-slot="{ errors }" class="col" rules="required|slug|maxWords:225">
							<div class="form-group" :class="{ 'validation-warning': isSlugExists || errors[0] }">
								<label for="slug" class="required">Slug</label>
								<input @keydown.space.prevent v-model="models.slug" type="text" class="form-control" ref="slugField" id="slug" placeholder="Enter slug">
								<small class="text-muted" v-if="isSlugExists">The slug is already exist</small>
								<small class="text-muted" v-else-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<ValidationProvider name="Display Name" v-slot="{ errors }" class="col" rules="required|maxWords:30">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="display_name" class="required">Regimen Display Name</label>
								<input v-model="models.displayName" type="text" class="form-control" id="display_name" placeholder="Enter regimen display name">
								<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
					</div>
					<div class="row">
						<ValidationProvider name="Category" v-slot="{ errors }" class="col" rules="required">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="category" class="required">Category</label>
									<multiselect
											style="white-space: nowrap"
											selectLabel=""
											v-model="models.polyClinicId"
											id="category"
											label="namePage"
											track-by="id" 
											placeholder="Search category"
											:options="masters.polyclics"
											searchable
											:max-height="600">
											<span slot="noResult">Oops! There is no category.</span>
									</multiselect>
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<ValidationProvider name="Selected Flow" v-slot="{ errors }" class="col" rules="required">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="selected_flow" class="required">Selected Flow</label>
									<multiselect
											style="white-space: nowrap"
											selectLabel=""
											id="selected_flow"
											v-model="models.flowId"
											label="title"
											track-by="id" 
											placeholder="Search flow"
											:options="masters.flows"
											searchable
											:max-height="600">
											<span slot="noResult">Oops! There is no flow.</span>
									</multiselect>
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
					</div>
					<ValidationProvider name="Description" v-slot="{ errors }" rules="max:200">
						<div class="form-group" :class="{ 'validation-warning': errors[0] }">
							<label for="description">Description (max 200 chars)</label>
							<textarea v-model="models.description" class="form-control" id="description" placeholder="Enter description" rows="4"></textarea>
							<small class="text-muted" v-if="errors[0]">Max 200 chars</small>
						</div>
					</ValidationProvider>
					<div class="banner-section mb-3 px-1">
						<div v-for="(banner, index) in models.banners" :key="index">
							<div class="border border-primary py-2 row" style="border-style: dashed !important;">
								<ValidationProvider :name="`Banner regimen ${index + 1}`" v-slot="{ errors, validate }" class="col" :rules="typeof banner.imageUrl === 'string' ? 'image|size:2000|max-width-dimensions:1080' : 'required|image|size:2000|max-width-dimensions:1080'">
									<div class="form-group " :class="{ 'validation-warning': errors[0] }">
										<label class="required">Banner</label>
										<small class="block">Aspect ratio: 2:1</small>
										<small class="block">Recommended dimensions: 1080 x 540 pixels</small>
										<small class="block">Max width: 1080 pixels</small>
										<div class="row mt-1">
											<div class="position-relative w-100 pl-1">
												<img v-if="banner.imageUrl" @click="$refs.bannerImage[index].click()" :src="getUrlOfFile(banner.imageUrl)" class="responsive m-0 cursor-pointer img-fluid aspect-ratio-2/1"/>
												<skeleton-img class="aspect-ratio-2/1" v-else @click="$refs.bannerImage[index].click()"/>

												<button v-if="banner.imageUrl" @click="banner.imageUrl = null" class="btn btn-rounded btn-danger position-absolute" 
														style="top: -10px; right: -5px"
														title="Delete Image"
														type="button">
														<i class="fa fa-times"></i>
												</button>
											</div>
										</div>
										<input 
												ref="bannerImage"
												type="file" 
												accept=".png, .jpg, .jpeg" 
												@change="(value) => {
														validate(value)
														.then((resp) => validateImage(resp, value, 'banners', 'imageUrl', index));
												}" 
												label="Image" 
												:name="`imagePage-${index}`" 
												class="form-control d-none">
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
								<ValidationProvider :name="`Banner url ${index + 1}`" v-slot="{ errors }" class="col-7" rules="url">
									<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label for="banner_url">URL</label>
										<input v-model="banner.route" type="url" class="form-control" id="banner_url" placeholder="Enter url">
										<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
									</div>
								</ValidationProvider>
							</div>

							<div class="mt-1" :class="{'mb-2': index !== models.banners.length - 1}">
								<button v-if="models.banners.length > 1" type="button" class="btn btn-danger mr-2" @click="deleteItem('banners', index)">Delete Content</button>&nbsp;
								<button v-if="index === models.banners.length - 1" type="button" class="btn btn-primary" @click="pushItem('banners')">Add Content</button>
							</div>
						</div>
					</div>

					
					<section class="regimen">
						<h5 class="font-500 mb-2">Product Regimen</h5>
						<div v-for="(regimen, index) in models.regimenProducts" :key="index" class="row">
							<ValidationProvider :name="`Step title ${index + 1}`" v-slot="{ errors }" rules="required|maxWords:60" class="col">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="step_title" class="required">Step Title</label>
									<input v-model="regimen.stepTitle" type="text" class="form-control h-100" id="step_title" placeholder="Enter title">
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
							<ValidationProvider :name="`Product catalog ${index + 1}`" v-slot="{ errors }" class="col" rules="required">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label :for="`product_catalog_${index + 1}`" class="required">Product Catalog</label>
									<multiselect
											style="white-space: nowrap"
											selectLabel=""
											:id="`product_catalog_${index + 1}`"
											v-model="regimen.productId"
											label="title"
											track-by="id" 
											placeholder="Search product"
											:options="masters.products"
											searchable
											:max-height="600">
											<span slot="noResult">Oops! There is no product.</span>
									</multiselect>
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
							<ValidationProvider :name="`Usage product ${index + 1}`" v-slot="{ errors }" class="col" rules="required">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label class="required">Usage</label>
										<multiselect 
												style="white-space: nowrap"
											selectLabel=""
											selectedLabel=""
											deselectLabel=""
											v-model="regimen.usage"
											:options="masters.usages"
											multiple
											track-by="value"
											:show-labels="false"
											label="value"
											:close-on-select="false"
											:searchable="false"
										>
											<span slot="singleLabel" slot-scope="props">{{props.option.value}}</span>
										</multiselect>
										<small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
							<div class="col align-self-center">
								<div class="row">
									<div class="col align-self-center">
										<div class="form-check">
											<input v-model="regimen.isImportant" class="form-check-input" type="checkbox" value="" :id="`isImportantCheck${index}`">
											<label class="form-check-label" :for="`isImportantCheck${index}`">
												Penting
											</label>
										</div>
									</div>
									<div class="col">
										<div class="row">
											<button v-if="index === models.regimenProducts.length - 1" @click="pushItem('regimenProducts')" type="button" class="btn btn-info mr-1 btn-sm"><i class="fa fa-plus"></i></button>
											<button v-if="models.regimenProducts.length > 1" @click="deleteItem('regimenProducts', index)" type="button" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					
					<h5 class="font-500 my-2">What To Do</h5>
					<section class="what-to-do border border-primary p-2" style="border-style: dashed !important;">
						<ValidationProvider name="What to do title" v-slot="{ errors }" rules="maxWords:225">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="wtd_title">Title</label>
								<input v-model="models.whatToDoTitle" type="text" class="form-control" id="wtd_title" placeholder="Enter title">
								<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<ValidationProvider name="What to do description" v-slot="{ errors }">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="wtd_description">Description</label>
								<quill-editor v-model="models.whatToDoDescription" :options="editorOption"></quill-editor>
								<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<div class="row">
							<ValidationProvider name="What to do thumbnail" v-slot="{ errors, validate }" class="col-3" rules="image|size:2000|max-width-dimensions:320">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label class="mb-2">Thumbnail Icon</label>
										<small class="block">Aspect ratio: 1:1</small>
										<small class="block">Recommended dimensions: 320 x 320 pixels</small>
										<small class="block">Max width: 320 pixels</small>
										<div class="row">
											<div class="col-12">
												<div class="position-relative">
														<img v-if="models.whatToDoThumbnailUrl" @click="$refs.wtdThumbnail.click()" :src="getUrlOfFile(models.whatToDoThumbnailUrl)" class="responsive m-0 cursor-pointer img-fluid img-icon-thumbnail" />
														<skeleton-img v-else @click="$refs.wtdThumbnail.click()" class="img-icon-thumbnail"/>
	
														<button v-if="models.whatToDoThumbnailUrl" @click="removeImage('whatToDoThumbnailUrl')" class="btn btn-rounded btn-danger position-absolute" 
																style="top: -10px; right: -5px"
																title="Delete Image"
																type="button">
																<i class="fa fa-times"></i>
														</button>
												</div>
												<input 
														ref="wtdThumbnail" 
														type="file" 
														accept=".png, .jpg, .jpeg" 
														@change="(value) => {
																validate(value)
																.then((resp) => validateImage(resp, value, 'whatToDoThumbnailUrl'));
														}"
														name="faqIcon" 
														class="form-control d-none">
												<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
											</div>
									</div>
								</div>
							</ValidationProvider>
						</div>
						<hr>
						<label>Icons</label>
						<small class="block">Aspect ratio: 1:1</small>
						<small class="block">Recommended dimensions: 180 x 180 pixels</small>
						<small class="block">Max width: 180 pixels</small>
						<div class="row mt-1" v-for="(icon, index) in models.whatToDoIcons" :key="index">
							<ValidationProvider :name="`WTD Icon ${index + 1}`" v-slot="{ errors, validate }" class="col-3" rules="image|size:2000|max-width-dimensions:180">
								<div class="form-group px-1" :class="{ 'validation-warning': errors[0] }">
									<div class="row">
										<div class="position-relative w-100">
											<img v-if="icon.iconUrl" @click="$refs.wtdIcon[index].click()" :src="getUrlOfFile(icon.iconUrl)" class="responsive m-0 cursor-pointer img-fluid img-icon-thumbnail"/>
											<skeleton-img class="img-icon-thumbnail" v-else @click="$refs.wtdIcon[index].click()"/>

											<button v-if="icon.iconUrl" @click="icon.iconUrl = null" class="btn btn-rounded btn-danger position-absolute" 
													style="top: -10px; right: -5px"
													title="Delete Image"
													type="button">
													<i class="fa fa-times"></i>
											</button>
										</div>
									</div>
									<input 
											ref="wtdIcon"
											type="file" 
											accept=".png, .jpg, .jpeg" 
											@change="(value) => {
													validate(value)
													.then((resp) => validateIcons(resp, value, 'whatToDoIcons', 'iconUrl', index))
											}" 
											class="form-control d-none">
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
							<ValidationProvider :name="`WTD Icon Text ${index + 1}`" v-slot="{ errors }" rules="maxWords:30" class="col"> 								
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="wtd_icon_title">Title</label>
									<input v-model="icon.title" type="text" class="form-control" id="wtd_icon_title" placeholder="Enter title">
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
								<button v-if="models.whatToDoIcons.length > 1" @click="deleteItem('whatToDoIcons', index)" type="button" class="btn mr-1 btn-danger btn-sm"><i class="fa fa-trash"></i></button>
								<button v-if="index === models.whatToDoIcons.length - 1" type="button" class="btn btn-primary btn-sm" @click="pushItem('whatToDoIcons')">Add Content</button>
							</ValidationProvider>
						</div>
					</section>

					<h5 class="font-500 my-2">What Not To Do</h5>
					<section class="what-to-do border border-primary p-2" style="border-style: dashed !important;">
						<ValidationProvider name="What not to do title" v-slot="{ errors }" rules="maxWords:225">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="wntd_title">Title</label>
								<input v-model="models.whatNotToDoTitle" type="text" class="form-control" id="wntd_title" placeholder="Enter title">
								<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<ValidationProvider name="What not to do description" v-slot="{ errors }">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="wntd_description">Description</label>
								<quill-editor v-model="models.whatNotToDoDescription" :options="editorOption"></quill-editor>
								<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<div class="row">
							<ValidationProvider name="What not to do thumbnail" v-slot="{ errors, validate }" class="col-3" rules="image|size:2000|max-width-dimensions:320">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
										<label class="mb-2">Thumbnail Icon</label>
										<small class="block">Aspect ratio: 1:1</small>
										<small class="block">Recommended dimensions: 320 x 320 pixels</small>
										<small class="block">Max width: 320 pixels</small>
										<div class="row">
											<div class="col-12">
												<div class="position-relative">
														<img v-if="models.whatNotToDoThumbnailUrl" @click="$refs.wntdThumbnail.click()" :src="getUrlOfFile(models.whatNotToDoThumbnailUrl)" class="responsive m-0 cursor-pointer img-fluid img-icon-thumbnail" />
														<skeleton-img v-else @click="$refs.wntdThumbnail.click()" class="img-icon-thumbnail"/>
	
														<button v-if="models.whatNotToDoThumbnailUrl" @click="removeImage('whatNotToDoThumbnailUrl')" class="btn btn-rounded btn-danger position-absolute" 
																style="top: -10px; right: -5px"
																title="Delete Image"
																type="button">
																<i class="fa fa-times"></i>
														</button>
												</div>
												<input 
														ref="wntdThumbnail" 
														type="file" 
														accept=".png, .jpg, .jpeg" 
														@change="(value) => {
																validate(value)
																.then((resp) => validateImage(resp, value, 'whatNotToDoThumbnailUrl'));
														}"
														name="faqIcon" 
														class="form-control d-none">
												<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
											</div>
									</div>
								</div>
							</ValidationProvider>
						</div>
						<hr>
						<label>Icons</label>
						<small class="block">Aspect ratio: 1:1</small>
						<small class="block">Recommended dimensions: 180 x 180 pixels</small>
						<small class="block">Max width: 180 pixels</small>
						<div class="row mt-1" v-for="(icon, index) in models.whatNotToDoIcons" :key="index">
							<ValidationProvider :name="`WNTD Icon ${index + 1}`" v-slot="{ errors, validate }" class="col-3" rules="image|size:2000|max-width-dimensions:180">
								<div class="form-group px-1" :class="{ 'validation-warning': errors[0] }">
									<div class="row">
										<div class="position-relative w-100">
											<img v-if="icon.iconUrl" @click="$refs.wntdIcon[index].click()" :src="getUrlOfFile(icon.iconUrl)" class="responsive m-0 cursor-pointer img-fluid img-icon-thumbnail"/>
											<skeleton-img class="img-icon-thumbnail" v-else @click="$refs.wntdIcon[index].click()"/>

											<button v-if="icon.iconUrl" @click="icon.iconUrl = null" class="btn btn-rounded btn-danger position-absolute" 
													style="top: -10px; right: -5px"
													title="Delete Image"
													type="button">
													<i class="fa fa-times"></i>
											</button>
										</div>
									</div>
									<input 
											ref="wntdIcon"
											type="file" 
											accept=".png, .jpg, .jpeg" 
											@change="(value) => {
													validate(value)
													.then((resp) => validateIcons(resp, value, 'whatNotToDoIcons', 'iconUrl', index))
											}"
											class="form-control d-none">
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
							<ValidationProvider :name="`WNTD Icon Text ${index + 1}`" v-slot="{ errors }" rules="maxWords:30" class="col"> 								
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="wntd_icon_title">Title</label>
									<input v-model="icon.title" type="text" class="form-control" id="wntd_icon_title" placeholder="Enter title">
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
								<button v-if="models.whatNotToDoIcons.length > 1" @click="deleteItem('whatNotToDoIcons', index)" type="button" class="btn btn-danger btn-sm mr-1"><i class="fa fa-trash"></i></button>
								<button v-if="index === models.whatNotToDoIcons.length - 1" type="button" class="btn btn-primary btn-sm" @click="pushItem('whatNotToDoIcons')">Add Content</button>
							</ValidationProvider>
						</div>
					</section>

					<section class="what-not-to-do px-1">
						<h5 class="font-500 my-2">Consult to doctor</h5>
						<ValidationProvider name="Consult doctor title" v-slot="{ errors }" rules="required|maxWords:225">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="consult_title" class="required">Title</label>
								<input v-model="models.consultationTitle" type="text" class="form-control" id="consult_title" placeholder="Enter title">
								<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<ValidationProvider name="Consult doctor description" v-slot="{ errors }" rules="required">
							<div class="form-group" :class="{ 'validation-warning': errors[0] }">
								<label for="consult_description" class="required">Description</label>
								<textarea v-model="models.consultationDescription" class="form-control" id="consult_description" placeholder="Enter description" rows="4"></textarea>
								<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
							</div>
						</ValidationProvider>
						<div class="row">
							<ValidationProvider name="Consultation image" v-slot="{ errors, validate }" class="col-3" :rules="typeof models.consultationImageUrl === 'string' ? 'image|size:2000|max-width-dimensions:960' : 'required|image|size:2000|max-width-dimensions:960'">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="consultationDoctor" class="required">Image</label>
									<small class="block">Aspect ratio: 1:1</small>
									<small class="block">Recommended dimensions: 960 x 960 pixels</small>
									<small class="block">Max width: 960 pixels</small>
									<div class="row mt-1">
										<div class="position-relative w-100 pl-1">
											<img v-if="models.consultationImageUrl" @click="$refs.consultationDoctor.click()" :src="getUrlOfFile(models.consultationImageUrl)" class="responsive m-0 cursor-pointer img-fluid img-consult-doctor"/>
											<skeleton-img class="img-consult-doctor" v-else @click="$refs.consultationDoctor.click()"/>

											<button v-if="models.consultationImageUrl" @click="models.consultationImageUrl = null" class="btn btn-rounded btn-danger position-absolute" 
													style="top: -10px; right: -5px"
													title="Delete Image"
													type="button">
													<i class="fa fa-times"></i>
											</button>
										</div>
									</div>
									<input 
											id="consultationDoctor"
											ref="consultationDoctor"
											type="file" 
											accept=".png, .jpg, .jpeg" 
											@change="(value) => {
													validate(value)
													.then((resp) => validateImage(resp, value, 'consultationImageUrl'));
											}" 
											label="Image"  
											class="form-control d-none">
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
							<ValidationProvider name="Consultation button name" v-slot="{ errors }" rules="required|maxWords:225" class="col">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="consultation_btn_name" class="required">Button Name</label>
									<input v-model="models.consultationButtonName" type="text" class="form-control" id="consultation_btn_name" placeholder="Enter button name">
									<small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
								</div>
							</ValidationProvider>
						</div>
					</section>

					<div class="">
						<router-link to="/regimens" class="btn text-danger">Cancel</router-link>
						<button type="submit" class="btn btn-primary " :disabled="isLoading">
							<div v-if="isLoading">{{$route.params.id ? 'Updating' : 'Creating'}}<i class="fa fa-spinner fa-spin"></i></div>
							<div v-else>{{$route.params.id ? 'Update' : 'Create'}}</div>
						</button>
					</div>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import {getUrlOfFile, getImages, convertFiletoURL, strSlug, isFalsyObj} from '@/helper'
const toolbarOptions = [
	['bold', 'italic', 'underline', 'strike'],
	['blockquote', 'code-block'],
	[{ 'header': 1 }, { 'header': 2 }],
	[{ 'list': 'ordered' }, { 'list': 'bullet' }],
	[{ 'script': 'sub' }, { 'script': 'super' }],
	[{ 'indent': '-1' }, { 'indent': '+1' }],
	[{ 'direction': 'rtl' }],
	[{ 'size': ['small', false, 'large', 'huge'] }],
	[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	[{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
	[{ 'font': [] }],
	[{ 'align': [] }],
	['clean'],
	['link']
]
export default {
	data(){
		return {
			models: {
				name: null,
				displayName: null,
				slug: null,
				polyClinicId: null,
				flowId: null,
				description: '',
				igStoryImageUrl: null,
				iconUrl: null,
				thumbnailUrl: null,
				regimenProducts: [
					{
						productId: null,
						stepTitle: null,
						usage: null,
						isImportant: true
					}
				],
				banners: [
					{
						imageUrl: null,
						route: null
					}
				],
				consultationTitle: null,
				consultationDescription: null,
				consultationImageUrl: null,
				consultationButtonName: null,
				whatToDoTitle: null,
				whatToDoDescription: null,
				whatToDoThumbnailUrl: null,
				whatToDoIcons: [
					{
						iconUrl: null,
						title: ''
					}
				],
				whatNotToDoTitle: null,
				whatNotToDoDescription: null,
				whatNotToDoThumbnailUrl: null,
				whatNotToDoIcons: [
					{
						iconUrl: null,
						title: ''
					}
				],
				isActive: true,
			},
			duplicate_slug: '',
			isSlugExists: false,
			duplicate_models: {},
			masters: {
				products: [],
				flows: [],
				polyclics: [],
				usages: [
					{
						value: 'Siang',
						checked: false
					},
					{
						value: 'Malam',
						checked: false
					},
				]
			},
			isLoading: false,
			debounce: null,
			editorOption: {
				modules: {
					toolbar: {
						container: toolbarOptions
					}
				}
			},
			getUrlOfFile,
		}
	},
	created() {
		let _ = this
		_.getListOptions()
		_.duplicate_models = JSON.parse(JSON.stringify(_.models))
		_.getById()
		_.$nextTick(() => {
			_.$watch('models.slug', () => {
				clearTimeout(_.debounce)
				_.debounce = setTimeout(async () => {
					if(_.models.slug !== _.duplicate_slug) {
						// Check if slug exist
						try {
							const {status} = await _.axios.post('otc/regimens/slug-exist', {
								slug: _.models.slug
							})
							if(status === 200) {
								this.isSlugExists = false
							}
						} catch (error) {
							const { status } = error.response
							if(status === 400) {
								this.isSlugExists = true
							}
						}
					}
					}, 200)
			})
		})
	},
	methods: {	
		getListOptions(){
			let _ = this
			const getPolyClinics = _.axios.get('misc/poly-clinic')
			const getFlows = _.axios.get('misc/flows')
			const getProducts = _.axios.get('misc/products')

			Promise.all([getPolyClinics, getFlows, getProducts])
				.then(res => {
					const [polyclics, flows, products] = res
					this.masters.polyclics = polyclics.data.data
					this.masters.flows = flows.data.data
					this.masters.products = products.data.data
				})
		},
		getById() {
			let _ = this
			if (_.$route.params.id) {
        _.axios.get(`otc/regimens/${_.$route.params.id}`)
					.then(res => {
						const {data} = res.data
						_.models.name = data.name
						_.models.displayName = data.displayName
						_.models.slug = data.slug
						_.duplicate_slug = data.slug
						_.models.polyClinicId = data.polyClinic
						_.models.flowId = data.flow
						_.models.description = data.description
						_.models.igStoryImageUrl = data.igStoryImageUrl
						_.models.iconUrl = data.iconUrl
						_.models.thumbnailUrl = data.thumbnailUrl
						_.models.regimenProducts = data.regimenProducts.length ? data.regimenProducts.map(product => {
							let usage = []
							if(product.usage === 'day and night') {
								usage.push(
									{
										value: 'Siang',
										checked: false
									},
									{
										value: 'Malam',
										checked: false
									},
								)
							} else if (product.usage === 'day') {
								usage.push({
									value: 'Siang',
									checked: false
								})
							} else {
								usage.push({
									value: 'Malam',
									checked: false
								})
							}
							return {
								productId: product.product,
								stepTitle: product.stepTitle,
								usage,
								isImportant: product.isImportant
							}
						}) : [{
							productId: null,
							stepTitle: null,
							usage: null,
							isImportant: true
						}]
						_.models.banners = data.banners.length ? data.banners : [
							{
								imageUrl: null,
								route: null
							}
						]
						_.models.consultationTitle = data.consultationTitle
						_.models.consultationDescription = data.consultationDescription
						_.models.consultationImageUrl = data.consultationImageUrl
						_.models.consultationButtonName = data.consultationButtonName
						_.models.whatToDoTitle = data.whatToDoTitle
						_.models.whatToDoDescription = data.whatToDoDescription
						_.models.whatToDoThumbnailUrl = data.whatToDoThumbnailUrl
						_.models.whatToDoIcons = data.whatToDoIcons.length ? [...data.whatToDoIcons] : JSON.parse(JSON.stringify(this.duplicate_models.whatToDoIcons))
						_.models.whatNotToDoTitle = data.whatNotToDoTitle
						_.models.whatNotToDoDescription = data.whatNotToDoDescription
						_.models.whatNotToDoThumbnailUrl = data.whatNotToDoThumbnailUrl
						_.models.whatNotToDoIcons = data.whatNotToDoIcons.length ? [...data.whatNotToDoIcons] : JSON.parse(JSON.stringify(this.duplicate_models.whatNotToDoIcons))
						_.models.isActive = data.isActive
					}).then(() => {
						this.$nextTick(() => {
							this.$watch('models.name',() => {
								this.models.slug = strSlug(this.models.name)
							})
						})
					})
			} else {
				this.$watch('models.name',() => {
					this.models.slug = strSlug(this.models.name)
				})
			}
		},
		async validateImage({valid}, event, type, key, index) {
				if(valid) {
						const file = await getImages(event)
						if(file) {
								if(typeof index === 'number') {
										this.$set(this.models[type][index], `${key}`, file)
								} else {
										this.models[type] = file
								}
						}
						event.target.value = null
				} else {
						event.target.value = null
				}
		},
		async validateIcons({valid}, event, type, key, index) {
				if(valid) {
						const file = await getImages(event)
						this.$set(this.models[type][index], key, file)
						event.target.value = null
				} else {
						event.target.value = null
				}
		},
		removeImage(type, key, index) {
				if (typeof index === 'number') {
						this.$set(this.models[type][index], key, null)
				} else if (!key) {
						this.models[type] = null
				} else {
						this.models[type][key] = null
				}
		},
		pushItem(type) {
			this.models[type].push(JSON.parse(JSON.stringify(this.duplicate_models[type][0])))
		},
		pushItemsImage(key, obj) {
			this.models[key].push(obj)
		},
		deleteItem(type, index) {
			this.models[type].splice(index, 1);
		},
		setInitialValue() {
			let _ = this
			_.duplicate_models = JSON.parse(JSON.stringify(this.models))
		},
		modifyOptionsObject(newModels) {
			if(newModels.regimenProducts.length) {
				newModels.regimenProducts = newModels.regimenProducts.map(product => {
					const usages = product.usage.map(usage => usage.value)
					if(usages.includes('Siang') && usages.includes('Malam')){
						product.usage = 'day and night'
					} else if(usages.includes('Siang')) {
						product.usage = 'day'
					} else if(usages.includes('Malam')) {
						product.usage = 'night'
					} else {
						product.usage = ''
					}
					return {
						...product,
						productId: product.productId.id
					}
				})
			}

			if(newModels.polyClinicId) {
					newModels.polyClinicId = newModels.polyClinicId.id
			}
			if(newModels.flowId) {
					newModels.flowId = newModels.flowId.id
			}

			const arrayFields = ['whatToDoIcons', 'whatNotToDoIcons']
			
			arrayFields.forEach(field => {
					if(newModels[field].length) {
							newModels[field] = newModels[field].filter(item => {
									if(!isFalsyObj(item)) {
											return item
									}
							})
							if(!newModels[field].length) {
									newModels[field] = this.duplicate_models[field]
							}
					}
			})

			return newModels
		},
		async submit() {
			let _ = this
			if(this.isSlugExists) {
				return _.$refs.slugField.scrollIntoView({ 
					behavior: "smooth",
					block: 'center'
				})
			}
			_.isLoading = true
			const isValid = await _.$refs.observer.validate()
			if ( isValid ) {
				const modelsToURL = await convertFiletoURL(_.models, 'regimens')
				if(modelsToURL) {
					const newModels = _.modifyOptionsObject(modelsToURL)
					let request = ''
					let message = 'created'
					
					try {
						if (!_.$route.params.id) {
							request = await _.axios.post('otc/regimens', newModels)
						} else {
							message = 'updated'
							request = await _.axios.put(`otc/regimens/${_.$route.params.id}`, newModels)
						}
						if(request.status === 200) {
							_.$notify({
								type: 'success',
								title: 'Success',
								text: `Regimen successfully ${message}`
							})
							_.isLoading = false
							_.$router.push('/regimens')
						}
					} catch (error) {
						if(error.response.data.message === 'Name already exists') {
							_.$notify({
								type: 'error',
								title: 'Failed',
								text: 'Regimen name is already exist'
							})
						} else if(error.response.data.message) {
							_.$notify({
								type: 'error',
								title: 'Failed',
								text: error.response.data.message
							})
						} else {
							_.$notify({
								type: 'error',
								title: 'Failed',
								text: `Failed ${message} regimen`
							})
						}
						_.isLoading = false
					}
				}
			} else {
				window.scrollTo(0,0)
				_.isLoading = false
			}
		},
	}
}
</script>