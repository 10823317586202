<template>
  <div class="card col-lg-12 p-0">
    <div class="card-body">
      <h3 class="font-600 mb-2">Clinic Landing Page</h3>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <app-collapse accordion type="margin">
            <app-collapse-item title="Banner Section" :isVisible="false">
              <div class="row">
                <div class="form-group col-2">
                  <label>Is Active?</label>
                  <select class="form-control" v-model="model.bannerIsActive">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </div>
              <div
                v-for="(banner, idx) in model.bannerImages"
                :key="idx"
                class="mb-1"
              >
                <div class="row">
                  <ValidationProvider
                    :name="`Image ${idx + 1}`"
                    v-slot="{ errors, validate }"
                    :rules="{ required: !banner.image, 'max-dimensions': 1080 }"
                    class="col"
                    :key="model.bannerImages.length + banner.image"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label for="image" class="required">Image</label>
                      <div class="form-control" style="height: auto">
                        <span
                          class="btn btn-outline-primary btn-sm"
                          @click="$refs.images[idx].click()"
                        >
                          Select Image
                        </span>
                        <img
                          v-if="banner.image"
                          :src="banner.image"
                          width="430"
                          height="135"
                          class="mt-1 d-block"
                          style="object-fit: cover; object-position: center"
                        />
                        <small class="block mt-1" style="margin-top: 4px"
                          >Ratio: 86:27</small
                        >
                        <small class="block" style="margin-top: 4px"
                          >Recommended dimensions: 1080 x 339 pixels</small
                        >
                        <small class="block" style="margin-top: 4px"
                          >Max width: 1080 pixels</small
                        >
                      </div>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        @change="
                          (e) => {
                            validate(e).then((res) =>
                              storeBannerImage(res, e, idx)
                            );
                          }
                        "
                        id="image"
                        class="hidden"
                        placeholder="Upload Image"
                        ref="images"
                      />
                      <small class="text-danger" v-if="errors[0]">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    :name="`URL Image ${idx + 1}`"
                    v-slot="{ errors }"
                    rules="url"
                    class="col"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label>Url Image</label>
                      <input
                        type="text"
                        v-model="banner.url"
                        class="form-control"
                        placeholder="Enter URL"
                      />
                      <small class="text-danger" v-if="errors[0]">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </ValidationProvider>
                  <div class="col">
                    <label>Actions</label>
                    <button
                      type="button"
                      class="btn btn-rounded btn-danger"
                      title="Delete"
                      @click="deleteBanner(idx)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-rounded btn-primary"
                      title="Move up"
                      @click="moveBanner('up', idx)"
                      :disabled="idx === 0"
                    >
                      <i class="fa fa-arrow-up"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-rounded btn-primary"
                      title="Move down"
                      @click="moveBanner('down', idx)"
                      :disabled="idx + 1 === model.bannerImages.length"
                    >
                      <i class="fa fa-arrow-down"></i>
                    </button>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-primary"
                @click="addBanner()"
              >
                <i class="fa fa-plus"></i> Add Content
              </button>
            </app-collapse-item>

            <app-collapse-item title="About Section" :isVisible="false">
              <div class="row">
                <div class="form-group col-2">
                  <label>Is Active?</label>
                  <select class="form-control" v-model="model.aboutIsActive">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <ValidationProvider
                  name="About Title"
                  v-slot="{ errors }"
                  :rules="{ required: model.aboutIsActive, max: 80 }"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label :class="{ required: model.aboutIsActive }"
                      >Title</label
                    >
                    <input
                      type="text"
                      v-model="model.aboutTitle"
                      class="form-control"
                      placeholder="Enter title"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="About Description"
                  v-slot="{ errors }"
                  :rules="{ required: model.aboutIsActive }"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label :class="{ required: model.aboutIsActive }"
                      >Description</label
                    >
                    <quill-editor
                      v-model="model.aboutDescription"
                      placeholder="Enter description"
                      :options="editorOption"
                    >
                    </quill-editor>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <ValidationProvider
                  name="About USP 1"
                  v-slot="{ errors }"
                  :rules="{ required: model.aboutIsActive, max: 36 }"
                  class="col"
                >
                  <div
                    class="form-group mb-1"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label>USPs</label>
                    <input
                      type="text"
                      v-model="model.aboutUsps[0]"
                      class="form-control"
                      placeholder="Enter USP 1"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <ValidationProvider
                  name="About USP 2"
                  v-slot="{ errors }"
                  :rules="{ required: model.aboutIsActive, max: 36 }"
                  class="col"
                >
                  <div
                    class="form-group mb-1"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <input
                      type="text"
                      v-model="model.aboutUsps[1]"
                      class="form-control"
                      placeholder="Enter USP 2"
                      :disabled="model.aboutUsps[0] === ''"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <ValidationProvider
                  name="About USP 3"
                  v-slot="{ errors }"
                  rules="max:36"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <input
                      type="text"
                      v-model="model.aboutUsps[2]"
                      class="form-control"
                      placeholder="Enter USP 3"
                      :disabled="
                        model.aboutUsps[0] === '' || model.aboutUsps[1] === ''
                      "
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <ValidationProvider
                  name="About CTA Button Text"
                  v-slot="{ errors }"
                  :rules="{ required: model.aboutCtaButtonUrl !== '' }"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label :class="{ required: model.aboutCtaButtonUrl !== '' }"
                      >CTA Button Text</label
                    >
                    <input
                      type="text"
                      v-model="model.aboutCtaButtonText"
                      class="form-control"
                      placeholder="Enter CTA Button Text"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="About CTA Button URL"
                  v-slot="{ errors }"
                  :rules="{
                    required: model.aboutCtaButtonText !== '',
                    url: true,
                  }"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label
                      :class="{ required: model.aboutCtaButtonText !== '' }"
                      >CTA Button URL</label
                    >
                    <input
                      type="text"
                      v-model="model.aboutCtaButtonUrl"
                      class="form-control"
                      placeholder="Enter CTA Button URL"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
            </app-collapse-item>

            <app-collapse-item title="Treatment Section" :isVisible="false">
              <div class="row">
                <div class="form-group col-2">
                  <label>Is Active?</label>
                  <select
                    class="form-control"
                    v-model="model.treatmentIsActive"
                  >
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label>Title</label>
                  <input
                    type="text"
                    v-model="model.treatmentTitle"
                    class="form-control"
                    placeholder="Enter title"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label>Treatments</label>
                  <multiselect
                    v-model="model.treatments"
                    label="name"
                    track-by="id"
                    placeholder="Select Treatments"
                    :multiple="true"
                    :options="treatmentOptions"
                    :clear-on-select="true"
                    :close-on-select="false"
                  >
                    <span slot="noResult">Oops! Data not found.</span>
                  </multiselect>
                </div>
              </div>
            </app-collapse-item>

            <app-collapse-item title="Address Section" :isVisible="false">
              <div class="row">
                <div class="form-group col-2">
                  <label>Is Active?</label>
                  <select class="form-control" v-model="model.addressIsActive">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label>Title</label>
                  <input
                    type="text"
                    v-model="model.addressTitle"
                    class="form-control"
                    placeholder="Enter title"
                  />
                </div>
                <div class="form-group col">
                  <label>Detail</label>
                  <input
                    type="text"
                    v-model="model.addressDetail"
                    class="form-control"
                    placeholder="Enter address detail"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label>Operational Hours</label>
                  <input
                    type="text"
                    v-model="model.addressOperationalHours"
                    class="form-control"
                    placeholder="Enter operational hours"
                  />
                </div>
                <div class="form-group col">
                  <label>Address Latitude</label>
                  <input
                    type="number"
                    v-model.number="model.addressLat"
                    step="any"
                    class="form-control"
                    placeholder="Enter latitude"
                  />
                </div>
                <div class="form-group col">
                  <label>Address Longitude</label>
                  <input
                    type="number"
                    v-model.number="model.addressLong"
                    step="any"
                    class="form-control"
                    placeholder="Enter longitude"
                  />
                </div>
              </div>
            </app-collapse-item>

            <app-collapse-item title="Testimony Section" :isVisible="false">
              <div class="row">
                <div class="form-group col-2">
                  <label>Is Active?</label>
                  <select
                    class="form-control"
                    v-model="model.testimonyIsActive"
                  >
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label>Title</label>
                  <input
                    type="text"
                    v-model="model.testimonyTitle"
                    class="form-control"
                    placeholder="Enter title"
                  />
                </div>
                <div class="form-group col">
                  <label>Description</label>
                  <input
                    type="text"
                    v-model="model.testimonyDescription"
                    class="form-control"
                    placeholder="Enter description"
                  />
                </div>
              </div>
              <div class="row">
                <ValidationProvider
                  name="Testimony See All URL"
                  v-slot="{ errors }"
                  rules="url"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label>See All URL</label>
                    <input
                      type="text"
                      v-model="model.testimonySeeAllUrl"
                      class="form-control"
                      placeholder="Enter See All URL"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label>Conditions</label>
                  <multiselect
                    v-model="model.testimonyConditions"
                    label="name"
                    track-by="id"
                    placeholder="Select Conditions"
                    :multiple="true"
                    :options="conditionOptions"
                    :clear-on-select="true"
                    :close-on-select="false"
                  >
                    <span slot="noResult">Oops! Data not found.</span>
                  </multiselect>
                </div>
              </div>
            </app-collapse-item>

            <app-collapse-item title="Clinical Team Section" :isVisible="false">
              <div class="row">
                <div class="form-group col-2">
                  <label>Is Active?</label>
                  <select
                    class="form-control"
                    v-model="model.clinicalTeamIsActive"
                  >
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <ValidationProvider
                  name="Clinical Team Image"
                  v-slot="{ errors, validate }"
                  rules="max-dimensions:900"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label for="clinicalTeamImage">Image</label>
                    <div class="form-control" style="height: auto">
                      <span
                        class="btn btn-outline-primary btn-sm"
                        @click="$refs.clinicalTeamImage.click()"
                      >
                        Select Image
                      </span>
                      <img
                        v-if="model.clinicalTeamImage"
                        :src="model.clinicalTeamImage"
                        width="300"
                        height="200"
                        class="mt-1 d-block"
                        style="object-fit: cover; object-position: center"
                      />
                      <small class="block mt-1" style="margin-top: 4px"
                        >Ratio: 3:2</small
                      >
                      <small class="block" style="margin-top: 4px"
                        >Recommended dimensions: 900 x 600 pixels</small
                      >
                      <small class="block" style="margin-top: 4px"
                        >Max width: 900 pixels</small
                      >
                    </div>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      @change="
                        (e) => {
                          validate(e).then((res) =>
                            storeClinicalTeamImage(res, e)
                          );
                        }
                      "
                      id="clinicalTeamImage"
                      class="hidden"
                      placeholder="Upload Image"
                      ref="clinicalTeamImage"
                    />
                    <small class="text-danger" v-if="errors[0]">
                      {{ errors[0] }}
                    </small>
                  </div>
                </ValidationProvider>
                <div class="form-group col">
                  <label>Title</label>
                  <input
                    type="text"
                    v-model="model.clinicalTeamTitle"
                    class="form-control"
                    placeholder="Enter title"
                  />
                </div>
              </div>
              <div class="row">
                <ValidationProvider
                  name="Clinical Team CTA Button Text"
                  v-slot="{ errors }"
                  :rules="{ required: model.clinicalTeamCtaButtonUrl !== '' }"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label
                      :class="{
                        required: model.clinicalTeamCtaButtonUrl !== '',
                      }"
                      >CTA Button Text</label
                    >
                    <input
                      type="text"
                      v-model="model.clinicalTeamCtaButtonText"
                      class="form-control"
                      placeholder="Enter CTA Button Text"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="Clinical Team CTA Button URL"
                  v-slot="{ errors }"
                  :rules="{
                    required: model.clinicalTeamCtaButtonText !== '',
                    url: true,
                  }"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label
                      :class="{
                        required: model.clinicalTeamCtaButtonText !== '',
                      }"
                      >CTA Button URL</label
                    >
                    <input
                      type="text"
                      v-model="model.clinicalTeamCtaButtonUrl"
                      class="form-control"
                      placeholder="Enter CTA Button URL"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
            </app-collapse-item>

            <app-collapse-item title="FAQ Section" :isVisible="false">
              <div class="row">
                <div class="form-group col-2">
                  <label>Is Active?</label>
                  <select class="form-control" v-model="model.faqIsActive">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label>Title</label>
                  <input
                    type="text"
                    v-model="model.faqTitle"
                    class="form-control"
                    placeholder="Enter title"
                  />
                </div>
                <ValidationProvider
                  name="FAQ See All URL"
                  v-slot="{ errors }"
                  rules="url"
                  class="col"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label>See All URL</label>
                    <input
                      type="text"
                      v-model="model.faqSeeAllUrl"
                      class="form-control"
                      placeholder="Enter See All URL"
                    />
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <div class="form-group col">
                  <label>FAQs</label>
                  <multiselect
                    v-model="model.faqItems"
                    label="question"
                    track-by="id"
                    placeholder="Select FAQs"
                    :multiple="true"
                    :options="faqOptions"
                    :clear-on-select="true"
                    :close-on-select="false"
                  >
                    <span slot="noResult">Oops! Data not found.</span>
                  </multiselect>
                </div>
              </div>
            </app-collapse-item>

            <app-collapse-item title="SEO Section" :isVisible="false">
              <div class="row">
                <ValidationProvider
                  name="Meta Title"
                  v-slot="{ errors }"
                  class="col"
                  rules="required|min:30|max:200"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Meta Title</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      v-model="model.metaTitle"
                      placeholder="Min 30 chars, max 200 chars"
                    ></textarea>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="Meta Description"
                  v-slot="{ errors }"
                  class="col"
                  rules="required|min:100|max:200"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Meta Description</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      v-model="model.metaDescription"
                      placeholder="Min 100 chars, max 200 chars"
                    ></textarea>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <ValidationProvider
                  name="Meta Image"
                  v-slot="{ errors, validate }"
                  :rules="{ required: !model.metaImage, 'max-dimensions': 900 }"
                  class="col-6"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label for="metaImage" class="required">Meta Image</label>
                    <div class="form-control" style="height: auto">
                      <span
                        class="btn btn-outline-primary btn-sm"
                        @click="$refs.metaImage.click()"
                      >
                        Select Image
                      </span>
                      <img
                        v-if="model.metaImage"
                        :src="model.metaImage"
                        width="300"
                        height="200"
                        class="mt-1 d-block"
                        style="object-fit: cover; object-position: center"
                      />
                      <small class="block mt-1" style="margin-top: 4px"
                        >Ratio: 3:2</small
                      >
                      <small class="block" style="margin-top: 4px"
                        >Recommended dimensions: 900 x 600 pixels</small
                      >
                      <small class="block" style="margin-top: 4px"
                        >Max width: 900 pixels</small
                      >
                    </div>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      @change="
                        (e) => {
                          validate(e).then((res) => storeMetaImage(res, e));
                        }
                      "
                      id="metaImage"
                      class="hidden"
                      placeholder="Upload Image"
                      ref="metaImage"
                    />
                    <small class="text-danger" v-if="errors[0]">
                      {{ errors[0] }}
                    </small>
                  </div>
                </ValidationProvider>
              </div>
            </app-collapse-item>
          </app-collapse>
          <div class="text-left mt-4">
            <button
              type="submit"
              class="btn btn-primary float-left"
              :disabled="isLoading"
            >
              <div v-if="isLoading">
                Updating<i class="fa fa-spinner fa-spin"></i>
              </div>
              <div v-else>Update</div>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ script: 'sub' }, { script: 'super' }],
  [{ color: ['#111820', '#0072cd', '#ff7f32', false] }],
  ['link'],
];

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      model: {
        // Banner Section
        bannerIsActive: true,
        bannerImages: [
          {
            image: '',
            url: '',
          },
        ],
        // About Section
        aboutIsActive: true,
        aboutTitle: '',
        aboutDescription: '',
        aboutUsps: ['', '', ''],
        aboutCtaButtonText: '',
        aboutCtaButtonUrl: '',
        // Treatment Section
        treatmentIsActive: true,
        treatmentTitle: '',
        treatments: [],
        // Address Section
        addressIsActive: true,
        addressTitle: '',
        addressDetail: '',
        addressOperationalHours: '',
        addressLat: null,
        addressLong: null,
        // Testimony Section
        testimonyIsActive: true,
        testimonyTitle: '',
        testimonyDescription: '',
        testimonySeeAllUrl: '',
        testimonyConditions: [],
        // Clinical Team Section
        clinicalTeamIsActive: true,
        clinicalTeamImage: '',
        clinicalTeamTitle: '',
        clinicalTeamCtaButtonText: '',
        clinicalTeamCtaButtonUrl: '',
        // FAQ Section
        faqIsActive: true,
        faqTitle: '',
        faqItems: [],
        faqSeeAllUrl: '',
        // SEO Section
        metaTitle: '',
        metaDescription: '',
        metaImage: '',
      },
      bannerImageFiles: [null],
      clinicalTeamImageFile: null,
      metaImageFile: null,
      isLoading: false,
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
          },
        },
      },
      treatmentOptions: [],
      conditionOptions: [],
      faqOptions: [],
    };
  },
  methods: {
    getData() {
      this.axios
        .get('/v2/cms/clinic-landing-page')
        .then((resp) => {
          const { data } = resp.data;
          // Banner Section
          this.model.bannerIsActive = data.bannerIsActive;
          this.model.bannerImages = data.bannerImages;
          this.bannerImageFiles = Array(data.bannerImages.length).fill(null);
          // About Section
          this.model.aboutIsActive = data.aboutIsActive;
          this.model.aboutIsActive = data.aboutIsActive;
          this.model.aboutTitle = data.aboutTitle;
          this.model.aboutDescription = data.aboutDescription;
          this.model.aboutUsps = [
            data.aboutUsps?.[0] ?? '',
            data.aboutUsps?.[1] ?? '',
            data.aboutUsps?.[2] ?? '',
          ];
          this.model.aboutCtaButtonText = data.aboutCtaButtonText;
          this.model.aboutCtaButtonUrl = data.aboutCtaButtonUrl;
          // Treatment Section
          this.model.treatmentIsActive = data.treatmentIsActive;
          this.model.treatmentTitle = data.treatmentTitle;
          this.model.treatments = data.treatments;
          // Address Section
          this.model.addressIsActive = data.addressIsActive;
          this.model.addressTitle = data.addressTitle;
          this.model.addressDetail = data.addressDetail;
          this.model.addressOperationalHours = data.addressOperationalHours;
          this.model.addressLat = data.addressLat;
          this.model.addressLong = data.addressLong;
          // Testimony Section
          this.model.testimonyIsActive = data.testimonyIsActive;
          this.model.testimonyTitle = data.testimonyTitle;
          this.model.testimonyDescription = data.testimonyDescription;
          this.model.testimonySeeAllUrl = data.testimonySeeAllUrl;
          this.model.testimonyConditions = data.testimonyConditions;
          // Clinical Team Section
          this.model.clinicalTeamIsActive = data.clinicalTeamIsActive;
          this.model.clinicalTeamImage = data.clinicalTeamImage;
          this.model.clinicalTeamTitle = data.clinicalTeamTitle;
          this.model.clinicalTeamCtaButtonText = data.clinicalTeamCtaButtonText;
          this.model.clinicalTeamCtaButtonUrl = data.clinicalTeamCtaButtonUrl;
          // FAQ Section
          this.model.faqIsActive = data.faqIsActive;
          this.model.faqTitle = data.faqTitle;
          this.model.faqItems = data.faqItems;
          this.model.faqSeeAllUrl = data.faqSeeAllUrl;
          // SEO Section
          this.model.metaTitle = data.metaTitle;
          this.model.metaDescription = data.metaDescription;
          this.model.metaImage = data.metaImage;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        });
    },
    getTreatmentOptions() {
      this.axios
        .get('/v2/misc/clinic-treatment-library', {
          params: {
            page: 1,
            isActive: true,
          },
        })
        .then((resp) => {
          this.treatmentOptions = resp.data.data.rows;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        });
    },
    getConditionOptions() {
      this.axios
        .get('/v2/misc/conditions', {
          params: {
            page: 1,
          },
        })
        .then((resp) => {
          this.conditionOptions = resp.data.data.rows;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        });
    },
    getFaqOptions() {
      this.axios
        .get('/v2/misc/faqs', {
          params: {
            page: 1,
          },
        })
        .then((resp) => {
          this.faqOptions = resp.data.data.rows;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        });
    },
    addBanner() {
      this.model.bannerImages.push({
        image: '',
        url: '',
      });
      this.bannerImageFiles.push(null);
    },
    deleteBanner(idx) {
      this.model.bannerImages.splice(idx, 1);
      this.bannerImageFiles.splice(idx, 1);
    },
    moveBanner(direction, index) {
      const isUp = direction === 'up';
      const destinationIndex = isUp ? index - 1 : index + 1;
      // do swap
      [
        this.model.bannerImages[index],
        this.model.bannerImages[destinationIndex],
      ] = [
        this.model.bannerImages[destinationIndex],
        this.model.bannerImages[index],
      ];
      [this.bannerImageFiles[index], this.bannerImageFiles[destinationIndex]] =
        [this.bannerImageFiles[destinationIndex], this.bannerImageFiles[index]];
      this.$forceUpdate();
    },
    storeBannerImage({ valid }, event, idx) {
      if (valid) {
        this.$set(this.bannerImageFiles, idx, event.target.files[0]);
        this.$set(
          this.model.bannerImages[idx],
          'image',
          window.URL.createObjectURL(event.target.files[0])
        );
      }
    },
    storeClinicalTeamImage({ valid }, event) {
      if (valid) {
        this.clinicalTeamImageFile = event.target.files[0];
        this.$set(
          this.model,
          'clinicalTeamImage',
          window.URL.createObjectURL(event.target.files[0])
        );
      }
    },
    storeMetaImage({ valid }, event) {
      if (valid) {
        this.metaImageFile = event.target.files[0];
        this.$set(
          this.model,
          'metaImage',
          window.URL.createObjectURL(event.target.files[0])
        );
      }
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          this.isLoading = true;

          // upload images first
          const headers = {
            'Content-Type': 'multipart/form-data',
          };
          const folder = 'clinic';

          const bannerImageUploadRequests = this.bannerImageFiles.map(
            (file) => {
              if (file) {
                const formData = new FormData();
                formData.append('folder', folder);
                formData.append('file', file);
                return this.axios.post('/misc/upload', formData, { headers });
              } else {
                return Promise.resolve(null);
              }
            }
          );

          const clinicalTeamImageFormData = new FormData();
          clinicalTeamImageFormData.append('folder', folder);
          clinicalTeamImageFormData.append('file', this.clinicalTeamImageFile);
          const clinicalTeamImageUploadRequest = this.clinicalTeamImageFile
            ? this.axios.post('/misc/upload', clinicalTeamImageFormData, {
                headers,
              })
            : Promise.resolve(null);

          const metaImageFormData = new FormData();
          metaImageFormData.append('folder', folder);
          metaImageFormData.append('file', this.metaImageFile);
          const metaImageUploadRequest = this.metaImageFile
            ? this.axios.post('/misc/upload', metaImageFormData, {
                headers,
              })
            : Promise.resolve(null);

          const [
            clinicalTeamImageUploadResponse,
            metaImageUploadResponse,
            ...bannerImageUploadResponses
          ] = await Promise.all([
            clinicalTeamImageUploadRequest,
            metaImageUploadRequest,
            ...bannerImageUploadRequests,
          ]);

          const aboutUsps = [];
          if (this.model.aboutUsps[0] !== '') {
            aboutUsps.push(this.model.aboutUsps[0]);
            if (this.model.aboutUsps[1] !== '') {
              aboutUsps.push(this.model.aboutUsps[1]);
              if (this.model.aboutUsps[2] !== '') {
                aboutUsps.push(this.model.aboutUsps[2]);
              }
            }
          }

          const payload = {
            // Banner Section
            bannerIsActive: this.model.bannerIsActive,
            bannerImages: this.model.bannerImages.map((banner, idx) => ({
              image:
                bannerImageUploadResponses[idx] === null
                  ? banner.image
                  : bannerImageUploadResponses[idx].data.data.file,
              url: banner.url,
            })),
            // About Section
            aboutIsActive: this.model.aboutIsActive,
            aboutTitle: this.model.aboutTitle,
            aboutDescription: this.model.aboutDescription,
            aboutUsps,
            aboutCtaButtonText: this.model.aboutCtaButtonText,
            aboutCtaButtonUrl: this.model.aboutCtaButtonUrl,
            // Treatment Section
            treatmentIsActive: this.model.treatmentIsActive,
            treatmentTitle: this.model.treatmentTitle,
            treatmentIds: this.model.treatments.map(
              (treatment) => treatment.id
            ),
            // Address Section
            addressIsActive: this.model.addressIsActive,
            addressTitle: this.model.addressTitle,
            addressDetail: this.model.addressDetail,
            addressOperationalHours: this.model.addressOperationalHours,
            addressLat: this.model.addressLat || null,
            addressLong: this.model.addressLong || null,
            // Testimony Section
            testimonyIsActive: this.model.testimonyIsActive,
            testimonyTitle: this.model.testimonyTitle,
            testimonyDescription: this.model.testimonyDescription,
            testimonySeeAllUrl: this.model.testimonySeeAllUrl,
            testimonyConditionIds: this.model.testimonyConditions.map(
              (condition) => condition.id
            ),
            // Clinical Team Section
            clinicalTeamIsActive: this.model.clinicalTeamIsActive,
            clinicalTeamImage:
              clinicalTeamImageUploadResponse === null
                ? this.model.clinicalTeamImage
                : clinicalTeamImageUploadResponse.data.data.file,
            clinicalTeamTitle: this.model.clinicalTeamTitle,
            clinicalTeamCtaButtonText: this.model.clinicalTeamCtaButtonText,
            clinicalTeamCtaButtonUrl: this.model.clinicalTeamCtaButtonUrl,
            // FAQ Section
            faqIsActive: this.model.faqIsActive,
            faqTitle: this.model.faqTitle,
            faqItemIds: this.model.faqItems.map((faq) => faq.id),
            faqSeeAllUrl: this.model.faqSeeAllUrl,
            // SEO Section
            metaTitle: this.model.metaTitle,
            metaDescription: this.model.metaDescription,
            metaImage:
              metaImageUploadResponse === null
                ? this.model.metaImage
                : metaImageUploadResponse.data.data.file,
          };

          this.axios
            .put('/v2/cms/clinic-landing-page', payload)
            .then((resp) => {
              this.$notify({
                type: 'success',
                title: 'Success!',
                text: resp.data.message,
              });
              this.getData();
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: err.response.data.message,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } catch (err) {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Failed to upload images',
          });
        }
      }
    },
  },
  async mounted() {
    this.getTreatmentOptions();
    this.getConditionOptions();
    this.getFaqOptions();
    this.getData();
  },
};
</script>
