<template>
  <div class="card col-lg-12 p-0">
    <div class="card-body">
      <h3 class="font-600 mb-2">Program Page</h3>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <div class="row">
            <ValidationProvider
              name="Title"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Title</label>
                <input
                  type="text"
                  v-model="model.title"
                  class="form-control"
                  placeholder="Enter Title"
                />
                <small class="text-danger" v-if="errors[0]">
                  {{ errors[0] }}
                </small>
              </div>
            </ValidationProvider>
          </div>
          <div class="row">
            <ValidationProvider
              name="Description"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Description</label>
                <quill-editor
                  v-model="model.description"
                  placeholder="Enter description"
                  :options="editorOption"
                >
                </quill-editor>
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
          </div>

          <app-collapse accordion type="margin">
            <app-collapse-item title="Program Section" :isVisible="false">
              <div
                v-for="(program, idx) in model.programItems"
                :key="idx"
                class="mb-1"
              >
                <div class="row">
                  <ValidationProvider
                    :name="`Program Name ${idx + 1}`"
                    v-slot="{ errors }"
                    rules="required"
                    class="col-5"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label class="required">Program Name</label>
                      <input
                        type="text"
                        v-model="program.name"
                        class="form-control"
                        placeholder="Enter Name"
                      />
                      <small class="text-danger" v-if="errors[0]">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    :name="`Program URL ${idx + 1}`"
                    v-slot="{ errors }"
                    rules="required|url"
                    class="col-5"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label class="required">URL</label>
                      <input
                        type="text"
                        v-model="program.url"
                        class="form-control"
                        placeholder="Enter URL"
                      />
                      <small class="text-danger" v-if="errors[0]">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </ValidationProvider>
                  <div class="col-2">
                    <label>Actions</label>
                    <button
                      type="button"
                      class="btn btn-rounded btn-danger"
                      title="Delete"
                      @click="deleteProgram(idx)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-rounded btn-primary"
                      title="Move up"
                      @click="moveProgram('up', idx)"
                      :disabled="idx === 0"
                    >
                      <i class="fa fa-arrow-up"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-rounded btn-primary"
                      title="Move down"
                      @click="moveProgram('down', idx)"
                      :disabled="idx + 1 === model.programItems.length"
                    >
                      <i class="fa fa-arrow-down"></i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <ValidationProvider
                    :name="`Program Icon ${idx + 1}`"
                    v-slot="{ errors, validate }"
                    :rules="{ required: !program.icon, 'max-dimensions': 480 }"
                    class="col-5"
                    :key="model.programItems.length + program.icon"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label for="icon" class="required">Icon</label>
                      <div class="form-control" style="height: auto">
                        <span
                          class="btn btn-outline-primary btn-sm"
                          @click="$refs.icons[idx].click()"
                        >
                          Select Image
                        </span>
                        <img
                          v-if="program.icon"
                          :src="program.icon"
                          width="300"
                          height="300"
                          class="mt-1 d-block"
                          style="object-fit: cover; object-position: center"
                        />
                        <small class="block mt-1" style="margin-top: 4px"
                          >Ratio: 1:1</small
                        >
                        <small class="block" style="margin-top: 4px"
                          >Recommended dimensions: 480 x 480 pixels</small
                        >
                        <small class="block" style="margin-top: 4px"
                          >Max width: 480 pixels</small
                        >
                      </div>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        @change="
                          (e) => {
                            validate(e).then((res) =>
                              storeProgramIcon(res, e, idx)
                            );
                          }
                        "
                        id="icon"
                        class="hidden"
                        placeholder="Upload Image"
                        ref="icons"
                      />
                      <small class="text-danger" v-if="errors[0]">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Program Description"
                    v-slot="{ errors }"
                    rules="required"
                    class="col-5"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label class="required">Program Description</label>
                      <quill-editor
                        v-model="program.description"
                        placeholder="Enter description"
                        :options="editorOption"
                      >
                      </quill-editor>
                      <small class="text-muted" v-if="errors[0]">{{
                        errors[0]
                      }}</small>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-primary"
                @click="addProgram()"
              >
                <i class="fa fa-plus"></i> Add Content
              </button>
            </app-collapse-item>
          </app-collapse>

          <app-collapse accordion type="margin">
            <app-collapse-item title="SEO Section" :isVisible="false">
              <div class="row">
                <ValidationProvider
                  name="Meta Title"
                  v-slot="{ errors }"
                  class="col"
                  rules="required|min:30|max:200"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Meta Title</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      v-model="model.metaTitle"
                      placeholder="Min 30 chars, max 200 chars"
                    ></textarea>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="Meta Description"
                  v-slot="{ errors }"
                  class="col"
                  rules="required|min:100|max:200"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Meta Description</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      v-model="model.metaDescription"
                      placeholder="Min 100 chars, max 200 chars"
                    ></textarea>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
            </app-collapse-item>
          </app-collapse>
          <div class="text-left mt-4">
            <button
              type="submit"
              class="btn btn-primary float-left"
              :disabled="isLoading"
            >
              <div v-if="isLoading">
                Updating<i class="fa fa-spinner fa-spin"></i>
              </div>
              <div v-else>Update</div>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ script: 'sub' }, { script: 'super' }],
  [{ color: ['#111820', '#0072cd', '#ff7f32', false] }],
  ['link'],
];

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      model: {
        title: '',
        description: '',
        // Program section
        programItems: [],
        // SEO Section
        metaTitle: '',
        metaDescription: '',
      },
      programIconFiles: [],
      isLoading: false,
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
          },
        },
      },
    };
  },
  methods: {
    getData() {
      this.axios
        .get('/v2/cms/program-page')
        .then((resp) => {
          const { data } = resp.data;
          this.model.title = data.title;
          this.model.description = data.description;
          // Program Section
          this.model.programItems = data.programItems;
          this.programIconFiles = Array(data.programItems.length).fill(null);
          // SEO Section
          this.model.metaTitle = data.metaTitle;
          this.model.metaDescription = data.metaDescription;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        });
    },
    addProgram() {
      this.model.programItems.push({
        name: '',
        url: '',
        icon: '',
        description: '',
      });
      this.programIconFiles.push(null);
    },
    deleteProgram(idx) {
      this.model.programItems.splice(idx, 1);
      this.programIconFiles.splice(idx, 1);
    },
    moveProgram(direction, index) {
      const isUp = direction === 'up';
      const destinationIndex = isUp ? index - 1 : index + 1;
      // do swap
      [
        this.model.programItems[index],
        this.model.programItems[destinationIndex],
      ] = [
        this.model.programItems[destinationIndex],
        this.model.programItems[index],
      ];
      [this.programIconFiles[index], this.programIconFiles[destinationIndex]] =
        [this.programIconFiles[destinationIndex], this.programIconFiles[index]];
      this.$forceUpdate();
    },
    storeProgramIcon({ valid }, event, idx) {
      if (valid) {
        this.$set(this.programIconFiles, idx, event.target.files[0]);
        this.$set(
          this.model.programItems[idx],
          'icon',
          window.URL.createObjectURL(event.target.files[0])
        );
      }
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          this.isLoading = true;

          // upload images first
          const headers = {
            'Content-Type': 'multipart/form-data',
          };

          const programIconUploadRequests = this.programIconFiles.map(
            (file) => {
              if (file) {
                const formData = new FormData();
                formData.append('folder', 'program-page');
                formData.append('file', file);
                return this.axios.post('/misc/upload', formData, { headers });
              } else {
                return Promise.resolve(null);
              }
            }
          );

          const programIconUploadResponses = await Promise.all(
            programIconUploadRequests
          );

          const payload = {
            title: this.model.title,
            description: this.model.description,
            programItems: this.model.programItems.map((program, idx) => ({
              name: program.name,
              url: program.url,
              icon:
                programIconUploadResponses[idx] === null
                  ? program.icon
                  : programIconUploadResponses[idx].data.data.file,
              description: program.description,
            })),
            // SEO Section
            metaTitle: this.model.metaTitle,
            metaDescription: this.model.metaDescription,
          };

          this.axios
            .put('/v2/cms/program-page', payload)
            .then((resp) => {
              this.$notify({
                type: 'success',
                title: 'Success!',
                text: resp.data.message,
              });
              this.getData();
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: err.response.data.message,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } catch (err) {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Failed to upload images',
          });
        }
      }
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>
