<template>
  <div class="card col-lg-12 p-0">
    <div class="card-body">
      <h3 class="font-600 mb-2">Clinic Treatment List Page</h3>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <div class="row">
            <ValidationProvider
              name="Title"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Title</label>
                <input
                  type="text"
                  v-model="model.title"
                  class="form-control"
                  placeholder="Enter title"
                />
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="row">
            <div class="col form-group">
              <label>Description</label>
              <input
                type="text"
                v-model="model.description"
                class="form-control"
                placeholder="Enter description"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <label>Treatments</label>
              <multiselect
                v-model="model.treatments"
                label="name"
                track-by="id"
                placeholder="Select Treatments"
                :multiple="true"
                :options="treatmentOptions"
                :clear-on-select="true"
                :close-on-select="false"
              >
                <span slot="noResult">Oops! Data not found.</span>
              </multiselect>
            </div>
          </div>
          <app-collapse accordion type="margin">
            <app-collapse-item title="SEO Section" :isVisible="false">
              <div class="row">
                <ValidationProvider
                  name="Meta Title"
                  v-slot="{ errors }"
                  class="col"
                  rules="required|min:30|max:200"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Meta Title</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      v-model="model.metaTitle"
                      placeholder="Min 30 chars, max 200 chars"
                    ></textarea>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="Meta Description"
                  v-slot="{ errors }"
                  class="col"
                  rules="required|min:100|max:200"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Meta Description</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      v-model="model.metaDescription"
                      placeholder="Min 100 chars, max 200 chars"
                    ></textarea>
                    <small class="text-muted" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <ValidationProvider
                  name="Meta Image"
                  v-slot="{ errors, validate }"
                  :rules="{ required: !model.metaImage, 'max-dimensions': 900 }"
                  class="col-6"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label for="metaImage" class="required">Meta Image</label>
                    <div class="form-control" style="height: auto">
                      <span
                        class="btn btn-outline-primary btn-sm"
                        @click="$refs.metaImage.click()"
                      >
                        Select Image
                      </span>
                      <img
                        v-if="model.metaImage"
                        :src="model.metaImage"
                        width="300"
                        height="200"
                        class="mt-1 d-block"
                        style="object-fit: cover; object-position: center"
                      />
                      <small class="block mt-1" style="margin-top: 4px"
                        >Ratio: 3:2</small
                      >
                      <small class="block" style="margin-top: 4px"
                        >Recommended dimensions: 900 x 600 pixels</small
                      >
                      <small class="block" style="margin-top: 4px"
                        >Max width: 900 pixels</small
                      >
                    </div>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      @change="
                        (e) => {
                          validate(e).then((res) => storeMetaImage(res, e));
                        }
                      "
                      id="metaImage"
                      class="hidden"
                      placeholder="Upload Image"
                      ref="metaImage"
                    />
                    <small class="text-danger" v-if="errors[0]">
                      {{ errors[0] }}
                    </small>
                  </div>
                </ValidationProvider>
              </div>
            </app-collapse-item>
          </app-collapse>
          <div class="text-left mt-4">
            <button
              type="submit"
              class="btn btn-primary float-left"
              :disabled="isLoading"
            >
              <div v-if="isLoading">
                Updating<i class="fa fa-spinner fa-spin"></i>
              </div>
              <div v-else>Update</div>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      model: {
        title: '',
        description: '',
        treatments: [],
        // SEO Section
        metaTitle: '',
        metaDescription: '',
        metaImage: '',
      },
      metaImageFile: null,
      isLoading: false,
      treatmentOptions: [],
    };
  },
  methods: {
    getData() {
      this.axios
        .get('/v2/cms/clinic-treatment-list-page')
        .then((resp) => {
          const { data } = resp.data;
          this.model.title = data.title;
          this.model.description = data.description;
          this.model.treatments = data.treatments;
          // SEO Section
          this.model.metaTitle = data.metaTitle;
          this.model.metaDescription = data.metaDescription;
          this.model.metaImage = data.metaImage;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        });
    },
    getTreatmentOptions() {
      this.axios
        .get('/v2/misc/clinic-treatment-library', {
          params: {
            page: 1,
            isActive: true,
          },
        })
        .then((resp) => {
          this.treatmentOptions = resp.data.data.rows;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        });
    },
    storeMetaImage({ valid }, event) {
      if (valid) {
        this.metaImageFile = event.target.files[0];
        this.$set(
          this.model,
          'metaImage',
          window.URL.createObjectURL(event.target.files[0])
        );
      }
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          this.isLoading = true;

          // upload images first
          const headers = {
            'Content-Type': 'multipart/form-data',
          };
          const folder = 'clinic';

          const metaImageFormData = new FormData();
          metaImageFormData.append('folder', folder);
          metaImageFormData.append('file', this.metaImageFile);
          const metaImageUploadRequest = this.metaImageFile
            ? this.axios.post('/misc/upload', metaImageFormData, {
                headers,
              })
            : Promise.resolve(null);

          const [metaImageUploadResponse] = await Promise.all([
            metaImageUploadRequest,
          ]);

          const payload = {
            title: this.model.title,
            description: this.model.description,
            treatmentIds: this.model.treatments.map(
              (treatment) => treatment.id
            ),
            // SEO Section
            metaTitle: this.model.metaTitle,
            metaDescription: this.model.metaDescription,
            metaImage:
              metaImageUploadResponse === null
                ? this.model.metaImage
                : metaImageUploadResponse.data.data.file,
          };

          this.axios
            .put('/v2/cms/clinic-treatment-list-page', payload)
            .then((resp) => {
              this.$notify({
                type: 'success',
                title: 'Success!',
                text: resp.data.message,
              });
              this.getData();
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: err.response.data.message,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } catch (err) {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Failed to upload images',
          });
        }
      }
    },
  },
  async mounted() {
    this.getTreatmentOptions();
    this.getData();
  },
};
</script>
