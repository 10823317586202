<template>
  <form>
    <ValidationProvider name="title section" ref="title section" v-slot="{ errors }" rules="required">
      <div class="form-group">
        <label for="titleSection">Title Section</label>
        <input type="text" v-model="model.title" name="Title" id="titleSection" class="form-control" placeholder="Enter title">
        <small class="text-danger" v-show="errors[0]">{{ errors[0] }}</small>
      </div>
    </ValidationProvider>

    <ValidationProvider name="description section" ref="description section" v-slot="{ errors }" rules="required">
      <div class="form-group">
        <label for="descriptionSection">Description Section</label>
        <textarea class="form-control" v-model="model.description" id="descriptionSection" placeholder="Enter description"></textarea>
        <small class="text-danger" v-show="errors[0]">{{ errors[0] }}</small>
      </div>
    </ValidationProvider>

    <div class="form-group">
      <label>Tips</label>
    </div>

    <div class="row" v-for="(_, index) in model.tips" :key="index">
      <ValidationProvider name="tip" :ref="`tip${index}`" v-slot="{ errors }" rules="required" class="form-group col-10">
        <input type="text" v-model="model.tips[index]" placeholder="Enter tip" class="form-control">
        <small class="text-danger" v-show="errors[0]">{{ errors[0] }}</small>
      </ValidationProvider>

      <div class="form-group col-2">
        <button class="btn btn-danger" @click="removeTip(index)" type="button">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
    <button class="btn btn-info mb-2" @click="addTip" type="button" v-if="model.tips.length < 3">
      <i class="fa fa-plus"></i>
      Add Tip
    </button>

    <div class="form-group">
      <label>Contoh foto yang benar</label>
    </div>

    <div class="row">
      <div class="col-12 d-flex" style="gap: 1rem; flex-wrap: wrap;">
        <div v-for="(guide, index) in model.guides" :key="index">
          <div class="position-relative">
            <img :src="guide" width="140" height="140" style="object-fit: cover; border-radius: 10px;" alt="">
            <div
              class="position-absolute bg-danger rounded-circle text-center"
              style="top: -10px; right: -10px; width: 21px; height: 21px; cursor: pointer;"
              @click="removeGuide(index)"
            >
              <i class="fa fa-close text-white f-14"></i> 
            </div>
          </div>
        </div>
        <div v-if="model.guides.length < 4" class="upload-image-button">
          <label for="guides-upload" class="f-24" style="cursor: pointer;">
            <i class="fa fa-upload" aria-hidden="true"></i> 
            <p class="f-14">{{ isUploadingGuide ? 'Uploading...' : 'Add Image'}}</p>
          </label>
          <input id="guides-upload" class="input-file-avatar d-none" type="file" accept="image/png, image/jpeg" @change="(event) => handleFileUpload(event, 'guides')" :disabled="isUploadingGuide" />
        </div>
      </div>
    </div>

    <div class="form-group mt-3">
      <label>Contoh foto yang salah</label>
    </div>

    <div class="row">
      <div class="col-12 d-flex" style="gap: 1rem; flex-wrap: wrap;">
        <div v-for="(badExample, index) in model.badExamples" :key="index">
          <div class="position-relative">
            <img :src="badExample" width="140" height="140" style="object-fit: cover; border-radius: 10px;" alt="">
            <div
              class="position-absolute bg-danger rounded-circle text-center"
              style="top: -10px; right: -10px; width: 21px; height: 21px; cursor: pointer;"
              @click="removeBadExample(index)"
            >
              <i class="fa fa-close text-white f-14"></i> 
            </div>
          </div>
        </div>
        <div v-if="model.badExamples.length < 4" class="upload-image-button">
          <label for="bad-examples-upload" class="f-24" style="cursor: pointer;">
            <i class="fa fa-upload" aria-hidden="true"></i> 
            <p class="f-14">{{ isUploadingBadExample ? 'Uploading...' : 'Add Image'}}</p>
          </label>
          <input id="bad-examples-upload" class="input-file-avatar d-none" type="file" accept="image/png, image/jpeg" @change="(event) => handleFileUpload(event, 'badExamples')" :disabled="isUploadingBadExample" />
        </div>
      </div>
    </div>

    <div class="form-group mt-3">
      <label>Photo frame and instruction</label>
    </div>

    <div class="row mb-2" v-for="(frame, index) in model.frames" :key="`${index}/${model.frames.length}`">
      <div class="col-4 d-flex flex-column">
        <ValidationProvider
          :name="`Photo Frame ${index + 1}`"
          v-slot="{ errors, validate }"
          :rules="{ required: !frame.image, 'max-dimensions': 512 }"
          class="d-flex flex-column"
        >
          <label class="required">
            Frame
          </label>
          <input
            type="file"
            accept="image/png"
            ref="imageFrame"
            @change="(e) => {
              validate(e).then(({ valid }) => {
                if (valid) {
                  handleFileUpload(e, 'frame', index);
                }
              });
            }"
            class="d-none"
          />
          <img
            v-if="frame.image"
            @click="$refs.imageFrame[index].click()"
            :src="frame.image"
            width="140"
            height="140"
            class="cursor-pointer"
            style="object-fit: cover; background-color: #E5E7E9; border-radius: 10px;"
          />
          <skeleton-img v-else @click="$refs.imageFrame[index].click()" style="width: 140px; height: 140px; border-radius: 10px;"/>
          <small class="text-danger" v-show="errors[0]">{{ errors[0] }}</small>
          <small class="block" style="margin-top: 4px;">Aspect ratio: square (1:1)</small>
          <small class="block" style="margin-top: 4px;">Recommended dimensions: 512 x 512 pixels</small>
          <small class="block" style="margin-top: 4px;">Max width: 512 pixels</small>
        </ValidationProvider>
      </div>
      <ValidationProvider :name="`Caption ${index + 1}`" v-slot="{ errors }" rules="required" class="form-group col-6">
        <label class="required">
          Caption
        </label>
        <input type="text" v-model="frame.caption" placeholder="Enter caption" class="form-control">
        <small class="text-danger" v-show="errors[0]">{{ errors[0] }}</small>
      </ValidationProvider>
      <div class="col-2 d-flex align-items-start mt-2" style="gap: .25rem;">
        <button v-if="model.frames.length < 5" class="btn btn-info" @click="addFrame(index + 1)" type="button">
          <i class="fa fa-plus"></i>
        </button>
        <button class="btn btn-danger" @click="removeFrame(index)" type="button">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>

    <div class="row" v-if="model.frames.length === 0">
      <div class="col">
        <button class="btn btn-info" @click="addFrame(0)" type="button">
          <i class="fa fa-plus"></i>
          Add Frame
        </button>
      </div>
    </div>
    <hr/>
  </form>
</template>

<script>
export default {
    name: 'PageTypeUploadPhoto',
    data() {
      return {
        model: {
          title: '',
          description: '',
          tips: [],
          guides: [],
          badExamples: [],
          frames: [],
        },
        isUploadingGuide: false,
        isUploadingBadExample: false,
        maxFileSize: 2,
      }
    },
    beforeMount() {
      if (this.$route.params.id) {
        this.model = {
          ...this.$store.getters.currentEditContent,
          badExamples: this.$store.getters.currentEditContent.badExamples || [],
        };
      }
    },
    methods: {
      handleFileUpload(event, field, index){
        const file = event.target.files[0];
        if (file.size > ((1024 * 1024) * this.maxFileSize)) {
          this.$toast.open({
            type: 'error',
            message: `The image file is too large, allowed maximum size is ${this.maxFileSize} MB`,
          });
          return;
        }

        const formData = new FormData();
        formData.append('folder', 'contents');
        formData.append('file', file);

        switch (field) {
          case 'guides':
            this.isUploadingGuide = true;
            break;
          case 'badExamples':
            this.isUploadingBadExample = true;
            break;
          default:
            break;
        }

        this.axios.post('/misc/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(resp => {
            if (resp.data.success) {
              switch (field) {
                case 'guides':
                  this.model.guides.push(resp.data.data.file);
                  break;
                case 'badExamples':
                  this.model.badExamples.push(resp.data.data.file);
                  break;
                case 'frame':
                  this.model.frames[index].image = resp.data.data.file;
                  break;
                default:
                  break;
              }
            }
          }).catch(err => {
            console.log(err.response);
          }).finally(() => {
            switch (field) {
              case 'guides':
                this.isUploadingGuide = false;
                break;
              case 'badExamples':
                this.isUploadingBadExample = false;
                break;
              default:
                break;
            }
          });
      },
      addTip() {
        this.model.tips.push('');
      },
      removeTip(index) {
        this.model.tips = [...this.model.tips.slice(0, index), ...this.model.tips.slice(index + 1)];
      },
      removeGuide(index) {
        this.model.guides = [...this.model.guides.slice(0, index), ...this.model.guides.slice(index + 1)];
      },
      removeBadExample(index) {
        this.model.badExamples = [...this.model.badExamples.slice(0, index), ...this.model.badExamples.slice(index + 1)];
      },
      updateStore(payload) {
        this.$store.dispatch('contentUploadPhoto', payload);
      },
      addFrame(index) {
        this.model.frames.splice(index, 0, { image: null, caption: '' });
      },
      removeFrame(index) {
        this.model.frames = [...this.model.frames.slice(0, index), ...this.model.frames.slice(index + 1)];
      }
    },
    watch: {
      'model.title': function(newValue) {
        this.updateStore({ pageOption: { title: newValue } });
      },
      'model.description': function(newValue) {
        this.updateStore({ pageOption: { description: newValue } });
      },
      'model.tips': function(newValue) {
        this.updateStore({ pageOption: { tips: newValue } });
      },
      'model.guides': function(newValue) {
        this.updateStore({ pageOption: { guides: newValue } });
      },
      'model.badExamples': function(newValue) {
        this.updateStore({ pageOption: { badExamples: newValue } });
      },
      'model.frames': function(newValue) {
        this.updateStore({ pageOption: { frames: newValue } });
      },
    },
    mounted() {
      this.maxFileSize = process.env.VUE_APP_MAX_FILE_SIZE;
    }
}
</script>

<style scoped>
.upload-image-button {
  text-align: center;
  border-radius: 10px;
  background: #E5E7E9;
  cursor: pointer;
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>