<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">Condition</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors && success==false || image_size_after || image_size_before">
                <div class="text-danger" v-if="message_error=='File too large' || image_size_after || image_size_before">
                    File too large please select a file less than 2mb
                   <span v-show="scrollToTop()"></span>
                </div>
                <div class="text-danger" v-else>
                    {{errors}}
                   <span v-show="scrollToTop()"></span>
                </div>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="is Popular" rules="required" v-slot="{ errors }" class="w-25 ml-2">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Is Popular? <span class="alert-required">*</span></label>
                                <select class="form-control" v-model="model.isPopular">
                                    <option :value="true">Yes</option>
                                    <option :value="false">No</option>
                                </select>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Is Active?" rules="required" v-slot="{ errors }" class="w-25 ml-2">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label>Is Active? <span class="alert-required">*</span></label>
                                <select class="form-control" v-model="model.isActive">
                                    <option :value="true">Yes</option>
                                    <option :value="false">No</option>
                                </select>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <app-collapse accordion :type="collapseType">
                        <app-collapse-item title="Section Image" :isVisible="false">
                            <div class="d-flex flex-row w-100">
                                <ValidationProvider name="mwebSectionImage" rules="required" v-slot="{ errors }" class="w-25">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Is Section Active? <span class="alert-required">*</span></label>
                                        <select class="form-control" v-model="model.mwebSectionImage">
                                            <option :value="true">Yes</option>
                                            <option :value="false">No</option>
                                        </select>
                                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <hr>
                            <label for="">Images</label>
                            <div class="row">
                                <div v-for="(image, index) in model.mwebImages" :key="index">
                                    <div v-if="image != null" class="text-center profile-avatar">
                                        <img :src="image" class="img-fluid rounded m-1 pointer" alt="" style="width: 150px; height: 150px;" @click="lihat(image)"><br>
                                        <div class="edit-avatar">
                                            <button class="btn btn-rounded btn-danger" 
                                                title="Delete Image" @click="deleteFile(image)"
                                                type="button">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>                                                        
                                </div>
                                <div class="pl-1">
                                    <div style="
                                            text-align: center; 
                                            border-radius: 10px; 
                                            background: #E5E7E9;
                                            padding-top: 25px;
                                            padding-bottom: 25px;
                                            cursor: pointer;
                                            width: 150px;
                                            height: 150px;
                                        " class="mr-1 mt-1 mb-1">
                                        <label for="file-upload" class="btn-edit" style="font-size: 40px; cursor: pointer;">
                                            <i class="fa fa-upload" aria-hidden="true"></i> 
                                            <p style="font-size: 14px">Upload Image</p>
                                        </label>
                                        <input id="file-upload" class="input-file-avatar d-none" type="file" @change="handleFileUpload( $event )">
                                    </div>
                                    <small class="text-danger" v-show="image_size">The image file is too large, <br>allowed maximum size is {{max_file_size}}MB</small>
                                </div>
                            </div>
                            <small class="text-danger" v-show="no_image">The image field is required</small>
                            <div class="row">
                                <ValidationProvider name="mwebIcon" v-slot="{ errors }" rules="" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Icon</label>
                                        <input input type="file" accept=".png, .jpg, .jpeg" @change="storemwebIcon" label="Icon" name="mwebIcon" class="form-control">
                                        <input type="hidden" v-model="model.mwebIcon" class="form-control" placeholder="Enter Icon">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        <small class="text-danger" v-show="image_size_icon">The image file is too large, allowed maximum size is {{max_file_size}}MB</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="row">
                                <ValidationProvider class="col">
                                    <div class="form-group" v-if="model.thumbnailmwebIcon">
                                        <label>Preview Icon</label>
                                        <img :src="model.thumbnailmwebIcon" class="responsive img-thumbnail" />
                                    </div>
                                    <div class="form-group" v-else>
                                        <label>Preview Icon</label>
                                        <img :src="model.mwebIcon" class="responsive img-thumbnail" />
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="Pre Title" v-slot="{ errors }" :rules="model.mwebSectionImage == true ? 'required': ''" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Pre Title <span class="alert-required" v-show="model.mwebSectionImage == true">*</span></label>
                                    <input type="text" name="namePage" v-model="model.namePage" class="form-control" placeholder="Enter Pre Title" @keyup="handlingSlug">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>    
                            <ValidationProvider name="title" v-slot="{ errors }" :rules="model.mwebSectionImage == true ? 'required': ''" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Title <span class="alert-required" v-show="model.mwebSectionImage == true">*</span></label>
                                    <quill-editor v-model="model.title" :options="editorOption"></quill-editor>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="imagePageAlt" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Alt Image Thumbnail</label>
                                    <input type="text" v-model="model.imagePageAlt" class="form-control" placeholder="Enter Alt Image">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="imagePage" v-slot="{ errors }" :rules="model.mwebSectionImage == true ? 'required': ''" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Image Thumbnail<span class="alert-required" v-show="model.mwebSectionImage == true">*</span></label>
                                    <input type="file" accept=".png, .jpg, .jpeg" @change="storeImagePage" label="Image" name="imagePage" class="form-control">
                                    <input type="hidden" v-model="model.imagePage" class="form-control" placeholder="Enter Image">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    <small class="text-danger" v-show="image_size_page">The image file is too large, allowed maximum size is {{max_file_size}}MB</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider class="col">
                                <div class="form-group" v-if="model.thumbnailimagePage">
                                    <label>Preview Image</label>
                                    <img :src="model.thumbnailimagePage" class="responsive img-thumbnail" />
                                </div>
                                <div class="form-group" v-else>
                                    <label>Preview Image</label>
                                    <img :src="model.imagePage" class="responsive img-thumbnail" />
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Button Sitemap" :isVisible="false">
                            <div class="row">
                                <ValidationProvider name="mwebButtonConsultName" v-slot="{ errors }" rules="" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Title </label>
                                        <input type="text" name="mwebButtonConsultName" v-model="model.mwebButtonConsultName" class="form-control" placeholder="Enter Button Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="buttonConsult1" v-slot="{ errors }" rules="required" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Button Mulai Konsultasi <span class="alert-required">*</span></label>
                                        <select name="buttonConsult1" id="" v-model="model.buttonConsult1" class="form-control">
                                            <option value="">Select Button</option>
                                            <option v-for="(data,index) in buttons" :key="index" :value="data.id">
                                                {{data.title}}
                                            </option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section Content" :isVisible="false">
                            <ValidationProvider name="mwebSectionContent" rules="required" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Section Active ? <span class="alert-required">*</span></label>
                                    <select class="form-control" v-model="model.mwebSectionContent">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <ValidationProvider name="mwebDescription" v-slot="{ errors }" rules="" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Description</label>
                                    <quill-editor v-model="model.mwebDescription" :options="editorOption"></quill-editor>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="mwebOurTreatment" v-slot="{ errors }" rules="" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Perawatan di Diri Online Clinic</label>
                                    <quill-editor v-model="model.mwebOurTreatment" :options="editorOption"></quill-editor>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Product" :isVisible="false">
                            <ValidationProvider name="sectionCatalog" rules="required" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Section Active ? <span class="alert-required">*</span></label>
                                    <select class="form-control" v-model="model.sectionCatalog">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="catalogTitle" v-slot="{ errors }" :rules="model.sectionCatalog == true ? 'required': ''" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title <span class="alert-required" v-show="model.sectionCatalog == true">*</span></label>
                                        <input type="text" v-model="model.catalogTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="catalogDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.catalogDescription" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="url see all" v-slot="{ errors }" :rules="{regex:/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/}" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Url See All </label>
                                    <input type="text" v-model="model.mwebProductFullUrl" class="form-control" placeholder="Enter URL https://www.url.com">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="catalogProductId" v-slot="{ errors }" :rules="model.sectionCatalog == true ? 'required': ''" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="descriptionInput">Catalog Product <span class="alert-required" v-show="model.sectionCatalog == true">*</span></label>
                                    <multiselect 
                                        v-model="model.catalogProductId"
                                        label="title"
                                        name="catalog"
                                        track-by="id" 
                                        placeholder="Search Catalog Product"
                                        :options="catalog" 
                                        :multiple="true" 
                                        :searchable="true" 
                                        :clear-on-select="false" 
                                        :close-on-select="false" 
                                        :max-height="600">
                                        <span slot="noResult">Oops! There is no catalog product.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </app-collapse-item>
                        <app-collapse-item title="Section Testimony" :isVisible="false">
                            <ValidationProvider name="Section Testimony Activation" rules="required" v-slot="{ errors }">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Section Active ? <span class="alert-required">*</span></label>
                                    <select class="form-control" v-model="model.sectionBeforeAfter">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <div class="row">
                                <ValidationProvider name="beforeAfterTitle" v-slot="{ errors }" :rules="model.sectionBeforeAfter == true ? 'required': ''" class="col">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Title <span class="alert-required" v-show="model.sectionBeforeAfter == true">*</span></label>
                                        <input type="text" v-model="model.beforeAfterTitle" class="form-control" placeholder="Enter Title">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="beforeAfterDescription" v-slot="{ errors }" class="col">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Description</label>
                                        <textarea name="" id="" cols="30" rows="5" v-model="model.beforeAfterDescription" class="form-control"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section F.A.Q" :isVisible="false">
                            <ValidationProvider name="sectionFaq" v-slot="{ errors }" rules="required">
                                <div class="form-group" style="width: 20%" :class="{ 'validation-warning': errors[0] }">
                                    <label>Is Section Active ? <span class="alert-required">*</span></label>
                                    <select class="form-control" v-model="model.sectionFaq">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                            <ValidationProvider name="faqTitle" v-slot="{ errors }" class="col">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Title F.A.Q</label>
                                    <input type="text" v-model="model.faqTitle" class="form-control" placeholder="Enter Title">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="faqSubtitle" v-slot="{ errors }" class="col">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Subtitle F.A.Q</label>
                                    <textarea name="" id="" cols="30" rows="3" v-model="model.faqSubtitle" class="form-control" placeholder="Enter Subtitle"></textarea>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <div class="row">
                                <div class="form-group col">
                                    <label for="descriptionInput">FAQ</label>
                                    <multiselect
                                        v-model="model.faqs"
                                        label="question"
                                        name="faqs"
                                        track-by="id"
                                        placeholder="Search FAQ"
                                        :options="faqs"
                                        :multiple="true"
                                        :searchable="true"
                                        :clear-on-select="false"
                                        :close-on-select="false"
                                        :max-height="600"
                                    >
                                        <span slot="noResult">Oops! There is no FAQs.</span>
                                    </multiselect>
                                </div>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="Section SEO" :isVisible="false">
                            <div class="row">
                                <ValidationProvider name="Page Title" v-slot="{ errors }" class="col" rules="required|min:30|max:200">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label for="metaTitle">Page Title <span class="alert-required">*</span></label>
                                        <textarea rows="5" class="form-control" v-model="model.metaTitle" id="metaTitle" placeholder="Min 30 chars, max 200 chars"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Meta Description" v-slot="{ errors }" class="col" rules="required|min:100|max:200">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label for="metaDescription">Meta Description <span class="alert-required">*</span></label>
                                        <textarea rows="5" class="form-control" v-model="model.metaDescription" id="metaDescription" placeholder="Min 100 chars, max 200 chars"></textarea>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <ValidationProvider name="URL Slug" v-slot="{ errors }" rules="required|slug-max-words:5">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>URL Slug <span class="alert-required">*</span></label>
                                    <input type="text" v-model="model.slug" class="form-control" placeholder="Example: this-is-valid-slug">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                            <hr>
                        </app-collapse-item>
                        <hr>
                    </app-collapse>

                    <div class="text-left mt-1">
                        <router-link to="/condition-library" class="btn text-danger float-left">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-left" :disabled="isLoading || message_error=='File too large' || !isValidURL || image_size_icon || image_size_after || image_size_before">
                            <div v-if="isLoading">Saving<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Create</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { replaceAllEnterWithSpace } from '@/helper/string';
import ld from 'lodash'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] },{ 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", '#0B54F7', '#D5E0FE', '#074ae0', '#8C93A0', '#272A33', '#EB5757', '#FF5E2D', '#FF5E2D', '#D5DFDE', '#E3DEDC', '#C9D2DC', '#E5ECC9', '#5B5E62'] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
    ['link']
]
export default {
    components: {
        AppCollapse,
        AppCollapseItem
    },
    data() {
        return {
            collapseType: 'margin',
            model: {
                namePage: '',
                imagePage: '',
                thumbnailimagePage: '',
                imagePageAlt: '',
                preTitle: '',
                title: '',
                description: '',
                bannerColor: '',
                image: '',
                imageAlt: '',
                thumbnailimage: '',
                sectionProblem: true,
                problemTitle: '',
                problemDescription: '',
                problem1Title: '',
                problem1Description: '',
                problem1Image: '',
                thumbnailproblem1Image: '',
                problem1ImageAlt: '',
                problem2Title: '',
                problem2Description: '',
                problem2Image: '',
                thumbnailproblem2Image: '',
                problem2ImageAlt: '',
                sectionTreatment: true,
                treatmentTitle: '',
                treatmentDescription: '',
                treatmentLeftTitle: '',
                treatmentLeftDescription: '',
                treatmentLeftIcon: '',
                thumbnailtreatmentLeftIcon: '',
                treatmentRightTitle: '',
                treatmentRightDescription: '',
                treatmentRightIcon: '',
                thumbnailtreatmentRightIcon: '',
                sectionBeforeAfter: true,
                beforeAfterTitle: '',
                beforeAfterDescription: '',
                sectionPlan: true,
                planTitle: '',
                planDescription: '',
                planImage: '',
                thumbnailplanImage: '',
                planImageAlt: '',
                plan1Title: '',
                plan1Icon: '',
                thumbnailplan1Icon: '',
                plan2Title: '',
                plan2Icon: '',
                thumbnailplan2Icon: '',
                plan3Title: '',
                plan3Icon: '',
                thumbnailplan3Icon: '',
                plan4Title: '',
                plan4Icon: '',
                thumbnailplan4Icon: '',
                productSpecialId: '',
                sectionDoctor: true,
                doctorTitle: '',
                doctorId: '',
                doctorDescription: '',
                doctorName: '',
                doctorImage: '',
                thumbnaildoctorImage: '',
                doctorImageAlt: '',
                doctorSpecialis: '',
                mwebButtonConsultName: '',
                buttonConsult1: '',
                buttonConsult2: '',
                sectionCatalog: true,
                catalogTitle: '',
                catalogDescription: '',
                catalogProductId: [],
                mwebSectionContent: true,
                mwebDescription: '',
                mwebOurTreatment: '',
                mwebIcon: '',
                isPopular: false,
                isActive: true,
                thumbnailmwebIcon: null,
                mwebSectionImage: true,
                mwebImages: [],
                mwebProductFullUrl: '',
                sectionFaq: true,
                faqTitle: '',
                faqSubtitle: '',
                slug: '',
                faqs: [
                    {
                        question: null,
                        answer: null,
                    }
                ],
                specialTreatments: [
                    {
                        title: null,
                        icon: null,
                        iconLink: null,
                        thumbnailicon: null,
                        type: null

                    }
                ],
            },
            conditionpages: [],
            special_product: [],
            buttons: [],
            catalog: [],
            doctors: [],
            faqs: [],
            selectedDoctor: '',
            isLoading: false,
            errors: null,
            message_error: null,
            success: true,
            no_image: false,
            image_size: false,
            image_size_icon: false,
            image_size_page: false,
            image_size_before: false,
            image_size_after: false,
            max_file_size: 2,
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    }
                }
            },
            isValidURL: true,
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        change:function(e){
            const url = e.target.value
            this.isURLValid(url);
        },
        isURLValid: function(inputUrl) {
            const regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            this.isValidURL=   regex.test(inputUrl)
        },
        get() {
            let _ = this
            _.axios.get('/dc-condition-page/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                    _.model.catalogProductId = _.model.catalog
                    _.selectedDoctor = _.model.doctors
                    _.model.productSpecialId = _.model.special_product.id
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getConditions() {
           let _ = this
            _.axios.get('/dc-condition-page/list-all')
            .then(resp=>{
                _.conditionpages = resp.data.data
            }) 
        },
        getProducts() {
           let _ = this
            _.axios.get('/dc-special-page/list-all')
            .then(resp=>{
                _.special_product = resp.data.data
            }) 
        },
        getButtons() {
            let _ = this
            _.axios.get('/misc/flows')
            .then(resp=>{
                _.buttons = resp.data.data
            })
        },
        getCatalogProduct() {
           let _ = this
            _.axios.get('/misc/products')
            .then(resp=>{
                _.catalog = resp.data.data
            }) 
        },
        getDoctors() {
           let _ = this
            _.axios.get('/dc-doctor/list-all')
            .then(resp=>{
                _.doctors = resp.data.data
            }) 
        },
        getFaqs() {
            this.axios.get('/dc-master-faq/list-all')
                .then(res => {
                    this.faqs = res.data.data;
                });
        },
        addspecialTreatments() {
            let _ = this
            _.model.specialTreatments.push({
                title: null,
                icon: null,
                type: null
            })
        },
        deletespecialTreatments(x) {
            let _ = this
            _.model.specialTreatments.splice(x, 1);
            // _.selectedContents.splice(x, 1);
        },
        storeImagePage(event){
            let images = event.target.files[0];
            if (images.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size_page = true
                return;
            } else {
                this.image_size_page = false
            }
            this.model.imagePage = images;
            this.$set(this.model, 'thumbnailimagePage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeImage(event){
            this.model.image = event.target.files[0];
            this.$set(this.model, 'thumbnailimage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeplanImage(event){
            this.model.planImage = event.target.files[0];
            this.$set(this.model, 'thumbnailplanImage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storedoctorImage(event){
            this.model.doctorImage = event.target.files[0];
            this.$set(this.model, 'thumbnaildoctorImage', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeproblem1Image(event){
            this.model.problem1Image = event.target.files[0];
            this.$set(this.model, 'thumbnailproblem1Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeproblem2Image(event){
            this.model.problem2Image = event.target.files[0];
            this.$set(this.model, 'thumbnailproblem2Image', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeplan1Icon(event){
            this.model.plan1Icon = event.target.files[0];
            this.$set(this.model, 'thumbnailplan1Icon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeplan2Icon(event){
            this.model.plan2Icon = event.target.files[0];
            this.$set(this.model, 'thumbnailplan2Icon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeplan3Icon(event){
            this.model.plan3Icon = event.target.files[0];
            this.$set(this.model, 'thumbnailplan3Icon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeplan4Icon(event){
            this.model.plan4Icon = event.target.files[0];
            this.$set(this.model, 'thumbnailplan4Icon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storetreatmentLeftIcon(event){
            this.model.treatmentLeftIcon = event.target.files[0];
            this.$set(this.model, 'thumbnailtreatmentLeftIcon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storetreatmentRightIcon(event){
            this.model.treatmentRightIcon = event.target.files[0];
            this.$set(this.model, 'thumbnailtreatmentRightIcon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storemwebIcon(event){
            let images = event.target.files[0];
            if (images.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size_icon = true
                return;
            } else {
                this.image_size_icon = false
            }
            this.model.mwebIcon = images;
            this.$set(this.model, 'thumbnailmwebIcon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
        },
        storeIcon(event, idx){
            this.model.specialTreatments[idx].iconLink = event.target.files[0];
            this.$set(this.model.specialTreatments[idx], 'thumbnailicon', window.URL.createObjectURL(event.target.files[0]));
            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData();
            formData.append("folder", "specialTreatments");
            formData.append("file", this.model.specialTreatments[idx].iconLink);
            this.axios.post('/misc/upload', formData)
            .then(resp => {
                this.$notify({
                    type: 'success',
                    title: 'Success!',
                    text: resp.data.message
                })
                this.model.specialTreatments[idx].icon = resp.data.data.file
                this.success = resp.data.success
                this.message_error = resp.data.message
                this.isLoading = false
            })
            .catch(err => {
                this.errors = err.response.data.message
                this.message_error = err.response.data.message
                this.success = err.response.data.success
                this.isLoading = false
            })
        },
        updateColor(event) {
            this.model.bannerColor = event.hex
        },
        handleFileUpload(event){
            this.file = event.target.files[0]
            if (this.file.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size = true
                return;
            } else {
                this.image_size = false
            }
            this.success=true;
            this.message_error=null;
            this.isLoading = true
            let formData = new FormData()
            formData.append('folder', 'image-mweb-condition')
            formData.append('file', this.file)
            this.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    this.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    this.model.mwebImages.push(resp.data.data.file)
                    this.success = resp.data.success
                    this.message_error = resp.data.message
                    this.isLoading = false
                }).catch(err => {
                    this.errors = err.response.data.message
                    this.message_error = err.response.data.message
                    this.success = err.response.data.success
                    this.isLoading = false
                })
        },
        deleteFile (url){
            let _ = this
            _.model.mwebImages = ld.filter(_.model.mwebImages, function(o){
                return o != url;
            })
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        },
        handlingSlug(slug) {
            this.model.slug = slug.target.value.toLowerCase().split(' ').join('-');
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                let formData = new FormData();
                formData.append("namePage", _.model.namePage);
                formData.append("imagePage", _.model.imagePage);
                formData.append("imagePageAlt", _.model.imagePageAlt);
                formData.append("preTitle", _.model.preTitle);
                formData.append("title", _.model.title);
                formData.append("description", _.model.description);
                formData.append("bannerColor", _.model.bannerColor);
                formData.append("image", _.model.image);
                formData.append("imageAlt", _.model.imageAlt);
                formData.append("sectionProblem", _.model.sectionProblem);
                formData.append("problemTitle", _.model.problemTitle);
                formData.append("problemDescription", _.model.problemDescription);
                formData.append("problem1Title", _.model.problem1Title);
                formData.append("problem1Description", _.model.problem1Description);
                formData.append("problem1Image", _.model.problem1Image);
                formData.append("problem1ImageAlt", _.model.problem1ImageAlt);
                formData.append("problem2Title", _.model.problem2Title);
                formData.append("problem2Description", _.model.problem2Description);
                formData.append("problem2Image", _.model.problem2Image);
                formData.append("problem2ImageAlt", _.model.problem2ImageAlt);
                formData.append("sectionTreatment", _.model.sectionTreatment);
                formData.append("treatmentTitle", _.model.treatmentTitle);
                formData.append("treatmentDescription", _.model.treatmentDescription);
                formData.append("treatmentLeftTitle", _.model.treatmentLeftTitle); 
                formData.append("treatmentLeftDescription", _.model.treatmentLeftDescription);
                formData.append("treatmentLeftIcon", _.model.treatmentLeftIcon);
                formData.append("treatmentRightTitle", _.model.treatmentRightTitle);
                formData.append("treatmentRightDescription", _.model.treatmentRightDescription);
                formData.append("treatmentRightIcon", _.model.treatmentRightIcon);
                formData.append("sectionBeforeAfter", _.model.sectionBeforeAfter);
                formData.append("beforeAfterTitle", _.model.beforeAfterTitle);
                formData.append("beforeAfterDescription", _.model.beforeAfterDescription);
                formData.append("sectionPlan", _.model.sectionPlan);
                formData.append("planTitle", _.model.planTitle);
                formData.append("planDescription", _.model.planDescription);
                formData.append("planImage", _.model.planImage);
                formData.append("planImageAlt", _.model.planImageAlt);
                formData.append("plan1Title", _.model.plan1Title);
                formData.append("plan1Icon", _.model.plan1Icon);
                formData.append("plan2Title", _.model.plan2Title);
                formData.append("plan2Icon", _.model.plan2Icon);
                formData.append("plan3Title", _.model.plan3Title);
                formData.append("plan3Icon", _.model.plan3Icon);
                formData.append("plan4Title", _.model.plan4Title);
                formData.append("plan4Icon", _.model.plan4Icon);
                formData.append("productSpecialId", _.model.productSpecialId);
                formData.append("sectionDoctor", _.model.sectionDoctor);
                formData.append("doctorTitle", _.model.doctorTitle);
                formData.append("doctorId", _.model.doctorId);
                formData.append("doctorDescription", _.model.doctorDescription);
                formData.append("doctorName", _.model.doctorName);
                formData.append("doctorImage", _.model.doctorImage);
                formData.append("doctorImageAlt", _.model.doctorImageAlt);
                formData.append("doctorSpecialis", _.model.doctorSpecialis);
                formData.append("sectionFaq", _.model.sectionFaq);
                formData.append("faqTitle", _.model.faqTitle);
                formData.append("faqSubtitle", _.model.faqSubtitle);
                formData.append("faqIds", JSON.stringify(_.model.faqs.map(faq => faq.id)));
                formData.append("specialTreatments", JSON.stringify(_.model.specialTreatments));
                formData.append("mwebButtonConsultName", _.model.mwebButtonConsultName);
                formData.append("buttonConsult1", _.model.buttonConsult1);
                formData.append("buttonConsult2", _.model.buttonConsult2);
                formData.append("mwebSectionContent", _.model.mwebSectionContent);
                formData.append("mwebDescription", _.model.mwebDescription);
                formData.append("mwebOurTreatment", _.model.mwebOurTreatment);
                formData.append("mwebIcon", _.model.mwebIcon);
                formData.append("isPopular", _.model.isPopular);
                formData.append("isActive", _.model.isActive);
                formData.append("mwebSectionImage", _.model.mwebSectionImage);
                formData.append("mwebImages", JSON.stringify(_.model.mwebImages));
                formData.append("sectionCatalog", _.model.sectionCatalog);
                formData.append("mwebProductFullUrl", _.model.mwebProductFullUrl);
                formData.append("catalogDescription", _.model.catalogDescription);
                formData.append("catalogTitle", _.model.catalogTitle);
                formData.append("catalogProductId", JSON.stringify(_.model.catalogProductId.map(p => p.id)));
                formData.append("metaTitle", replaceAllEnterWithSpace(_.model.metaTitle));
                formData.append("metaDescription", replaceAllEnterWithSpace(_.model.metaDescription));
                formData.append("slug", _.model.slug);
                if (!_.$route.params.id) {
                    _.axios.post('/dc-condition-page', formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.success = resp.data.success
                            _.$router.push('/condition-library')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.message_error = err.response.data.message
                            _.success = err.response.data.success
                            _.isLoading = false
                        })     
                } else {
                    _.axios.put('/dc-condition-page/' + _.$route.params.id, formData)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.success = resp.data.success
                            _.$router.push('/condition-library')
                        })
                        .catch(err => {
                            _.errors = err.response.data.message
                            _.message_error = err.response.data.message
                            _.success = err.response.data.success
                            _.isLoading = false
                        })   
                }  
            }
        }
        
    },
    mounted() {
        if (this.$route.params.id) {
            this.get();
        }
        this.getConditions();
        this.getProducts();
        this.getCatalogProduct();
        this.getButtons();
        this.getDoctors();
        this.getFaqs();
    }
}
</script>