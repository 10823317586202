<template>
  <div class="card col-lg-12 p-0">
    <div class="card-body">
      <h3 class="font-600">Revert Rejected Order</h3>
      <p class="f-14 mb-2">
        Revert order status from <b>Rejected</b> to <b>New Order</b>
      </p>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <div class="row">
            <ValidationProvider
              name="Order ID"
              v-slot="{ errors }"
              rules="required"
              class="col-6"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Order ID</label>
                <input
                  type="text"
                  v-model="orderId"
                  class="form-control"
                  placeholder="Enter Order ID"
                />
                <small class="text-danger" v-if="errors[0]">
                  {{ errors[0] }}
                </small>
              </div>
            </ValidationProvider>
          </div>
          <div class="text-left mt-4">
            <button
              type="submit"
              class="btn btn-primary float-left"
              :disabled="isLoading"
            >
              <div v-if="isLoading">
                Loading<i class="fa fa-spinner fa-spin"></i>
              </div>
              <div v-else>Submit</div>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderId: '',
      isLoading: false,
    };
  },
  methods: {
    async resetData() {
      this.orderId = '';
      await this.$refs.observer.reset();
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.isLoading = true;

        const payload = {
          orderId: this.orderId,
        };

        this.axios
          .post('/devs/orders/revert-reject-order', payload)
          .then((resp) => {
            this.$notify({
              type: 'success',
              title: 'Success!',
              text: resp.data.message,
            });
            this.resetData();
          })
          .catch((err) => {
            this.$notify({
              type: 'error',
              title: 'Error',
              text: err.response.data.message,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
