<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Inventory</h3>
            <div class="alert-danger p-1 mb-1 text-danger" style="border-radius: 8px;" v-if="errorMessage">
                {{ errorMessage }}
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <div class="row">
                        <ValidationProvider name="Category Level 1" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="required">Category Level 1</label>
                                <multiselect 
                                    v-model="selected_type_level_1"
                                    label="name"
                                    track-by="id" 
                                    placeholder="Select Category Level 1"
                                    :options="types.filter(x => x.parentId == null)"
                                    @input="
                                        model.typeLevelOneId = selected_type_level_1 ? selected_type_level_1.id : null, 
                                        model.typeLevelTwoId = null,
                                        model.typeLevelThreeId = null,
                                        selected_type_level_2 = null,
                                        selected_type_level_3 = null
                                    ">
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                                <input type="hidden" v-model="model.typeLevelOneId">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div class="col">
                            <div class="form-group">
                                <label>Category Level 2</label>
                                <multiselect 
                                    v-model="selected_type_level_2"
                                    label="name"
                                    track-by="id" 
                                    placeholder="Select Category Level 2"
                                    :options="types.filter(x => x.parentId == model.typeLevelOneId)"
                                    @input="
                                        model.typeLevelTwoId = selected_type_level_2 ? selected_type_level_2.id : null,
                                        model.typeLevelThreeId = null,
                                        selected_type_level_3 = null"
                                    :disabled="!model.typeLevelOneId">
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                                <input type="hidden" v-model="model.typeLevelTwoId">
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Category Level 3</label>
                                <multiselect 
                                    v-model="selected_type_level_3"
                                    label="name"
                                    track-by="id" 
                                    placeholder="Select Category Level 3"
                                    :options="types.filter(x => x.parentId == model.typeLevelTwoId)"
                                    @input="model.typeLevelThreeId = selected_type_level_3 ? selected_type_level_3.id : null"
                                    :disabled="!model.typeLevelTwoId">
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                                <input type="hidden" v-model="model.typeLevelThreeId">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <ValidationProvider name="Product Type" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="required">Product Type</label>
                                <div class="form-control">
                                    <div class="form-check form-check-inline mr-3">
                                        <input class="form-check-input" type="radio" v-model="model.type" id="productDisplay" value="Display">
                                        <label class="form-check-label" for="productDisplay">Product Display</label>
                                    </div>
                                    <div class="form-check form-check-inline mr-3">
                                        <input class="form-check-input" type="radio" v-model="model.type" id="rxRed" value="Rx Red">
                                        <label class="form-check-label d-flex flex-row align-items-center gap-1" for="rxRed">
                                            Rx <span class="circle bg-danger"></span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline mr-3">
                                        <input class="form-check-input" type="radio" v-model="model.type" id="rxBlue" value="Rx Blue">
                                        <label class="form-check-label d-flex flex-row align-items-center gap-1" for="rxBlue">
                                            Rx <span class="circle bg-blue"></span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline mr-3">
                                        <input class="form-check-input" type="radio" v-model="model.type" id="otc" value="OTC">
                                        <label class="form-check-label" for="otc">OTC</label>
                                    </div>
                                </div>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider v-slot="{ errors }" name="flow" :rules="model.type !== 'OTC' ? 'required' : ''" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label :class="model.type !== 'OTC' ? 'required' : ''">Flow</label>
                                <multiselect 
                                    v-model="selected_flow"
                                    label="title"
                                    track-by="id" 
                                    placeholder="Select Flow"
                                    :options="flows"
                                    @input="model.flowId = selected_flow.id"
                                    :disabled="model.type === 'OTC'"
                                >
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                                <input type="hidden" v-model="model.flowId">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider v-slot="{ errors }" name="Real Product" :rules="isProductDisplay ? 'required' : ''" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label :class="isProductDisplay ? 'required' : ''">Real Product</label>
                                <multiselect 
                                    v-model="model.children"
                                    label="title"
                                    track-by="id"
                                    placeholder="Select Product"
                                    :options="realProducts"
                                    :multiple="true"
                                    :disabled="model.type !== 'Display'"
                                >
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider name="Manufacturer name" v-slot="{ errors }" rules="required">
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                            <label class="required">Manufacturer name</label>
                            <input type="text" v-model="model.manufacture" class="form-control" placeholder="Enter manufacturer name">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider name="SKU" v-slot="{ errors }" rules="required|alpha_num|max:20" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="required">SKU</label>
                                <input
                                    type="text"
                                    :value="model.sku"
                                    class="form-control"
                                    placeholder="Enter SKU"
                                    @input="(event) => (model.sku = event.target.value.toUpperCase())"
                                >
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="product name" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="required">Product Name</label>
                                <input type="text" v-model="model.publicTitle" class="form-control" placeholder="Enter product name">
                                <small class="text-muted" v-if="errors[0] && !uniqueError.publicTitle">{{ errors[0] }}</small>
							    <small class="text-danger" v-else-if="uniqueError.publicTitle">Product name has been used</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="internal product name" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="">Internal Product Name</label>
                                <input type="text" v-model="model.title" class="form-control" placeholder="Enter internal product name">
                                <small class="text-muted" v-if="errors[0] && !uniqueError.name">{{ errors[0] }}</small>
							    <small class="text-danger" v-else-if="uniqueError.name">Internal product name has been used</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label>Product Variants</label>
                            <p class="form-control read-only">
                                {{ model.productVariantNames.length > 0 ? model.productVariantNames.join(', ') : '-' }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <ValidationProvider name="url" v-slot="{ errors }" class="col" ref="slug">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="">Url Override</label>
                                <input type="text" v-model="model.slug" class="form-control" placeholder="Enter url">
                                <small class="text-muted" v-if="errors[0] && !uniqueError.slug">{{ errors[0] }}</small>
							    <small class="text-danger" v-else-if="uniqueError.slug">Product slug has been used</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="registration number" v-slot="{ errors }" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="">Registration Number</label>
                                <input type="text" v-model="model.registrationNo" class="form-control" placeholder="Enter registration number">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <ValidationProvider name="description" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label class="required">Description</label>
                            <textarea name="" id="" cols="30" rows="5" v-model="model.description" class="form-control" placeholder="Enter description"></textarea>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider name="unit" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="required">Unit</label>
                                <select name="" id="" class="form-control" v-model="model.unit">
                                    <option value="">Select Unit</option>
                                    <option value="butir">Butir</option>
                                    <option value="strip">Strip</option>
                                    <option value="botol">Botol</option>
                                    <option value="sachet">Sachet</option>
                                    <option value="pot">Pot</option>
                                    <option value="box">Box</option>
                                    <option value="vial">Vial</option>
                                    <option value="ampul">Ampul</option>
                                    <option value="tube">Tube</option>
                                    <option value="piece">Piece</option>
                                    <option value="pack">Pack</option>
                                </select>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="retail price" v-slot="{ errors }" :rules="isProductDisplay ? '' : 'required|min_value:1'" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label :class="isProductDisplay ? '' : 'required'">Retail Price</label>
                                <vue-numeric @keypress.native="NumbersOnly" v-model="model.price" class="form-control" placeholder="Enter retail price" currency="Rp" separator="." :disabled="isProductDisplay"></vue-numeric>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="selling price" v-slot="{ errors }" :rules="isProductDisplay ? '' : 'min_value:0|max_value:'+model.price" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">Selling Price</label>
                                <vue-numeric @keypress.native="NumbersOnly" v-model="model.discountPrice" class="form-control" placeholder="Enter selling price" currency="Rp" separator="." :disabled="isProductDisplay"></vue-numeric>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="stock" v-slot="{ errors }" :rules="isProductDisplay ? '' : 'required|min_value:0'" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label :class="isProductDisplay ? '' : 'required'">Stock</label>
                                <vue-numeric @keypress.native="NumbersOnly" v-model="model.stock" class="form-control" placeholder="Enter stock" separator="." :disabled="isProductDisplay"></vue-numeric>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Safety Stock" v-slot="{ errors }" :rules="isProductDisplay ? '' : 'required|min_value:0'" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label :class="isProductDisplay ? '' : 'required'">Safety Stock</label>
                                <vue-numeric @keypress.native="NumbersOnly" v-model="model.minimumStock" class="form-control" placeholder="Enter stock" separator="." :disabled="isProductDisplay"></vue-numeric>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="Minimum Order Quantity" v-slot="{ errors }" :rules="isProductDisplay ? '' : 'required|min_value:1'" class="col-3">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label :class="isProductDisplay ? '' : 'required'">Min. Order Quantity</label>
                                <vue-numeric @keypress.native="NumbersOnly" v-model="model.minPurchaseQuantity" class="form-control" placeholder="Enter quantity" separator="." :disabled="isProductDisplay"></vue-numeric>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Maximum Order Quantity" v-slot="{ errors }" :rules="isProductDisplay ? '' : `min_value:${model.minPurchaseQuantity ? model.minPurchaseQuantity : 1}`" class="col-3">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label>Max. Order Quantity</label>
                                <input type="number" @keypress="NumbersOnly" min="1" v-model.number="model.maxPurchaseQuantity" class="form-control" placeholder="Enter quantity" :disabled="isProductDisplay" />
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="weight" v-slot="{ errors }" :rules="isProductDisplay ? '' : 'required|min_value:0'" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label :class="isProductDisplay ? '' : 'required'">Weight (kg)</label>
                                <vue-numeric @keypress.native="NumbersOnly" v-model="model.weight" class="form-control" placeholder="Enter weight" currency="" separator="." :disabled="isProductDisplay" :precision="2"></vue-numeric>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="length" v-slot="{ errors }" :rules="isProductDisplay ? '' : 'required|min_value:0'" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label :class="isProductDisplay ? '' : 'required'">Length (cm)</label>
                                <vue-numeric @keypress.native="NumbersOnly" v-model="model.length" class="form-control" placeholder="Enter length" currency="" separator="." :disabled="isProductDisplay" :precision="2"></vue-numeric>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="width" v-slot="{ errors }" :rules="isProductDisplay ? '' : 'required|min_value:0'" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label :class="isProductDisplay ? '' : 'required'">Width (cm)</label>
                                <vue-numeric @keypress.native="NumbersOnly" v-model="model.width" class="form-control" placeholder="Enter width" currency="" separator="." :disabled="isProductDisplay" :precision="2"></vue-numeric>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="height" v-slot="{ errors }" :rules="isProductDisplay ? '' : 'required|min_value:0'" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label :class="isProductDisplay ? '' : 'required'">Height (cm)</label>
                                <vue-numeric @keypress.native="NumbersOnly" v-model="model.height" class="form-control" placeholder="Enter height" currency="" separator="." :disabled="isProductDisplay" :precision="2"></vue-numeric>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="ingredient" v-slot="{ errors }" rules="" class="col-lg-6">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">Ingredients</label>
                                <textarea name="" id="" cols="30" rows="5" v-model="model.composition" class="form-control" placeholder="Enter ingredients"></textarea>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="usage" v-slot="{ errors }" rules="" class="col-lg-6">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">Usage</label>
                                <textarea name="" id="" cols="30" rows="5" v-model="model.usage" class="form-control" placeholder="Enter usage"></textarea>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="general indication" v-slot="{ errors }" rules="" class="col-lg-6">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">General Indication / Function</label>
                                <textarea name="" id="" cols="30" rows="5" v-model="model.generalIndication" class="form-control" placeholder="Enter general indication"></textarea>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="dose" v-slot="{ errors }" rules="" class="col-lg-6">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">Dose</label>
                                <textarea name="" id="" cols="30" rows="5" v-model="model.measure" class="form-control" placeholder="Enter dose"></textarea>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="contra indication" v-slot="{ errors }" rules="" class="col-lg-6">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">Contra Indication</label>
                                <textarea name="" id="" cols="30" rows="5" v-model="model.contraIndication" class="form-control" placeholder="Enter contra indication"></textarea>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="group" v-slot="{ errors }" rules="" class="col-lg-6">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">Group</label>
                                <textarea name="" id="" cols="30" rows="5" v-model="model.group" class="form-control" placeholder="Enter group"></textarea>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="warning" v-slot="{ errors }" rules="" class="col-lg-6">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">Warning</label>
                                <textarea name="" id="" cols="30" rows="5" v-model="model.attention" class="form-control" placeholder="Enter warning"></textarea>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="packaging" v-slot="{ errors }" rules="" class="col-lg-6">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">Packaging</label>
                                <textarea name="" id="" cols="30" rows="5" v-model="model.packaging" class="form-control" placeholder="Enter packaging"></textarea>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-6">
                            <label>Fabrication Instruction</label>
                            <textarea cols="30" rows="5" v-model="model.fabricationInstruction" class="form-control" placeholder="Enter instruction"></textarea>
                        </div>
                    </div>
                    <label for="" class="required">Images</label>
                    <div class="row">
                        <div v-for="(image, index) in model.images" :key="index">
                            <div v-if="image != null" class="text-center profile-avatar">
                                <img :src="image" class="img-fluid rounded m-1 pointer" alt="" style="width: 150px; height: 150px;" @click="lihat(image)"><br>
                                <div class="edit-avatar">
                                    <button class="btn btn-rounded btn-danger" 
                                        title="Delete Image" @click="deleteFile(image)"
                                        type="button">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>                                                        
                        </div>

                        <div class="pl-1">
                            <div style="
                                    text-align: center; 
                                    border-radius: 10px; 
                                    background: #E5E7E9;
                                    padding-top: 25px;
                                    padding-bottom: 25px;
                                    cursor: pointer;
                                    width: 150px;
                                    height: 150px;
                                " class="mr-1 mt-1 mb-1">
                                <label for="file-upload" class="btn-edit" style="font-size: 40px; cursor: pointer;">
                                    <i class="fa fa-upload" aria-hidden="true"></i> 
                                    <p style="font-size: 14px">Upload Image</p>
                                </label>
                                <input id="file-upload" class="input-file-avatar d-none" type="file" @change="handleFileUpload( $event )">
                            </div>
                            <small class="text-danger" v-show="image_size">The image file is too large, <br>allowed maximum size is {{max_file_size}}MB</small>
                        </div>
                    </div>
                    <small class="text-danger" v-show="no_image">The image field is required</small>

                    <div class="row">
                        <ValidationProvider name="Is Active" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="required">Is Active</label>
                                <div>
                                    <div class="form-check form-check-inline mr-3">
                                        <input class="form-check-input" type="radio" v-model="model.isActive" id="isActiveYes" :value="true">
                                        <label class="form-check-label" for="isActiveYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline mr-3">
                                        <input class="form-check-input" type="radio" v-model="model.isActive" id="isActiveNo" :value="false">
                                        <label class="form-check-label" for="isActiveNo">No</label>
                                    </div>
                                </div>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div class="row">
                        <ValidationProvider name="Discoverable" v-slot="{ errors }" rules="required" class="col">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }"
                                    :disabled="!model.isActive">
                                <label class="required">Discoverable</label>
                                <div>
                                    <div class="form-check form-check-inline mr-3">
                                        <input class="form-check-input" type="radio" v-model="model.isDiscoverable" id="discoverableYes" :value="true" :disabled="!model.isActive">
                                        <label class="form-check-label" for="discoverableYes">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline mr-3">
                                        <input class="form-check-input" type="radio" v-model="model.isDiscoverable" id="discoverableNo" :value="false" :disabled="!model.isActive">
                                        <label class="form-check-label" for="discoverableNo">No</label>
                                    </div>
                                </div>
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <hr>
                    <div class="mt-1">
                        <router-link to="/inventories" class="btn text-danger">Cancel</router-link>
                        <button type="submit" class="btn btn-primary" :disabled="isLoading">
                            <div v-if="isLoading">{{$route.params.id ? 'Updating' : 'Creating'}}<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>{{$route.params.id ? 'Update' : 'Create'}}</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import ld from 'lodash'

export default {
    data() {
        return {
            model: {
                title: '',
                description: '',
                images: [],
                typeLevelOneId: '',
                typeLevelTwoId: '',
                typeLevelThreeId: null,
                flowId: null,
                price: '',
                discountPrice: 0,
                stock: '',
                minimumStock: 0,
                minPurchaseQuantity: 1,
                maxPurchaseQuantity: null,
                weight: '',
                length: '',
                width: '',
                height: '',
                unit: '',
                sku: '',
                composition: '',
                usage: '',
                generalIndication: '',
                measure: '',
                contraIndication: '',
                group: '',
                attention: '',
                packaging: '',
                fabricationInstruction: '',
                slug: '',
                type: null,
                children: [],
                isActive: true,
                isDiscoverable: null,
                productVariantNames: [],
            },
            isLoading: false,
            errors: null,
            errorMessage: '',
            types: [],
            flows: [],
            realProducts: [],
            no_image: false,
            image_size: false,
            max_file_size: 0,
            selected_type_level_1: null,
            selected_type_level_2: null,
            selected_type_level_3: null,
            selected_flow: null,
            uniqueError: {
				name: false,
                slug: false,
                publicTitle: false
			}
        }
    },
    computed: {
        isProductDisplay() {
            return this.model.type === 'Display';
        },
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/products/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                    _.selected_type_level_1 = _.model.typeLevelOne
                    _.selected_type_level_2 = _.model.typeLevelTwo
                    _.selected_type_level_3 = _.model.typeLevelThree
                    
                    _.getType()
                    _.getFlow()
                    _.getRealProducts()
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this

            if ( _.uniqueError.name === true || _.uniqueError.publicTitle === true || _.uniqueError.slug === true ) {
                return false;
            }

            if (_.model.images.length == 0) {
                _.no_image = true
            } else {
                _.no_image = false
            }
            const isValid = await _.$refs.observer.validate()
            if (isValid && !_.no_image){
                _.isLoading = true
                _.errorMessage = ''
                if (!_.$route.params.id) {
                    _.axios.post('/products', {
                        ..._.model,
                        maxPurchaseQuantity: _.model.maxPurchaseQuantity || null,
                        productVariantNames: undefined
                    })
                        .then(resp=> {
                            if (resp.data.success) {
                                _.$notify({
                                    type: 'success',
                                    title: 'Success!',
                                    text: resp.data.message
                                })
                                _.$router.push('/inventories')
                                _.isLoading = false
                            } else {
                                _.$notify({
                                    type: 'error',
                                    title: 'Error!',
                                    text: 'Inventory title has been used'
                                })
                                _.isLoading = false
                            }
                        })
                        .catch(err=> {
                            _.errorMessage = err.response.data.message;
                            _.isLoading = false
                            window.scrollTo(0, 0);
                        })
                } else {
                    delete _.model.id
                    delete _.model.createdAt
                    delete _.model.updatedAt
                    delete _.model.typeLevelThree
                    delete _.model.typeLevelTwo
                    delete _.model.typeLevelOne
                    delete _.model.deletedAt

                    delete _.model.rating
                    delete _.model.rating_1
                    delete _.model.rating_2
                    delete _.model.rating_3
                    delete _.model.rating_4
                    delete _.model.rating_5
                    delete _.model.sold
                    delete _.model.totalReviews
                    delete _.model.needConsult

                    _.axios.put('/products/' + _.$route.params.id, {
                        ..._.model,
                        maxPurchaseQuantity: _.model.maxPurchaseQuantity || null,
                        productVariantNames: undefined
                    })
                        .then(resp=> {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.$router.push('/inventories')
                            _.isLoading = false
                        })
                        .catch(err=>{
                            _.errorMessage = err.response.data.message;
                            _.isLoading = false
                            window.scrollTo(0, 0);
                        })
                }
            }
        },
        handleFileUpload(event){
            this.file = event.target.files[0]
            if (this.file.size > ((1024 * 1024) * this.max_file_size)) {
                event.preventDefault();
                this.image_size = true
                return;
            } else {
                this.image_size = false
            }

            let formData = new FormData()
            formData.append('folder', 'contents')
            formData.append('file', this.file)

            this.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    this.model.images.push(resp.data.data.file)
                }).catch(err => {
                    console.log(err.response)
                })
        },
        deleteFile (url){
            let _ = this
            _.model.images = ld.filter(_.model.images, function(o){
                return o != url;
            })
        },
        getType() {
            let _ = this
            _.axios.get('/misc/levels')
                .then(resp=>{
                    _.types = resp.data.data
                })
        },
        getFlow() {
            let _ = this
            _.axios.get('/misc/flows')
                .then(resp=>{
                    _.flows = resp.data.data

                    if (_.model.flowId != null) {
                        _.selected_flow = this.flows.filter(x => x.id == this.model.flowId)
                    }
                })
        },
        getRealProducts() {
            this.axios.get('/products?type=not:Display')
                .then(resp => {
                    this.realProducts = resp.data.data.rows.map(product => ({
                        id: product.id,
                        title: product.title,
                        publicTitle: product.publicTitle,
                    }));
                })
        },
        NumbersOnly(evt) {
            evt = (evt) ? evt : window.event
            var charCode = (evt.which) ? evt.which : evt.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        },
        check( key )
		{
			let _ = this
			let request = {
                id: _.$route.params.id ? parseInt(_.$route.params.id) : null,
                title: key
            }
			
			_.axios.post('/products/check', request)
				.then( resp => {
					if (!resp.data.success) {
                        _.uniqueError.name = true
					} else {
                        _.uniqueError.name = false
					}
				})
		},
        check2( key )
		{
			let _ = this
			let request = {
                id: _.$route.params.id ? parseInt(_.$route.params.id) : null,
                publicTitle: key
            }
			
			_.axios.post('/products/check', request)
				.then( resp => {
					if (!resp.data.success) {
                        _.uniqueError.publicTitle = true
					} else {
                        _.uniqueError.publicTitle = false
					}
				})
		},
        checkSlug( key )
		{
			let _ = this
			let request = {
                id: _.$route.params.id ? parseInt(_.$route.params.id) : null,
                slug: key
            }
			
			_.axios.post('/products/check-slug', request)
				.then( resp => {
					if (!resp.data.success) {
                        _.uniqueError.slug = true
					} else {
                        _.uniqueError.slug = false
					}
				})
		}
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        } else {
            _.getType()
            _.getFlow()
            _.getRealProducts()
        }
        _.max_file_size = process.env.VUE_APP_MAX_FILE_SIZE
    },
    watch: {
        'model.title': function(newValue) {
			if (newValue != '') {
				this.check( newValue )
			}		
		},
        'model.publicTitle': function(newValue) {
			if (newValue != '') {
				this.check2( newValue )
			}		
		},
        'model.slug': function(newValue) {
			if (newValue != '') {
                this.model.slug = newValue.toLowerCase()
				this.checkSlug( this.model.slug )
			}		
		},
        'model.type': function(newValue, oldValue) {
            switch (newValue) {
                case 'OTC':
                    this.selected_flow = null;
                    this.model.flowId = null;
                    this.model.children = [];
                    if(typeof this.$route.params.id === 'undefined' || oldValue) {
                        this.model.isDiscoverable = true;
                    }
                    break;
                case 'Rx Red':
                case 'Rx Blue':
                    this.model.children = [];
                    if(typeof this.$route.params.id === 'undefined' || oldValue) {
                        this.model.isDiscoverable = false;
                    }
                    break;
                case 'Display':
                    if (typeof this.$route.params.id === 'undefined') { // create mode only
                        this.model.price = 0;
                        this.model.discountPrice = 0;
                        this.model.stock = 0;
                        this.model.minimumStock = 0;
                        this.model.minPurchaseQuantity = 1;
                        this.model.maxPurchaseQuantity = null;
                        this.model.weight = 0;
                        this.model.length = 0;
                        this.model.width = 0;
                        this.model.height = 0;
                    }
                    if(typeof this.$route.params.id === 'undefined' || oldValue) {
                        this.model.isDiscoverable = true;
                    }
                    break;
                default:
                    this.model.isDiscoverable = false;
                    break;
            }
            this.$forceUpdate();
        },
        'model.isActive': function(newValue) {
			if(!newValue) this.model.isDiscoverable = false;
            else{
                switch(this.model.type){
                case 'OTC':
                case 'Display':
                    this.model.isDiscoverable = true;
                    break;
                default:
                    this.model.isDiscoverable = false;
                    break;
                }
            }
		},
    }
}
</script>

<style scoped>
.circle {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.bg-blue {
    background-color: #0072CD;
}

.gap-1 {
    gap: .25rem;
}
</style>