<template>
  <div class="card">
    <div class="card-body">
      <h3 class="font-600 mb-2">
        {{ id ? 'Edit' : 'Add' }} Subscription Package
      </h3>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <div class="row">
            <ValidationProvider
              name="Package Name"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Package Name (Subscription Type)</label>
                <input
                  type="text"
                  v-model="model.name"
                  class="form-control"
                  placeholder="Enter name"
                />
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="row">
            <ValidationProvider
              name="Active Period"
              v-slot="{ errors }"
              :rules="{ required: true, min_value: 1 }"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Active Period (in months)</label>
                <vue-numeric
                  v-model="model.activePeriodInMonths"
                  @keypress.native="numbersOnly"
                  class="form-control"
                  placeholder="Enter Active Period"
                  separator="."
                ></vue-numeric>
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="Retail Price"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Retail Price</label>
                <vue-numeric
                  v-model="model.retailPrice"
                  @keypress.native="numbersOnly"
                  class="form-control"
                  placeholder="Enter Retail Price"
                  currency="Rp"
                  separator="."
                ></vue-numeric>
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="Selling Price"
              v-slot="{ errors }"
              :rules="{
                max_value:
                  model.retailPrice === '' ? false : model.retailPrice - 1,
              }"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label>Selling Price</label>
                <vue-numeric
                  v-model="model.sellingPrice"
                  @keypress.native="numbersOnly"
                  class="form-control"
                  placeholder="Enter Selling Price"
                  currency="Rp"
                  separator="."
                  allow-clear
                ></vue-numeric>
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="Stock"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Stock</label>
                <vue-numeric
                  v-model="model.stock"
                  @keypress.native="numbersOnly"
                  class="form-control"
                  placeholder="Enter Stock"
                  separator="."
                ></vue-numeric>
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="row">
            <div class="form-group col-2">
              <label>Is Active?</label>
              <select class="form-control" v-model="model.isActive">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <hr />
              <router-link to="/subscription-packages" class="btn text-danger"
                >Cancel</router-link
              >
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="isLoading"
              >
                <div v-if="isLoading">
                  {{ id ? 'Updating' : 'Creating'
                  }}<i class="fa fa-spinner fa-spin"></i>
                </div>
                <div v-else>{{ id ? 'Update' : 'Create' }}</div>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      model: {
        name: '',
        activePeriodInMonths: '',
        retailPrice: '',
        sellingPrice: '',
        stock: '',
        isActive: true,
      },
    };
  },
  async mounted() {
    if (this.id) {
      this.axios
        .get(`/v2/cms/subscription-packages/${this.id}`)
        .then((res) => {
          const { data } = res.data;
          this.model = {
            name: data.name,
            activePeriodInMonths: data.activePeriodInMonths,
            retailPrice: data.retailPrice,
            sellingPrice: data.sellingPrice || '',
            stock: data.stock,
            isActive: data.isActive,
          };
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
          this.$router.push('/subscription-packages');
        });
    }
  },
  methods: {
    numbersOnly(e) {
      const charCode = e.which ?? e.keyCode;
      if (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 96 && charCode <= 105)
      ) {
        return true;
      }
      e.preventDefault();
    },
    async submit() {
      console.log(this.model);
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.isLoading = true;
        const data = {
          name: this.model.name,
          activePeriodInMonths: this.model.activePeriodInMonths,
          retailPrice: this.model.retailPrice,
          sellingPrice:
            this.model.sellingPrice === '' ? null : this.model.sellingPrice,
          stock: this.model.stock,
          isActive: this.model.isActive,
        };

        if (this.id) {
          this.axios
            .put(`/v2/cms/subscription-packages/${this.id}`, data)
            .then((resp) => {
              this.$notify({
                type: 'success',
                title: 'Success!',
                text: resp.data.message,
              });
              this.$router.push('/subscription-packages');
            })
            .catch((err) => {
              this.isLoading = false;
              this.$notify({
                type: 'error',
                title: 'Error',
                text: err.response.data.message,
              });
            });
        } else {
          this.axios
            .post('/v2/cms/subscription-packages', data)
            .then((resp) => {
              this.$notify({
                type: 'success',
                title: 'Success!',
                text: resp.data.message,
              });
              this.$router.push('/subscription-packages');
            })
            .catch((err) => {
              this.isLoading = false;
              this.$notify({
                type: 'error',
                title: 'Error',
                text: err.response.data.message,
              });
            });
        }
      }
    },
  },
};
</script>
