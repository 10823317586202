<template>
  <div class="card">
    <div class="card-body">
      <h3 class="font-600 mb-2">{{ id ? 'Edit' : 'Add' }} Product Variant</h3>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <div class="row">
            <ValidationProvider
              name="Variant Name"
              v-slot="{ errors }"
              rules="required"
              class="col"
            >
              <div
                class="form-group"
                :class="{ 'validation-warning': errors[0] }"
              >
                <label class="required">Variant Name</label>
                <input
                  type="text"
                  v-model="model.name"
                  class="form-control"
                  placeholder="Enter name"
                />
                <small class="text-muted" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </ValidationProvider>
          </div>
          <div class="row">
            <div class="form-group col">
              <label>Products</label>
              <multiselect
                v-model="model.products"
                label="title"
                track-by="id"
                placeholder="Select Products"
                :multiple="true"
                :options="productOptions"
                :clear-on-select="true"
                :close-on-select="false"
              >
                <span slot="noResult">Oops! Data not found.</span>
              </multiselect>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <hr />
              <router-link to="/product-variants" class="btn text-danger"
                >Cancel</router-link
              >
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="isLoading"
              >
                <div v-if="isLoading">
                  {{ id ? 'Updating' : 'Creating'
                  }}<i class="fa fa-spinner fa-spin"></i>
                </div>
                <div v-else>{{ id ? 'Update' : 'Create' }}</div>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      model: {
        name: '',
        products: [],
      },
      productOptions: [],
    };
  },
  async mounted() {
    this.getProductOptions();
    if (this.id) {
      this.axios
        .get(`/v2/cms/product-variants/${this.id}`)
        .then((res) => {
          const { data } = res.data;
          this.model = {
            name: data.name,
            products: data.products,
          };
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
          this.$router.push('/product-variants');
        });
    }
  },
  methods: {
    getProductOptions() {
      this.axios
        .get('/v2/misc/products', { params: { page: 1, type: 'in:Rx Red,Rx Blue,OTC' } })
        .then((res) => {
          this.productOptions = res.data.data.rows;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: 'Failed to load product options',
          });
        });
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.isLoading = true;
        const data = {
          name: this.model.name,
          productIds: this.model.products.map((product) => product.id),
        };

        if (this.id) {
          this.axios
            .put(`/v2/cms/product-variants/${this.id}`, data)
            .then((resp) => {
              this.$notify({
                type: 'success',
                title: 'Success!',
                text: resp.data.message,
              });
              this.$router.push('/product-variants');
            })
            .catch((err) => {
              this.isLoading = false;
              this.$notify({
                type: 'error',
                title: 'Error',
                text: err.response.data.message,
              });
            });
        } else {
          this.axios
            .post('/v2/cms/product-variants', data)
            .then((resp) => {
              this.$notify({
                type: 'success',
                title: 'Success!',
                text: resp.data.message,
              });
              this.$router.push('/product-variants');
            })
            .catch((err) => {
              this.isLoading = false;
              this.$notify({
                type: 'error',
                title: 'Error',
                text: err.response.data.message,
              });
            });
        }
      }
    },
  },
};
</script>
