<template>
  <div class="card">
    <div class="card-body">
      <Table :dataTable="table.dataTable" :title="table.title">
        <template v-slot:header>
          <div class="row">
            <div class="col-lg-8 col-xs-12">
              <div class="btn-responsive">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="$router.push('/subscription-packages/add')"
                >
                  <i class="fa fa-plus"></i> Add
                </button>
              </div>
            </div>
            <div class="col mb-1">
              <div class="input-group form-search-rounded">
                <input
                  class="form-control"
                  v-model="search"
                  type="search"
                  placeholder="Search by name..."
                />
                <span class="input-group-append">
                  <div class="input-group-text bg-transparent">
                    <i class="fa fa-search"></i>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:thead>
          <thead>
            <tr>
              <th v-for="(data, index) in table.dataTable.header" :key="index">
                {{ data }}
              </th>
            </tr>
          </thead>
        </template>

        <template v-slot:action="{ data }">
          <div class="btn-action-block">
            <router-link :to="`/subscription-packages/edit/${data.id}`">
              <button
                type="button"
                class="btn btn-rounded btn-warning waves-effect waves-light"
                title="Edit"
              >
                <i class="fa fa-edit"></i>
              </button>
            </router-link>
            <button
              type="button"
              class="btn btn-rounded btn-danger waves-effect waves-light"
              title="Delete"
              @click="destroy(data)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue';
import { debounce } from 'debounce';

export default {
  components: {
    Table,
  },
  data() {
    return {
      table: {
        title: 'Subscription Packages',
        dataTable: {
          header: ['#', 'Package Name', 'Price', '# Purchased', 'Active', 'Action'],
          columns: ['counter', 'name', 'finalPrice', 'numberOfPurchased', 'isActive'],
          lists: {},
        },
      },
      search: '',
      limit: 10,
      isLoading: false,
    };
  },
  mounted() {
    this.getList();
    this.getList = debounce(this.getList, 500);

    this.$root.$on('paginate', (data) => {
      this.getList(data);
    });
    this.$root.$on('changeLimit', (data) => {
      this.limit = data;
    });
  },
  beforeDestroy() {
    this.$root.$off('paginate');
    this.$root.$off('changeLimit');
  },
  methods: {
    getList(page) {
      this.axios
        .get('/v2/cms/subscription-packages', {
          params: {
            page: page || 1,
            limit: this.limit,
            search: this.search || undefined,
          },
        })
        .then((res) => {
          this.table.dataTable.lists = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        });
    },
    destroy(data) {
      this.$swal
        .fire({
          title: 'Are you sure want to delete Subscription Package?',
          text: data.name,
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonColor: '#0036A0',
          confirmButtonText: 'Yes',
          focusCancel: true,
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .delete(`/v2/cms/subscription-packages/${data.id}`)
              .then((res) => {
                if (res.status) {
                  this.getList();
                  this.$swal.fire({
                    title: 'Deleted!',
                    text: res.message,
                    icon: 'success',
                    confirmButtonColor: '#0036A0',
                    confirmButtonText: 'OK',
                  });
                } else {
                  this.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: 'Failed to delete',
                  });
                }
              })
              .catch((err) => {
                this.$notify({
                  type: 'error',
                  title: 'Error!',
                  text: err.response.data.message,
                });
              });
          }
        });
    },
  },
  watch: {
    search: function () {
      this.getList();
    },
    limit: function () {
      this.getList();
    },
  },
};
</script>
