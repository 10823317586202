<template>
  <section>
    <div class="card">
      <div class="card-body">
        <Table :dataTable="table.dataTable" :title="table.title">
          <template v-slot:title-top-right>
            <button
              v-if="status === 'APPROVED'"
              type="button"
              class="btn btn-sm btn-primary"
              data-toggle="modal"
              data-target="#modalForm"
            >
              <i class="fa fa-plus"></i> Add Review
            </button>
          </template>
          <template v-slot:header>
            <div class="row mb-1 align-items-start">
              <div class="col form-group">
                <label>Status</label>
                <div class="d-flex gap-1 align-items-center">
                  <button
                    type="button"
                    class="btn btn-sm"
                    :class="{
                      'btn-primary': status === 'ALL',
                      'btn-outline-primary': status !== 'ALL',
                    }"
                    @click="changeStatus('ALL')"
                  >
                    All
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm"
                    :class="{
                      'btn-primary': status === 'PENDING',
                      'btn-outline-primary': status !== 'PENDING',
                    }"
                    @click="changeStatus('PENDING')"
                  >
                    Pending
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm"
                    :class="{
                      'btn-primary': status === 'APPROVED',
                      'btn-outline-primary': status !== 'APPROVED',
                    }"
                    @click="changeStatus('APPROVED')"
                  >
                    Approved
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm"
                    :class="{
                      'btn-primary': status === 'REJECTED',
                      'btn-outline-primary': status !== 'REJECTED',
                    }"
                    @click="changeStatus('REJECTED')"
                  >
                    Rejected
                  </button>
                  <span
                    class="text-blue text-bold-500 ml-2 cursor-pointer"
                    @click="resetFilter"
                    >Reset Filter</span
                  >
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label>Product</label>
                  <multiselect
                    v-model="productSearch"
                    label="title"
                    track-by="id"
                    placeholder="Search Product"
                    :options="products"
                    :multiple="false"
                    :searchable="true"
                    :max-height="600"
                    @input="getList()"
                  >
                    >
                    <span slot="noResult">Oops! There is no product.</span>
                  </multiselect>
                </div>
              </div>
              <div
                class="col-2"
                v-if="status === 'APPROVED' && productSearch !== null"
              >
                <div class="form-group">
                  <label>Final Rating</label>
                  <span
                    ><b class="text-blue">{{ averageRating.toFixed(1) }}</b> of
                    <b class="text-blue">{{ totalApprovedRatings }}</b></span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-2">
                <label>User Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="userNameFilter"
                  placeholder="Enter User Name"
                  @input="getList()"
                />
              </div>
              <div class="form-group col-2">
                <label>Rating</label>
                <select
                  class="form-control"
                  v-model="ratingFilter"
                  @change="getList()"
                >
                  <option :value="null" disabled>Select Rating</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div class="form-group col-2">
                <label>Photo</label>
                <select
                  class="form-control"
                  v-model="imageFilter"
                  @change="getList()"
                >
                  <option :value="null" disabled>Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div class="form-group col-3">
                <label>Date</label>
                <date-picker
                  type="date"
                  :range="true"
                  v-model="dateFilter"
                  placeholder="Select Date"
                  :confirm="true"
                  value-type="YYYY-MM-DD"
                  class="w-100"
                  @change="getList()"
                  :disabled-date="disabledDateFilter"
                >
                </date-picker>
              </div>
            </div>
          </template>

          <template v-slot:thead>
            <thead>
              <tr>
                <th
                  v-for="(data, index) in table.dataTable.header"
                  :key="index"
                >
                  {{ data }}
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:action="{ data }">
            <div class="btn-action-block d-flex justify-content-center gap-1">
              <template v-if="data.status === 'PENDING'">
                <button
                  type="button"
                  class="btn btn-sm btn-success"
                  @click="approve(data.id)"
                >
                  Approve
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="reject(data.id)"
                >
                  Reject
                </button>
              </template>
              <template v-else-if="data.status === 'APPROVED'">
                <switches
                  @input="toggleShow(data)"
                  :emit-on-mount="false"
                  v-model="data.show"
                  :label="data.show ? 'Show' : 'Hidden'"
                  :color="data.show ? 'green' : 'red'"
                >
                </switches>
              </template>
              <template v-else>
                <p class="text-center w-100 mb-0">-</p>
              </template>
            </div>
          </template>
        </Table>
      </div>
    </div>

    <!-- Add Review Modal -->
    <div
      class="modal fade text-left show"
      id="modalForm"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <ValidationObserver class="card-content" ref="observer">
            <form @submit.prevent="submit()">
              <div class="modal-header modal-primary white">
                <h5 class="modal-title">Add Review</h5>
              </div>
              <div class="modal-body">
                <!-- Product -->
                <ValidationProvider
                  name="Product Name"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label>Product Name</label>
                    <multiselect
                      v-model="model.product"
                      label="title"
                      track-by="id"
                      placeholder="Search Product"
                      :options="products"
                      :multiple="false"
                      :searchable="true"
                      :max-height="600"
                    >
                      <span slot="noResult">Oops! There is no product.</span>
                    </multiselect>
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>

                <!-- Avatar -->
                <div class="form-group">
                  <div v-if="model.avatar" class="text-center profile-avatar">
                    <img
                      :src="model.avatar"
                      class="img-fluid rounded mt-1 mr-1 pointer"
                      alt=""
                      style="width: 150px; height: 150px"
                    />
                    <div class="edit-avatar">
                      <button
                        @click="deleteAvatar"
                        class="btn btn-rounded btn-danger"
                        title="Delete"
                        type="button"
                      >
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="upload-image-trigger mr-1 mt-1 mb-1"
                      @click="$refs.inputAvatar.click()"
                    >
                      <i
                        class="fa fa-upload"
                        aria-hidden="true"
                        style="font-size: 40px"
                      ></i>
                      <span style="font-size: 14px">Upload Avatar</span>
                      <input
                        ref="inputAvatar"
                        class="input-file-avatar d-none"
                        type="file"
                        accept="image/jpeg, image/jpg, image/png"
                        @change="storeAvatar"
                      />
                    </div>
                  </div>
                </div>

                <!-- Date -->
                <ValidationProvider
                  name="Date"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <div class="form-group">
                    <label>Date</label>
                    <date-picker
                      v-model="model.date"
                      type="date"
                      value-type="YYYY-MM-DD"
                      placeholder="Choose date"
                      class="w-100"
                      :disabled-date="disabledDate"
                    >
                    </date-picker>
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>

                <!-- User name -->
                <ValidationProvider
                  name="Username"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <div class="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="model.name"
                      placeholder="Enter Username"
                    />
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>

                <!-- Rating -->
                <ValidationProvider
                  name="Rating"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <div class="form-group">
                    <label>Rating</label>
                    <star-rating
                      v-model="model.rating"
                      :increment="1"
                    ></star-rating>
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>

                <!-- Review -->
                <ValidationProvider
                  name="Review"
                  v-slot="{ errors }"
                  rules="max:350"
                >
                  <div class="form-group">
                    <label>Review</label>
                    <textarea
                      v-model="model.review"
                      cols="30"
                      rows="5"
                      class="form-control"
                      placeholder="Enter Review"
                    ></textarea>
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </ValidationProvider>

                <!-- Photos -->
                <div class="form-group mb-0">
                  <label>Photo Review</label>
                  <div class="d-flex flex-wrap" style="gap: 1rem">
                    <div
                      v-for="(image, idx) in model.images"
                      :key="idx"
                      class="position-relative"
                    >
                      <span
                        class="badge badge-danger badge-pill position-absolute cursor-pointer"
                        style="top: -10px; right: -10px"
                        @click="deletePhotoReview(idx)"
                      >
                        x
                      </span>
                      <img
                        :src="image"
                        width="120"
                        height="120"
                        style="
                          object-fit: cover;
                          object-position: center;
                          border-radius: 10px;
                        "
                      />
                    </div>
                    <div
                      v-if="model.images && model.images.length < 6"
                      :key="model.images.length"
                      class="upload-image-trigger"
                      style="width: 120px; height: 120px"
                      @click="$refs.inputPhotoReview.click()"
                    >
                      <i
                        class="fa fa-plus"
                        aria-hidden="true"
                        style="font-size: 32px"
                      ></i>
                      <span style="font-size: 14px">Add Photo</span>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        @change="storePhotoReview"
                        class="hidden"
                        ref="inputPhotoReview"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Modal Footer -->
              <div class="modal-footer p-2" style="justify-content: left">
                <button
                  type="button"
                  id="closeModalForm"
                  class="btn text-primary waves-effect waves-light"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="btn btn-primary waves-effect waves-light"
                  :disabled="isLoading"
                >
                  <div>
                    Submit
                    <i v-if="isLoading" class="fa fa-spinner fa-spin"></i>
                  </div>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Table from '@/components/Table.vue';
import { debounce } from 'debounce';
import StarRating from 'vue-star-rating';
import Switches from 'vue-switches';

export default {
  components: {
    Table,
    StarRating,
    Switches,
  },
  data() {
    return {
      table: {
        title: 'Product Review',
        dataTable: {
          header: [
            '#',
            'Date',
            'User Name',
            'Product Name',
            'Rating',
            'Review',
            'Photos',
            'Action',
          ],
          columns: [
            'counter',
            'date',
            'productReviewUserName',
            'product.title',
            'rating',
            'productReview',
            'photoReview',
          ],
          lists: [],
        },
      },
      status: 'PENDING',
      limit: 10,
      isLoading: false,
      averageRating: 0,
      totalApprovedRatings: 0,
      model: {
        product: null,
        avatar: null,
        avatarFile: null,
        date: null,
        name: '',
        rating: 0,
        review: '',
        images: [],
        imageFiles: [],
      },
      products: [],

      // Search & Filter
      productSearch: null,
      userNameFilter: '',
      ratingFilter: null,
      imageFilter: null,
      dateFilter: null,
    };
  },
  mounted() {
    this.getList();
    this.getList = debounce(this.getList, 500);

    this.getProducts();

    this.$root.$on('paginate', (data) => {
      this.getList(data);
    });
    this.$root.$on('changeLimit', (data) => {
      this.limit = data;
    });
  },
  beforeDestroy() {
    this.$root.$off('paginate');
    this.$root.$off('changeLimit');
  },
  methods: {
    getList(page) {
      this.axios
        .get('/ratings', {
          params: {
            page: page || 1,
            limit: this.limit,
            status: this.status === 'ALL' ? undefined : this.status,
            productId: this.productSearch ? this.productSearch.id : undefined,
            userName: this.userNameFilter || undefined,
            rating: this.ratingFilter || undefined,
            imageAvailable: this.imageFilter || undefined,
            startDate: this.dateFilter ? this.dateFilter[0] : undefined,
            endDate: this.dateFilter ? this.dateFilter[1] : undefined,
          },
        })
        .then((res) => {
          this.table.dataTable.lists = res.data.data;
          this.averageRating = res.data.data.averageRating;
          this.totalApprovedRatings = res.data.data.totalApprovedRatings;
        })
        .catch((err) => {
          console.error(err.response);
        });
    },
    getProducts() {
      this.axios
        .get('/misc/products')
        .then((res) => {
          this.products = res.data.data;
        });
    },
    changeStatus(status) {
      this.status = status;
      this.getList();
    },
    resetFilter() {
      this.productSearch = null;
      this.userNameFilter = '';
      this.ratingFilter = null;
      this.imageFilter = null;
      this.dateFilter = null;
      this.getList();
    },
    disabledDateFilter(date, currentDate) {
      const isFutureDate =
        this.$moment() &&
        date.setHours(0, 0, 0, 0) >
          new Date(this.$moment()).setHours(0, 0, 0, 0);
      const diffDays =
        currentDate[0] &&
        this.$moment(currentDate[0]).diff(this.$moment(date), 'days');
      const max90days = (diffDays > 90) | (diffDays < -90);
      return isFutureDate | max90days;
    },
    disabledDate(date) {
      return (
        this.$moment() &&
        date.setHours(0, 0, 0, 0) >
          new Date(this.$moment()).setHours(0, 0, 0, 0)
      );
    },
    storeAvatar(event) {
      this.model.avatarFile = event.target.files[0];
      this.model.avatar = window.URL.createObjectURL(event.target.files[0]);
    },
    deleteAvatar() {
      this.model.avatarFile = null;
      this.model.avatar = null;
    },
    storePhotoReview(event) {
      this.model.imageFiles.push(event.target.files[0]);
      this.model.images.push(window.URL.createObjectURL(event.target.files[0]));
    },
    deletePhotoReview(idx) {
      this.model.imageFiles.splice(idx, 1);
      this.model.images.splice(idx, 1);
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        const photoReviewsUploads = this.model.imageFiles.map((imageFile) => {
          if (imageFile) {
            const formData = new FormData();
            formData.append('folder', 'reviews');
            formData.append('file', imageFile);
            return this.axios.post('/misc/upload', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
          }
          return Promise.resolve(null);
        });

        try {
          this.isLoading = true;
          let avatarUrl;
          if (this.model.avatarFile) {
            const formData = new FormData();
            formData.append('folder', 'reviews');
            formData.append('file', this.model.avatarFile);
            const avatarUploadResult = await this.axios.post(
              '/misc/upload',
              formData,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            );
            avatarUrl = avatarUploadResult.data.data.file;
          }
          const photoReviewsUploadResults = await Promise.all(
            photoReviewsUploads
          );
          const photoReviewUrls = photoReviewsUploadResults.map(
            (result) => result.data.data.file
          );

          const data = {
            productId: this.model.product.id,
            name: this.model.name,
            avatar: avatarUrl,
            rating: this.model.rating,
            review: this.model.review,
            date: this.model.date,
            images: photoReviewUrls,
          };

          this.axios
            .post('/ratings', data)
            .then((res) => {
              this.$notify({
                type: 'success',
                title: 'Success!',
                text: res.data.message,
              });
              this.isLoading = false;
              this.resetModel();
              this.closeModal();
              this.getList();
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error!',
                text: err.response.data.message,
              });
              this.isLoading = false;
            });
        } catch (err) {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: 'Failed to upload photo',
          });
        }
      }
    },
    closeModal() {
      document.getElementById('closeModalForm').click();
    },
    resetModel() {
      this.model = {
        product: null,
        avatar: null,
        avatarFile: null,
        date: null,
        name: '',
        rating: 0,
        review: '',
        images: [],
        imageFiles: [],
      };
      this.$refs.observer.reset();
    },
    toggleShow(data) {
      this.axios.patch(`/ratings/${data.id}`, {
        show: data.show,
      });
    },
    approve(id) {
      this.axios
        .patch(`/ratings/${id}`, {
          status: 'APPROVED',
        })
        .then(() => {
          this.getList();
        });
    },
    reject(id) {
      this.$swal
        .fire({
          title: 'Are you sure want to reject this rating?',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonColor: '#0036A0',
          confirmButtonText: 'Yes',
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .patch(`/ratings/${id}`, {
                status: 'REJECTED',
              })
              .then(() => {
                this.getList();
              });
          }
        });
    },
  },
  watch: {
    limit: function () {
      this.getList();
    },
  },
};
</script>

<style scoped>
.gap-1 {
  gap: 0.5rem;
}
.upload-image-trigger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border-radius: 10px;
  background: #e5e7e9;
  cursor: pointer;
  width: 150px;
  height: 150px;
}
</style>
