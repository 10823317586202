export default {
	account: null,
	content: {
		title: '',
		pageTypeId: '',
		pageOption: {
			description: '',
			lists: []
		}
	},
	contentEdit: null
}