<template>
  <div class="card">
    <div class="card-body">
      <h3 class="font-600 mb-2">Diri Essentials</h3>
      <ValidationObserver class="card-content" ref="observer">
        <form @submit.prevent="submit">
          <app-collapse accordion type="margin">
            <app-collapse-item title="Banner Section">
              <div class="row">
                <ValidationProvider
                  name="Is Banner Active"
                  v-slot="{ errors }"
                  class="col-2"
                >
                  <div
                    class="form-group"
                    :class="{ 'validation-warning': errors[0] }"
                  >
                    <label class="required">Is Active ?</label>
                    <select class="form-control" v-model="model.isBannerActive">
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                    <small class="val-error" v-if="errors[0]">
                      {{ errors[0] }}
                    </small>
                  </div>
                </ValidationProvider>
              </div>
              <div v-for="(banner, idx) in model.banners" :key="idx" class="mb-1">
                <div class="row">
                  <ValidationProvider
                    :name="`Image ${idx + 1}`"
                    v-slot="{ errors, validate }"
                    :rules="{ required: !banner.image, 'max-dimensions': 1080 }"
                    class="col"
                    :key="model.banners.length"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label for="image" class="required">Image</label>
                      <div class="form-control" style="height: auto">
                        <span
                          class="btn btn-outline-primary btn-sm"
                          @click="$refs.images[idx].click()"
                        >
                          Select Image
                        </span>
                        <img
                          v-if="banner.image"
                          :src="banner.image"
                          width="430"
                          height="135"
                          class="mt-1 d-block"
                          style="object-fit: cover; object-position: center;"
                        />
                        <small class="block mt-1" style="margin-top: 4px;">Ratio: 86:27</small>
                        <small class="block" style="margin-top: 4px;">Recommended dimensions: 1080 x 339 pixels</small>
                        <small class="block" style="margin-top: 4px;">Max width: 1080 pixels</small>
                      </div>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        @change="
                          (e) => {
                            validate(e).then((res) => storeImage(res, e, idx));
                          }
                        "
                        id="image"
                        class="hidden"
                        placeholder="Upload Image"
                        ref="images"
                      />
                      <small class="text-danger" v-if="errors[0]">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    :name="`URL Image ${idx + 1}`"
                    v-slot="{ errors }"
                    rules="url"
                    class="col"
                  >
                    <div
                      class="form-group"
                      :class="{ 'validation-warning': errors[0] }"
                    >
                      <label>Url Image</label>
                      <input
                        type="text"
                        v-model="banner.url"
                        class="form-control"
                        placeholder="Enter URL"
                      />
                      <small class="text-muted" v-if="errors[0]">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </ValidationProvider>
                </div>
                <div>
                  <button type="button" class="btn btn-danger mr-2" @click="deleteBanner(idx)" v-if="model.banners.length > 1"><i class="fa fa-trash"></i> Delete Content</button>
                </div>
              </div>
              <div>
                <button type="button" class="btn btn-primary" @click="addBanner()"><i class="fa fa-plus"></i> Add Content</button>
              </div>
            </app-collapse-item>
          </app-collapse>
          <div class="row">
            <div class="col">
              <hr />
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="isLoading"
              >
                <div v-if="isLoading">
                  Updating <i class="fa fa-spinner fa-spin"></i>
                </div>
                <div v-else>Update</div>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      id: this.$route.params.id,
      model: {
        isBannerActive: false,
        banners: [
          {
            image: '',
            url: '',
          },
        ],
      },
      imageFiles: [null],
      isLoading: false,
    };
  },
  async mounted() {
    this.axios
      .get('/store/otc')
      .then((res) => {
        const { data } = res.data;
        this.model.isBannerActive = data.isBannerActive;
        this.model.banners = data.banners;
        this.imageFiles = Array(data.banners.length).fill(null);
      })
      .catch((err) => {
        this.$notify({
          type: 'error',
          title: 'Error!',
          text: err.response.data.message,
        });
      });
  },
  methods: {
    addBanner() {
      this.model.banners.push({
        image: '',
        url: '',
      });
      this.imageFiles.push(null);
    },
    deleteBanner(idx) {
      this.model.banners.splice(idx, 1);
      this.imageFiles.splice(idx, 1);
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          this.isLoading = true;

          // upload images first
          const headers = {
            'Content-Type': 'multipart/form-data',
          };
          const imageUploadRequests = this.imageFiles.map(file => {
            if (file) {
              const formData = new FormData();
              formData.append('folder', 'diri-store');
              formData.append('file', file);
              return this.axios.post('/misc/upload', formData, { headers });
            } else {
              return Promise.resolve(null);
            }
          })
          const imageUploadResponses = await Promise.all(imageUploadRequests);

          const data = {
            isBannerActive: this.model.isBannerActive,
            banners: this.model.banners.map((banner, idx) => ({
              image: imageUploadResponses[idx] === null ? banner.image : imageUploadResponses[idx].data.data.file,
              url: banner.url?.length > 0 ? banner.url : null,
            })),
          };

          this.axios
            .put('/store/otc', data)
            .then((resp) => {
              this.$notify({
                type: 'success',
                title: 'Success!',
                text: resp.data.message,
              });
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: err.response.data.message,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } catch (err) {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Failed to upload images',
          });
        }
      }
    },
    storeImage({ valid }, event, idx) {
      if (valid) {
        this.$set(this.imageFiles, idx, event.target.files[0]);
        this.$set(
          this.model.banners[idx],
          'image',
          window.URL.createObjectURL(event.target.files[0])
        );
      }
    },
  },
};
</script>
