<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-lg-8 col-xs-12">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-primary" @click="$router.push('/inventories/add')">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        </div>
                        <div class="col mb-1">
                            <div class="input-group form-search-rounded">
                                <input class="form-control" v-model="search" type="search"
                                    placeholder="Search by keyword...">
                                <span class="input-group-append">
                                    <div class="input-group-text bg-transparent">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>                
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th v-for="(data, index) in table.dataTable.header" :key="index">
                                <span
                                    class="d-flex align-items-center"
                                    :class="{
                                        pointer: data.sortKey,
                                        'font-weight-bold': sortByKey === data.sortKey,
                                        'text-primary': sortByKey === data.sortKey,
                                    }"
                                    @click="data.sortKey && changeSortBy(data.sortKey)"
                                >
                                    {{ data.label }}
                                    <i
                                        v-if="data.sortKey"
                                        class="fa"
                                        :class="{
                                            'fa-sort': sortByKey !== data.sortKey,
                                            'fa-sort-up': sortByKey === data.sortKey && sortByMode === 'asc',
                                            'fa-sort-down': sortByKey === data.sortKey && sortByMode === 'desc',
                                        }"
                                    />
                                </span>
                            </th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data }">
                    <div class="btn-action-block">
                        <router-link :to="'/inventories/edit/' + data.id">
                            <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                                <i class="fa fa-edit"></i>
                            </button>
                        </router-link>
                        <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Delete" @click="destroy(data)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </template> 
            </Table>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { debounce } from "debounce";

const DEFAULT_SORT_BY = 'updatedAt.desc';

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                title: 'Pharmacy Inventory',
                dataTable: {
                    header: [
                        { label: '#' },
                        { label: 'SKU' },
                        { label: 'Product Name' },
                        { label: 'Internal Product Name' },
                        { label: 'Price' },
                        { label: 'Stock', sortKey: 'stock'},
                        { label: 'Action' },
                    ],
                    columns: ['counter', 'sku', 'publicTitle', 'title', 'price', 'stock'],
                    lists: [],
                }
			},
            filter: '',
            search: '',
            sortBy: DEFAULT_SORT_BY,
            limit: 10,
            isLoading: false
		}
	},
    computed: {
        sortByKey: function () {
            return this.sortBy.split('.')[0];
        },
        sortByMode: function () {
            return this.sortBy.split('.')[1];
        },
    },
    mounted() {
        let _ = this
        _.getList()
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        getList(page = 1) {
            let _ = this
            _.axios.get(`/products?page=${page}&search=${_.search}&limit=${_.limit}&sortBy=${_.sortBy}`)
                .then(resp=>{
                    _.table.dataTable.lists = {
                        ...resp.data.data,
                        rows: resp.data.data.rows.map(item => ({
                            ...item,
                            stock: item.type === 'Display' ? '-' : item.stock,
                        }))
                    };
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Are you sure want to delete Inventory?',
                text: x.title,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                focusCancel: true
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/products/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        changeSortBy(key) {
            if (this.sortByKey === key) {
                if (this.sortByMode === 'asc') {
                    this.sortBy = `${key}.desc`;
                } else {
                    this.sortBy = DEFAULT_SORT_BY;
                }
            } else {
                this.sortBy = `${key}.asc`;
            }
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        },
        sortBy: function(){
            this.getList();
        }
    }
}
</script>